<template>
  <div class="relative">
    <div class="flex bg-white justify-center border-b-2 border-gray-300 items-center py-1">
      <div class="font-bold text-center justify-end w-full my-4">
        {{ capitalize(mainTitle) }}
      </div>
      <v-btn icon text class="mr-2" @click="$emit('closeDialog')">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ['mainTitle', 'selectedInfo'],
  methods: {
    capitalize(string) {
      return (string ?? '').toUpperCase();
    },
  },
};
</script>
