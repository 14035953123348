<template>
  <div>
    <!-- {{ $route.path }} -->
    <TitleHeader />
    <!-- {{ $route.path }} -->
    <div class="flex justify-center pt-4 px-4 border-solid border-b-2 mb-4">
      <div v-for="(item, i) in menus" :key="i">
        <div>
          <button
            @click="selectMenu(item)"
            class="px-[8px] font-bold text-[#13282D] border-solid hover:text-[#FF9800] active:text-[#FF9800] focus:border-solid focus:border-b-4 focus:border-[#FF9800] focus:text-[#FF9800] mx-2"
            :class="
              $route.path.includes(item.path) ? 'border-solid border-b-4 border-[#FF9800]' : ''
            "
          >
            <span v-if="currentuser == '1cdsuperadmin' || !item.meta">{{ item.title }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="bg-white p-2 my-2">
      <router-view />
    </div>
  </div>
</template>
<script>
import TitleHeader from '../../components/TitleHeader.vue';
export default {
  created() {
    this.$router.push({ path: '/home/users/users-view' });
  },
  data() {
    return {
      currentuser: null,
      path: true,
      menus: [
        {
          title: 'Users',
          value: 'users',
          path: '/home/users/users-view',
          name: 'Users',
        },
        // {
        //   title: 'Companies',
        //   value: 'companies',
        //   path: '/home/users/companies-view',
        //   name: 'Companies UI',
        // },
        // {
        //   title: 'Plans',
        //   value: 'plans',
        //   path: '/home/users/plans-view',
        //   name: 'Plans UI',
        // },
        // {
        //   title: 'Permissions',
        //   value: 'permissions',
        //   path: '/home/users/permission-view',
        //   name: 'Permission UI',
        // },
        // {
        //   title: 'Virtual Space Types',
        //   value: 'virtual_space_types',
        //   path: '/home/users/virtual-space-types-view',
        //   name: 'Virtual Space Types UI',
        // },
        {
          title: 'Backups',
          value: 'UsersBackup',
          path: '/home/users/users-backup',
          name: 'Backups',
        },
        {
          title: 'Permissions',
          value: 'PermissionUI',
          path: '/home/users/permission-view',
          name: 'Permission UI',
          meta: true,
        },
      ],
    };
  },
  components: { TitleHeader },
  mounted() {
    this.verifyMenuOptions();
  },
  methods: {
    selectMenu(e) {
      // console.log(e);
      if (!this.$route.path.includes(e.path)) {
        this.$router.push({ name: e.name });
      }
    },
    verifyMenuOptions() {
      const userDetails = JSON.parse(localStorage.getItem('user'));
      // console.log('%c  userDetails:', 'color: #0e93e0;background: #aaefe5;', userDetails);
      this.currentuser = userDetails.role;
      if (userDetails.role.toLowerCase() !== '1cdsuperadmin') {
        this.menus = this.menus.filter((menu) => {
          return menu.value === 'users';
        });
      }
    },
  },
};
</script>
