<template>
  <div>
    <div>
      <DialogTopBar @closeDialog="$emit('close')" mainTitle="Invite Users" />
    </div>
    <UserForm @submit="submit" @cancel="$emit('close')" />
  </div>
</template>
<script>
import { db, storage } from '@/firebase';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import DialogTopBar from '../../../../components/DialogTopBar.vue';
import UserForm from '../../components/users_components/UserForm.vue';

export default {
  components: { DialogTopBar, UserForm },
  methods: {
    async submit(data) {
      try {
        let avatarUrl = data.oldAvatarUrl || '';

        await setDoc(doc(db, 'users', data.email), {
          avatar_url: avatarUrl || '',
          email: data.email || '',
          full_name: data.full_name || '',
          company: data.company || '',
          role: data.role || '',
          permissions: data.permissions || [],
          receive_email: data.receive_email || false,
          regions: data.regions || [],
          position: data.position || '',
          is_deleted: false,
        });

        if (data.avatarFile !== null) {
          const ext = data.avatarFile.name.split('.').pop();
          const fileName = `avatars/user-${data.full_name.replace(/ /g, '')}-${data.email}.${ext}`;
          const avatarRef = ref(storage, fileName);
          await uploadBytes(avatarRef, data.avatarFile);
          avatarUrl = await getDownloadURL(avatarRef);
        }

        await setDoc(
          doc(db, 'users', data.email),
          {
            avatar_url: avatarUrl,
          },
          { merge: true }
        );

        await addDoc(collection(db, 'mail'), {
          to: data.email,
          message: {
            subject: 'Invitation to join 1ClickDesign',
            html: `
              <p>You are invited to join 1ClickDesign as ${[data.position, data.role]
                .filter((x) => !!x)
                .join(', ')}.</p>
              <br />
              <p>Click this link to join: <a href="https://vmc-1clickdesign.netlify.app/invitation?email=${encodeURIComponent(
                data.email
              )}"></a></p>
            `,
          },
        });

        this.$toast.open('Invited user');
        this.$emit('close');
      } catch (err) {
        this.$toast.open({ type: 'error', message: `Error: ${err.message}` });
      }
    },
  },
};
</script>
