import { db } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export async function getCustomers(search) {
  const conditions = [where('is_deleted', '==', false)];
  // "Starts with" string check
  if (search) conditions.push(where('full_name', '>=', search));
  const q = await getDocs(query(collection(db, 'customers'), ...conditions));
  const customers = [];
  q.forEach((customer) =>
    customers.push({
      id: customer.id,
      ...customer.data(),
    })
  );
  return customers;
}
