<template>
  <div class="p-2">
    <div class="text-center p-4">Are you sure to delete this product?</div>
    <ActionButtons
      class="my-2 text-center"
      :title="'Enter'"
      @cancel="$emit('closeDialog')"
    />
  </div>
</template>

<script>
import ActionButtons from "../../../../components/ActionButtons.vue";
export default {
  components: { ActionButtons },
  props: ["selectedInfo"],
};
</script>