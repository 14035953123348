<template>
  <div>
    <div class="text-center my-4">Are you sure to delete this permission?</div>
    <ActionButtons @cancel="$emit('closeDialog')" title="Enter" />
  </div>
</template>
<script>
import ActionButtons from '../../../../components/ActionButtons.vue';
export default {
  components: { ActionButtons },
  props: ['mainTitle'],
};
</script>