<template>
  <div>
    <DialogTopBar @closeDialog="close" mainTitle="Add Permission" />
    <div class="mt-4">
      <div v-for="(item, i) in permission" :key="i">
        <InputField :label="item.label" :type="item.type" v-model="item.value" />
        <div v-if="item.err" class="text-red-900 font-bold text-xs">
          {{ item.label }} is required
        </div>
      </div>
      <ActionButtons :title="'Add Permission'" @cancel="close" @submit="submit" />
    </div>
  </div>
</template>
<script>
import DialogTopBar from '@/components/DialogTopBar.vue';
import ActionButtons from '@/components/ActionButtons.vue';
import InputField from '@/components/form/InputField.vue';
export default {
  data() {
    return {
      permission: [
        {
          label: 'Name',
          value: '',
          err: false,
          type: 'text',
        },
        {
          label: 'Value',
          value: '',
          err: false,
          type: 'text',
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit('closeDialog');
      this.clearData();
      this.clearErr();
    },
    clearData() {
      for (const key in this.permission) {
        this.permission[key].value = '';
      }
    },
    clearErr() {
      for (const key in this.permission) {
        this.permission[key].err = false;
      }
    },
    submit() {
      for (const key in this.permission) {
        this.permission[key].err = false;
        console.log('key', this.permission[key]);
        if (this.permission[key].value == '') {
          this.permission[key].err = true;
        }
      }
    },
  },
  components: { DialogTopBar, ActionButtons, InputField },
};
</script>