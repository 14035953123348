
<template>
  <div>
    <PermissionsForm />
    <ActionButtons :title="'Edit Permission'" @cancel="$emit('cancel')" @submit="submit" />
  </div>
</template>
<script>
import ActionButtons from '../../../../components/ActionButtons.vue';
import PermissionsForm from './PermissionsForm.vue';
export default {
  data() {
    return {};
  },
  components: { ActionButtons, PermissionsForm },
  methods: {
    submit() {
      console.log('');
    },
  },
};
</script>