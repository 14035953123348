<template>
  <div class="border-solid border-black border-4">
    <!-- Selected Manager is {{ selected_manager }} -->
    <div>
      <div class="p-10 sm:flex items-center flex-wrap">
        <div class="border-r-2 border-solid border-gray-200 sm:pr-10 w-1/3 flex-none">
          <img :src="theVirtualSpace.logo_url" alt="Logo" class="w-full h-auto flex-shrink-0" />
        </div>
        <div class="px-10 w-2/3 flex-none">
          <div class="font-bold text-xl">
            {{ theVirtualSpace.name }}
          </div>
          <p class="text-sm">
            {{ theVirtualSpace.description }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="relative">
        <div v-for="(url, i) in photo_urls.slice(0)" :key="i" class="relative">
          <img :src="url" alt="" v-if="i == 0" class="w-full h-full" />
        </div>
        <div
          class="p-6 bg-[#13282D] sm:absolute z-50 text-white right-0 top-0 h-full w-[280px] flex items-center"
        >
          <!-- vfor here of images  -->
          <div>
            <div class="my-4">
              <div class="flex justify-center w-full">
                <img src="../../../../assets/icons/users/ryan.png" alt="user_image" class="w-1/3" />
              </div>
              <div class="text-center my-2">
                <div>
                  {{ login_fullname }}
                </div>
                <div class="text-center text-md text-[#FF9800]">-</div>
              </div>
            </div>

            <div class="my-4">
              <div class="flex justify-center w-full">
                <img src="../../../../assets/icons/users/ryan.png" alt="user_image" class="w-1/3" />
              </div>
              <div class="text-center my-2">
                <div>{{ selectedBackup }}</div>
                <div class="text-center text-md text-[#FF9800]">-</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- {{ selected_manager.full_name }} -->
      <!-- {{ user_full_name }} -->
      <!--  -->
    </div>
    <div class="sm:flex">
      <div class="p-10 w-2/3">
        <div class="text-2xl text-[#FF9800] font-bold mb-4" v-if="invitation_type">
          This is a {{ invitation_type }} invitation
        </div>
        <div v-else>Please choose an invitation type...</div>
        <div class="font-bold text-xl">
          Hello {{ full_name.length ? full_name[0].full_name : '' }}
        </div>
        <div v-if="message_content">
          <p class="text-md text-left" v-html="message_content"></p>
        </div>
        <div v-else>Please type your message using the editor above...</div>
        <!-- <div class="font-bold mt-10">Please use this PIN to open our showroom:</div>
        <div class="text-[#FF9800] text-3xl font-extrabold">8888</div> -->
      </div>
      <div class="sm:w-1/3 p-10 border-l-2 sm:border-solid border-gray-200 py-10">
        <div class="text-sm">
          To enter our showroom, you may scan the OR code or click the button below.
        </div>
        <div class="rounded-md bg-[#FF9800] p-6 mt-4">
          <div class="text-center text-white text-xl">SCAN ME</div>
          <div class="text-center my-4 flex justify-center">
            <img :src="qrcodeImage" alt="" />
          </div>
        </div>
        <div class="my-4 text-center">
          <v-btn class="w-full" color="black" dark @click="onClickEnterShowroom"
            >Enter Showroom</v-btn
          >
        </div>
        <div class="text-center mt-4 text-[#FF9800] font-bold">Expiration</div>
        <div class="text-center">
          {{ expire_date }}
        </div>
      </div>
    </div>
    <!-- <div class="template-2" v-if="template_selected === 't2'">
      <div style="display: flex">
        <div
          style="width: 30%; margin: 2%; text-align: center"
          v-for="(url, i) in photo_urls.slice(2, 5)"
          :key="i"
        >
          <img style="width: 100%" :src="url" alt="" />
        </div>
      </div>
    </div>
    <div class="template-3" v-else-if="template_selected === 't3'">
      <div style="display: flex">
        <div
          style="margin: 3%; text-align: center"
          v-for="(url, i) in photo_urls.slice(2, photo_urls.length)"
          :key="i"
        >
          <img style="width: 100%" :src="url" alt="" />
        </div>
      </div>
    </div>
    <div v-else></div> -->
  </div>
</template>

<script>
import user from '../../../../assets/icons/users/ryan.png';
export default {
  data() {
    return {
      userImage: user,
      PIN: [
        {
          pin: 8,
        },
        {
          pin: 8,
        },
        {
          pin: 8,
        },
        {
          pin: 8,
        },
      ],
      url: this.theVirtualSpace.url,
      company: '',
      avatar_url: '',
      user_full_name: '',
    };
  },
  mounted() {
    this.setUserDetails();
  },
  props: [
    'selectedBackup',
    'expire_date',
    'qrcodeImage',
    'photo_urls',
    'login_fullname',
    'theVirtualSpace',
    'message_content',
    'template_selected',
    'invitation_type',
    'full_name',
    'selected_manager',
  ],
  methods: {
    onClickEnterShowroom() {
      window.location.href = this.theVirtualSpace.url;
    },
    onSendInvite() {
      alert('Invitation Sent!');
    },
    setUserDetails() {
      const userDetails = JSON.parse(localStorage.getItem('user'));
      this.company = userDetails.company;
      this.avatar_url = userDetails.avatar_url;
      this.user_full_name = userDetails.full_name;
    },
  },
};
</script>
