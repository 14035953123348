<template>
  <div>
    <DialogTopBar class="my-2" @closeDialog="close" mainTitle="Add Company" />
    <div class="grid gap-2 grid-cols-2">
      <InputField label="Company Name" type="text" v-model="company_name" />
      <InputField label="Unique ID" type="text" v-model="unique_id" :readOnly="readOnlyUniqueId" />
      <v-select label="Plan" v-model="plan_id" :items="plan_choices" item-text="name" item-value="id" dense></v-select>
      <div>
        <label>Status</label>
        <v-radio-group v-model="status" row>
          <v-radio v-for="(s, i) in status_choices" :key="i" :label="s.label" :value="s.value"></v-radio>
        </v-radio-group>
      </div>
      <InputField label="Street" type="text" v-model="street" />
      <InputField label="City" type="text" v-model="city" />
      <InputField label="State" type="text" v-model="state" />
      <InputField label="Zip code" type="text" v-model="zip" />
      <InputField label="Phone" type="text" v-model="phone" />
      <v-select label="Virtual Space Type" v-model="virtual_space_type" :items="virtual_space_type_choices"
        dense></v-select>
      <InputField label="Contract" type="text" v-model="smart_contract_address" />
      <v-select label="Tags" v-model="tags" :items="tags_choices" multiple dense></v-select>
      <div class="flex caret-lime-300">
        <v-checkbox class="mr-4" label="Show Hotspot" v-model="show_hotspot"></v-checkbox>
        <v-checkbox label="Show Price" v-model="show_price"></v-checkbox>
      </div>
      <div class="text-center my-2">
        <div class="flex flex-row justify-center mb-4">
          <img :src="logoPreviewUrl" class="object-cover" />
        </div>
        <v-btn small elevation="" color="" @click="onUploadClick"> Upload Logo </v-btn>
        <input class="hidden" type="file" ref="logoFileUpload" @change="onLogoFileUpload" accept="image/*" />
      </div>
      <InputField label="Description" type="text" v-model="description" />
    </div>
    <ActionButtons title="Submit" @cancel="close" @submit="submit" />
  </div>
</template>

<script>
import ActionButtons from '@/components/ActionButtons.vue';
import DialogTopBar from '@/components/DialogTopBar.vue';
import InputField from '@/components/form/InputField.vue';
import { db } from '@/firebase';
import { doc, getDoc } from '@firebase/firestore';
import { getPlans } from '../../apis/get-plans';

export default {
  props: ['id', 'readOnlyUniqueId'],
  components: { InputField, ActionButtons, DialogTopBar },
  data() {
    return {
      company_name: '',
      unique_id: '',
      plan_id: '',
      status: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      virtual_space_type: '',
      smart_contract_address: '',
      tags: [],
      show_hotspot: false,
      show_price: false,
      logo_file: null,
      existing_logo_url: '',
      description: '',
      tags_choices: ['Likes', 'Classic', 'Modern', 'Abstract', 'Geometric', 'Animals', 'Nature'],
      plan_choices: [],
      virtual_space_type_choices: [
        { text: 'Virtual Showroom', value: 'showroom' },
        { text: 'Virtual Real Estate', value: 'realestate' },
      ],
      status_choices: [
        { label: 'Pending', value: 'pending' },
        { label: 'Activated', value: 'activated' },
      ],
    };
  },
  watch: {
    id: {
      async handler() {
        if (!this.id) return;
        this.plan_choices = await getPlans();
        const company = await this.getCompany(this.id);
        this.company_name = company.company_name;
        this.unique_id = this.id;
        this.plan_id = company.plan_id;
        this.status = company.status;
        this.street = company.street;
        this.city = company.city;
        this.state = company.state;
        this.zip = company.zip;
        this.phone = company.phone;
        this.virtual_space_type = company.virtual_space_type;
        this.smart_contract_address = company.smart_contract_address;
        this.tags = company.tags;
        this.show_hotspot = company.show_hotspot;
        this.show_price = company.show_price;
        this.logo_file = null;
        this.existing_logo_url = company.logo_url;
        this.description = company.description;
      },
      immediate: true,
    },
  },
  computed: {
    logoPreviewUrl() {
      if (this.logo_file !== null) return URL.createObjectURL(this.logo_file);
      if (this.existing_logo_url) return this.existing_logo_url;
      return null;
    },
  },
  methods: {
    onUploadClick() {
      this.$refs.logoFileUpload.click();
    },
    onLogoFileUpload(e) {
      this.logo_file = e.target.files[0];
    },
    async getCompany(id) {
      const docSnap = await getDoc(doc(db, 'companies', id));
      if (!docSnap.exists()) return null;
      return docSnap.data();
    },
    async submit() {
      let canSubmit = true;
      console.log('company_name', this.company_name);
      if (!this.company_name) {
        canSubmit = false;
        alert('Company name is required');
        return;
      }
      if (canSubmit) {
        this.$emit('submit', {
          // Payload
          unique_id: this.unique_id,
          logo_file: this.logo_file,
          existing_logo_url: this.existing_logo_url,
          // Form
          company_name: this.company_name,
          plan_id: this.plan_id,
          plan: this.plan_choices.find((p) => p.id === this.plan_id)?.name ?? '',
          status: this.status,
          street: this.street,
          city: this.city,
          state: this.state,
          zip: this.zip,
          phone: this.phone,
          virtual_space_type: this.virtual_space_type,
          smart_contract_address: this.smart_contract_address,
          tags: this.tags,
          show_hotspot: this.show_hotspot,
          show_price: this.show_price,
          logo_url: this.existing_logo_url,
          description: this.description,
          is_deleted: false,
        });
      }
    },
    close() {
      this.clearData();
      this.$emit('cancel');
    },
    clearData() {
      this.company_name = '';
      this.unique_id = '';
      this.plan_id = '';
      this.status = '';
      this.street = '';
      this.city = '';
      this.state = '';
      this.zip = '';
      this.phone = '';
      this.virtual_space_type = '';
      this.smart_contract_address = '';
      this.tags = [];
      this.show_hotspot = false;
      this.show_price = false;
      this.logo_file = null;
      this.existing_logo_url = '';
      this.description = '';
    },
  },
};
</script>