<template>
  <div class="sm:flex">
    <div class="p-10 sm:w-2/3">
      <div>
        <v-text-field
          type="text"
          label="Name"
          v-model="name"
          outlined
          color
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          type="text"
          label="URL"
          v-model="url"
          outlined
          color
        ></v-text-field>
      </div>
      <div>
        <div>Background Music</div>
        <v-text-field
          type="text"
          error-count=""
          placeholder=""
          label="Music URL"
          v-model="music_url"
          outlined
          color
        ></v-text-field>
        <div>Preview Music</div>

        <div>Use default from list</div>
        <div>
          <v-select
            color
            v-model="default_music"
            :items="default_music_list"
            filled
            item-value="title"
            item-text="title"
            label="Default Music"
          ></v-select>
          {{ default_music }}
        </div>
        <div>Upload a music</div>
        <div>
          <input type="file" />
        </div>
      </div>
      <div>
        Description
        <div>
          <v-text-field
            name="Description"
            label="Description"
            v-model="description"
            class="input-group--focused"
            single-line
          ></v-text-field>
        </div>
      </div>
      <div>
        <div>Virtual Space Type</div>
        <div>
          <v-select
            color
            v-model="tyoe"
            :items="types"
            filled
            label=""
          ></v-select>
        </div>
      </div>
      <div>
        <v-checkbox label="label" v-model="value" value="value"></v-checkbox>
      </div>
      <div>
        <v-checkbox label="label" v-model="value" value="value"></v-checkbox>
      </div>
      <div>
        <v-checkbox label="label" v-model="value" value="value"></v-checkbox>
      </div>
      <div>
        <div>
          <v-select
            color
            v-model="tyoe"
            :items="types"
            filled
            label="Options offered"
          ></v-select>
        </div>
      </div>
      <div>
        <v-select
          label="Authorized User"
          :items="items"
          v-model="value"
          multiple
          max-height="example"
          hint="Pick your favorite states"
          persistent-hint
        ></v-select>
      </div>
      <div>
        <v-text-field
          type="text"
          error-count=""
          placeholder=""
          label="Brand Name"
          append-icon=""
          v-model="brand_name"
          outlined
          color
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          type="text"
          error-count=""
          placeholder=""
          label="Primary Brand Color"
          append-icon=""
          v-model="primary_brand_color"
          outlined
          color
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          type="text"
          error-count=""
          placeholder=""
          label="Contact Button Text"
          append-icon=""
          v-model="primary_brand_color"
          outlined
          color
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          type="text"
          error-count=""
          placeholder=""
          label="Take a Tour Button Text"
          append-icon=""
          v-model="primary_brand_color"
          outlined
          color
        ></v-text-field>
      </div>
      <div>
        <v-checkbox
          label="Show Product Price"
          v-model="product_price"
          value="value"
        ></v-checkbox>
      </div>
    </div>
    <div class="sm:w-1/3">
      <div>
        <div>Assets</div>
        <div>Thumbnail</div>
        <div>Drag and drop here</div>
        <div>Photos</div>
        <div>Drag and drop images here</div>
        <div>Logo</div>
        <div>Drag and drop here</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      default_music: null,
      default_music_list: [
        {
          id: 1,
          title: "Gold",
        },
        {
          id: 2,
          title: "Shout",
        },
      ],
      types: [
        {
          id: 1,
          title: "Virtual",
        },
      ],
    };
  },
};
</script>