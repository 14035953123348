<template>
  <div class="sm:flex w-full mr-2">
    <div class="flex justify-end">
      <div class="flex items-center justify-end">
        <SimpleSearch
          @input="updateInputValue"
          class="mr-8"
          :placeholder="'Search Company...'"
          @SearchInput="updateInputValue($event)"
        />
        <div>
          <v-btn
            dark
            rounded
            elevation="0"
            class="text-white"
            color="#F29E37"
            @click="addPermission"
            >Add Permission
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleSearch from '../../../../components/SimpleSearch.vue';
export default {
  data() {
    return {
      value: null,
    };
  },
  components: { SimpleSearch },
  methods: {
    addPermission() {
      this.$emit('addPermission');
    },
    updateInputValue(val) {
      this.value = val;
      // console.log(this.value);
      this.$emit(`SearchInput`, val);
    },
  },
};
</script>
