<template>
  <div>
    <DialogTopBar @closeDialog="$emit('cancel')" mainTitle="Add Plan" />
    <PlansForm @submit="submit" @closeDialog="$emit('cancel')" />
  </div>
</template>
<script>
import DialogTopBar from '@/components/DialogTopBar.vue';
import { db } from '@/firebase';
import { addDoc, collection } from 'firebase/firestore';
import PlansForm from "./PlansForm.vue";

export default {
  components: { PlansForm, DialogTopBar },
  methods: {
    async submit(plan) {
      await addDoc(collection(db, 'plans'), {
        name: plan.name,
        price: plan.price,
        user_limit: plan.user_limit,
        permissions: plan.permissions,
        status: plan.status,
        is_deleted: false,
      });
      this.$toast.open('Created plan');
      this.$emit('cancel');
    },
  },
};
</script>