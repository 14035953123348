<template>
  <div class="sm:flex w-full">
    <div class="sm:w-1/2 sm:flex items-center">
      <!-- <v-btn small class="mr-4 w-full sm:w-[100px]">Display All</v-btn> -->
      <!-- <div class="sm:w-1/3">
          <v-select
            label="Search Customer Name"
            :items="items"
            v-model="value"
            autocomplete
          ></v-select>
        </div> -->
    </div>
    <div class="flex justify-end" :class="$route.name == 'Users' ? 'sm:w-1/2' : 'w-full py-6'">
      <div class="flex items-center justify-end">
        <SimpleSearch
          @input="updateInputValue"
          class="mr-8"
          :placeholder="'Global Search'"
          @SearchInput="updateInputValue($event)"
          :search_id="'Customer'"
        />
        <div>
          <v-btn rounded dark elevation="" color="#FF9800" @click="addCustomer"
            >Add Company</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { EventBus } from '@/EventBus';

import SimpleSearch from '@/components/SimpleSearch.vue';

export default {
  data() {
    return {
      search: '',
      value: '',
      items: [],
    };
  },
  components: { SimpleSearch },
  mounted() {
    // EventBus.$on('SearchInput', this.handleSearchBus);
  },
  methods: {
    onSearchSubmit() {
      this.$emit('search', this.search);
    },
    addCustomer() {
      this.$emit('addCustomer');
    },
    updateInputValue(val) {
      this.value = val;
      // console.log(this.value);
      this.$emit(`SearchInput`, val);
    },
    // handleSearchBus(search_value_bus) {
    //   //this function gets the current search valu
    //   // console.log('ex', search_value_bus);
    // },
  },
};
</script>
