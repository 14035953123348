<template>
  <div class="h-full w-full mt-[24px]">
    <!-- <h1>{{ name }}</h1> -->
    <canvas :id="name" style="width: 100%; height: auto"></canvas>
  </div>
</template>

<script>
/* eslint-disable */
import { Chart } from 'chart.js/auto';
export default {
  name: 'LineGraph',
  props: {
    name: String,
  },
  mounted() {
    const ctx = document.getElementById(this.name);
    // X Labels
    const labels = [];
    // const labels = ['1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7'];
    const data = {
      labels: labels ?? [],
      datasets: [
        {
          label: 'Total Showroom Users',
          data: [1, 0, -1],
          fill: false,
          backgroundColor: '#1B58C2',
          borderWidth: '0px',
          tension: 0.1,
        },
        {
          label: 'Revenue',
          data: [1, 0, -1],
          fill: false,
          backgroundColor: '#FF675B',
          borderWidth: '0px',
          tension: 0.1,
        },
      ],
    };
    new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        //position legend labels bottom
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
        },
      },
    });
  },
};
</script>
