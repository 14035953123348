<template>
  <div>
    <label v-if="label" class="form-label inline-block text-black text-xs">
      {{ label }}
    </label>
    <input :type="type" class="
      block
      w-full
      px-3
      py-1
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600
    " :placeholder="label" :value="value" :checked="value === true ? 'true' : undefined" @input="handleChange"
      :readonly="readOnly" />
  </div>
</template>

<script>
export default {
  props: ["type", "label", "value", "readOnly"],
  methods: {
    handleChange(e) {
      this.$emit("input", e.target.value);
    }
  }
};
</script>