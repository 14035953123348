<template>
  <div>
    <CompanyForm @cancel="close" @submit="submit" />
  </div>
</template>
<script>
import { db, storage } from '@/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import CompanyForm from './CompanyForm.vue';

export default {
  components: { CompanyForm },
  methods: {
    async submit(payload) {
      try {
        let logoUrl = payload.existing_logo_url;
        let defaultUniqueId;
        if (!payload.unique_id) defaultUniqueId = payload?.company_name.toLowerCase();

        await setDoc(doc(db, 'companies', defaultUniqueId), {
          company_name: payload.company_name,
          plan_id: payload.plan_id,
          plan: payload.plan,
          status: payload.status,
          street: payload.street,
          city: payload.city,
          state: payload.state,
          zip: payload.zip,
          phone: payload.phone,
          virtual_space_type: payload.virtual_space_type,
          smart_contract_address: payload.smart_contract_address,
          tags: payload.tags,
          show_hotspot: payload.show_hotspot,
          show_price: payload.show_price,
          logo_url: payload.existing_logo_url,
          description: payload.description,
          is_deleted: false,
        });
        if (payload.logo_file !== null) {
          const ext = payload.logo_file.name.split('.').pop();
          const fileName = `companies/logo-${defaultUniqueId}.${ext}`;
          const logoRef = ref(storage, fileName);
          await uploadBytes(logoRef, payload.logo_file);
          logoUrl = await getDownloadURL(logoRef);
        }

        await setDoc(doc(db, 'companies', defaultUniqueId), { logo_url: logoUrl }, { merge: true });
        this.$toast.open('Created company');
        this.close();
      } catch (err) {
        console.error(err);
        this.$toast.open({ type: 'error', message: `Error: ${err.message}` });
      }
    },
    close() {
      this.$emit('cancel');
    },
  },
};
</script>
