<template>
  <div class="my-2">
    <div class="grid gap-4 grid-cols-2">
      <div v-for="(item, i) in planForm" :key="i">
        <InputField :label="item.label" :type="item.type" v-model="item.value" />
        <div v-if="item.err" class="text-red-900 font-bold w-[200px] text-sm">
          {{ item.label }} is required
        </div>
      </div>
    </div>
    <div class="flex">
      <v-select class="mr-10" label="Permissions" :items="permission_choices" v-model="permissions" multiple
        autocomplete></v-select>
    </div>

    <v-radio-group v-model="status">
      <template v-slot:label>
        <div>Status</div>
      </template>
      <v-radio v-for="(item, i) in status_choices" :key="i" :label="item.text" :value="item.value"></v-radio>
    </v-radio-group>

    <ActionButtons title="Submit" @cancel="close" @submit="submit" />
  </div>
</template>
<script>
import ActionButtons from '@/components/ActionButtons.vue';
import InputField from '@/components/form/InputField.vue';
import { db } from '@/firebase';
import { doc, getDoc } from 'firebase/firestore';

export default {
  props: ['id'],
  components: { ActionButtons, InputField },
  data() {
    return {
      planForm: [
        { label: 'Name', name: 'name', type: 'text', value: '', required: true, err: false },
        { label: 'Price', name: 'price', type: 'text', value: '', required: false, err: false },
        {
          label: 'User Limit',
          name: 'user_limit',
          type: 'text',
          value: 0,
          required: false,
          err: false,
        },
      ],
      permissions: [],
      status: '',
      status_choices: [
        { text: 'Pending', value: 'pending' },
        { text: 'Activated', value: 'activated' },
      ],
      permission_choices: [
        { text: 'Dashboard', value: 'dashboard' },
        { text: 'Product', value: 'product' },
        { text: 'Task Management', value: 'task_management' },
        { text: 'Reports', value: 'reports' },
        { text: 'Settings', value: 'settings' },
        { text: 'Admin Management', value: 'admin_management' },
        { text: 'Notifications', value: 'notifications' },
        { text: 'Showroom', value: 'showroom' },
        { text: 'Customer', value: 'customer' },
      ],
      errors: [],
    };
  },
  watch: {
    id: {
      async handler() {
        if (!this.id) return;
        const plan = await this.getPlan(this.id);
        let find = (name) => this.planForm.find((p) => p.name === name) ?? {};
        find('name').value = plan.name ?? '';
        find('price').value = plan.price ?? '';
        find('user_limit').value = plan.user_limit ?? '';
        this.permissions = plan.permissions ?? '';
        this.status = plan.status ?? '';
      },
      immediate: true,
    },
  },
  methods: {
    async getPlan(id) {
      const docSnap = await getDoc(doc(db, 'plans', id));
      if (!docSnap.exists()) return null;
      return docSnap.data();
    },
    submit() {
      let canSubmit = true;
      for (const key in this.planForm) {
        if (!this.planForm[key].required) continue;
        this.planForm[key].err = false;
        if (this.planForm[key].value == '') {
          this.planForm[key].err = true;
        }
      }
      if (!canSubmit) return;
      let findField = (name) => this.planForm.find((p) => p.name === name)?.value ?? '';
      this.$emit('submit', {
        id: this.id,
        name: findField('name'),
        price: findField('price'),
        user_limit: findField('user_limit'),
        permissions: this.permissions,
        status: this.status,
      });
    },
    clearErr() {
      for (const key in this.planForm) {
        this.planForm[key].err = false;
      }
    },
    close() {
      this.clearErr();
      this.$emit('closeDialog');
      for (const key in this.planForm) {
        this.planForm[key].value = '';
      }
    },
  },
};
</script>