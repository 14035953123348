<template>
  <div class="flex relative">
    <!-- <pre>
      {{ theVirtualSpace }}
    </pre> -->

    <!-- <DialogTopBar
      @closeDialog="$emit('closeDialog')"
      :mainTitle="`Share Virtual Space - ${name}`"
    /> -->
    <div class="m-4 p-4 w-full">
      <h1 class="text-3xl font-bold text-center mb-6">Share Virtual Showroom</h1>
      <button @click="cancel" class="px-4 py-2 rounded absolute top-0 right-0 mt-4 mr-4">x</button>
      <div>
        Select Date
        <div class="m-1">
          <input
            class="m-1 mx-3 border-2 border-solid border-gray rounded-md"
            type="date"
            v-model="from_date"
            value
          />
          <input
            class="m-1 mx-3 border-2 border-solid border-gray rounded-md"
            type="time"
            v-model="from_time"
          />
          <span>to</span>
          <input
            class="m-1 mx-3 border-2 border-solid border-gray rounded-md"
            type="date"
            v-model="to_date"
            :disabled="noExpiry"
          />
          <input
            class="m-1 mx-3 border-2 border-solid border-gray rounded-md"
            type="time"
            v-model="to_time"
            :disabled="noExpiry"
          />
          <input
            class="m-1 mx-2 border-2 border-solid border-gray rounded-md"
            type="checkbox"
            v-model="noExpiry"
          />24/7 365
        </div>
      </div>
      <div class="flex mt-4">
        <!-- <div class="w-1/4">
          Time Zone
          <div class="m-2">
            <v-select
              v-model="selectedTimeZone"
              :items="timeZones"
              placeholder="Select Time Zone"
              @change="updateCurrentTime"
              required
            ></v-select>
          </div>
        </div> -->

        <!-- <div v-if="currentTime">Current Time: {{ currentTime }}</div> -->
        <div class="w-1/4">
          Invitation Type
          <div class="m-2">
            <v-select
              v-model="invitation_type"
              :items="invitation_type_choices"
              placeholder="Invitation Type"
              @change="updateMessageContent"
            ></v-select>
          </div>
        </div>

        <div class="w-1/5">
          From:
          <div class="m-2">
            <v-select
              :items="senders"
              v-model="selected_sender"
              item-text="name"
              return-object
              :label="selected_sender"
              @change="updateMessageContent"
            ></v-select>
          </div>
        </div>
        <div class="w-1/4">
          Campaign/Tracing Coded
          <div class="m-2 mt-4">
            <v-text-field
              dense
              type="text"
              placeholder="Campaign/Tracing Code"
              v-model="campaign_tracing_code"
              outlined
              color
            ></v-text-field>
          </div>
        </div>
      </div>
      <div>
        To:
        <div class="m-2 mx-3">
          <v-autocomplete
            autocomplete
            color
            v-model="selected_customers"
            :items="customer_choices"
            filled
            placeholder="Select guests..."
            item-text="full_name"
            return-object
            multiple
            @change="updateMessageContent"
          ></v-autocomplete>
        </div>
      </div>
      <div>
        <v-switch color="orange" label="With backup" v-model="wBackup">Yes</v-switch>
        <div class="m-2 mx-3">
          <v-autocomplete
            autocomplete
            color
            v-if="wBackup"
            v-model="backupName"
            :items="backups"
            filled
            label="Select backup..."
            item-text="name"
            return-object
            @change="onSelectedBackupName"
          ></v-autocomplete>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="m-2">
          <v-btn class="mx-2" color="success" disabled @click="onAutoShowing">Auto Showing</v-btn>
          <v-btn class="mx-2" color="orange" dark> Live Showing </v-btn>
        </div>
        <div class="m-2" v-if="selectShowing">
          <v-btn class="w-full my-4" small>Select Showing</v-btn>
        </div>
        <div v-if="canSend" class="m-2">
          <v-btn color="orange" @click="sendInvitation" dark>Send</v-btn>
        </div>
      </div>
      <div class="my-8 flex items-center">
        <hr class="border-gray-300 flex-grow" />
        <span class="px-4 text-gray-500">Preview</span>
        <hr class="border-gray-300 flex-grow" />
      </div>
      <div class="flex justify-center items-center flex-col">
        <div class="mb-4">
          <vue-editor v-model="message_content" required> </vue-editor>
        </div>
        <!-- <div>
          <v-btn
            text
            @click="onClickPreviewButtons(item.value)"
            color="success"
            v-for="(item, i) in previewButtons"
            :key="i"
          >
            {{ item.text }}
          </v-btn>
        </div> -->

        <!-- <div>
          <preview-dialog
            :selectedBackup="selectedBackup"
            :expire_date="expire_date"
            :message_content="message_content"
            :theVirtualSpace="theVirtualSpace"
            :photo_urls="photo_urls"
            :qr_value="qr_value"
            :template_selected="template_selected.value"
            :invitation_type="invitation_type"
            :full_name="selected_customers"
            :selected_manager="selected_manager"
            :qrcodeImage="qrcodeImage"
            :login_fullname="userLoggedIn.full_name"
          />
        </div> -->
        <!-- <div v-else-if="medium">
          <preview-medium
            :selectedBackup="selectedBackup"
            :expire_date="expire_date"
            :login_fullname="userLoggedIn.full_name"
            :message_content="message_content"
            :theVirtualSpace="theVirtualSpace"
            :photo_urls="photo_urls"
            :qr_value="qr_value"
            :template_selected="template_selected.value"
            :invitation_type="invitation_type"
            :full_name="full_name"
            :selected_manager="selected_manager"
            :qrcodeImage="qrcodeImage"
          />
        </div> -->
        <div>
          <preview-small
            :virtualSpace="theVirtualSpace"
            :invitationType="invitation_type"
            :messageContent="message_content"
            :expiration="expirationTimeDate"
            :user="userLoggedIn"
            :backup="selectedBackup"
            :qrImage="qrcodeImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import supabase from '../../../../database/config/supabaseClient';
import moment from 'moment-timezone';
import Template from '../invitation/EmailContent.vue';
import PreviewMedium from '../invitation/PreviewMedium.vue';
import PreviewSmall from '../invitation/PreviewSmall.vue';
import QR from 'qrcode';
import uid from 'uniqid';
import PreviewDialog from '../dialogs/PreviewDialog.vue';
import { VueEditor } from 'vue2-editor';
import DialogTopBar from '@/components/DialogTopBar.vue';
import { getCustomers } from '@/views/customers/get-customers';
import { getShowroomManager } from '@/views/customers/get-managers';
import { getVirtualSpace } from '../../apis/get-virtual-space';
import emailjs from '@emailjs/browser';
import generateStaticTemplate from '../../staticEmailTemplate';

export default {
  props: ['virtualSpaceId'],
  // eslint-disable-next-line vue/no-unused-components
  components: {
    DialogTopBar,
    VueEditor,
    PreviewDialog,
    PreviewSmall,
    PreviewMedium,
    // QrcodeVue
  },
  computed: {
    async getTimeZones() {
      const tz = await TZ.getTimezone('America/Los_Angeles');
      this.timezones = tz;
    },
    expirationTimeDate() {
      if (this.noExpiry) {
        return 'Never';
      }
      return `${this.to_date} • ${this.to_time}`;
    },
    canSend() {
      if (!!this.invitation_type && !!this.selected_sender && !!this.selected_customers.length) {
        return true;
      } else return false;
    },
  },
  data() {
    return {
      logo_url: null,
      full_name: null,
      selectedTimeZone: '',
      currentTime: '',
      timeZones: ['America/Los_Angeles', 'America/Vancouver', 'America/Tijuana', 'America/Phoenix'],
      timezones: [],
      expire_date: null,
      selected_sender: null,
      senders: [
        {
          name: 'John Doe',
        },
        {
          name: 'James Dean',
        },
      ],
      backupName: null,
      selectedBackup: null,
      backups: [
        {
          name: 'John Doe',
        },
        {
          name: 'James Doe',
        },
        {
          name: 'Jean Doe',
        },
      ],
      wBackup: null,
      template: Template,
      large: null,
      medium: null,
      small: null,
      previewButtons: [
        {
          text: 'Desktop View',
          value: 'lg',
          lg: true,
        },
        {
          text: 'Tablet View',
          value: 'md',
          md: true,
        },
        {
          text: 'Mobile View',
          value: 'sm',
          sm: true,
        },
      ],
      qrcodeImage: null,
      selectShowing: false,
      userLoggedIn: {},
      time_zone: null,
      theVirtualSpace: [],
      previewDialog: false,
      photo_urls: [],
      message_content: [],
      name: '',
      qr_value: 'https://example.com',
      from_time: '',
      to_time: '',
      is24by7: false,
      send_email_true: false,
      to_date: '',
      from_date: '12/05/2023',
      invitation_type: null,
      campaign_tracing_code: '',
      checkbox: true,
      sender: '',
      template_selected: '',
      selected_customers: [],
      selected_manager: [], //selected manager
      fullName: '',
      noExpiry: false,
      invitation_type_choices: [
        { text: 'A VIP Invitation', value: 'VIP' },
        { text: 'A Personal Invitation', value: 'Personal' },
        { text: 'A Special Invitation', value: 'Special' },
        { text: 'An Invitation', value: 'Normal' },
      ],
      invitationDefault: {
        text: 'A VIP Invitation',
        value: 'vip',
      },
      showroom_manager: [],
      customer_choices: [],
      templates: [
        { text: 'Template 1', value: 't1' },
        { text: 'Template 2', value: 't2' },
        { text: 'Template 3', value: 't3' },
      ],
    };
  },
  // async campaign_tracing_code() {
  // },

  watch: {
    to_date: {
      handler(e) {
        if (e) {
          this.expire_date = e;
        }
      },
      immediate: true,
    },
    noExpiry: {
      handler() {
        console.log(this.noExpiry);
      },
    },
    virtualSpaceId: {
      async handler() {
        if (!this.virtualSpaceId) return;
        const vs = await getVirtualSpace(this.virtualSpaceId);
        console.log('VS:', vs);

        await this.setUserDetails();
        this.selected_sender = this.userLoggedIn.full_name;
        this.theVirtualSpace = vs;
        // this.logo_url=this.theVirtualSpace.logo_url;
        this.photo_urls = vs.photo_urls;
        this.name = vs.name;
        await this.qrcodeGenerate(vs.url);
        this.campaign_tracing_code = uid('1cd-');
        await this.getTimeZones();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.customer_choices = await getCustomers();
    console.log('aight', this.customer_choices);
    this.showroom_manager = (await getShowroomManager()).filter(function (check) {
      return check.is_showroom_manager == true;
    });
    this.getCurrentDate();
  },

  methods: {
    onSelectedCustomers(item) {
      this.full_name = item[0].full_name;
      console.log('item full_name selected customer', item[0].full_name);
    },
    onSelectedBackupName(item) {
      console.log('item backupname:', item);
      this.selectedBackup = item.name;
      console.log('this.selectedBackup', this.selectedBackup);
    },
    async getBackupsFromSupa() {
      const res = await supabase.from('backups').select('*');
      this.backups = res.data;
    },
    updateCurrentTime() {
      const timeZone = this.selectedTimeZone;
      const currentMoment = moment().tz(timeZone);
      this.currentTime = currentMoment.format('YYYY-MM-DD HH:mm:ss');
    },
    onClickPreviewButtons(item) {
      if (item == 'lg') {
        this.large = true;
        this.medium = false;
        this.small = false;
      } else if (item == 'md') {
        this.medium = true;
        this.large = false;
        this.small = false;
      } else {
        this.small = true;
        this.large = false;
        this.medium = false;
      }
    },
    async qrcodeGenerate(data) {
      const qrCodeUrl = await QR.toDataURL(data);
      this.qrcodeImage = qrCodeUrl;
    },
    onAutoShowing() {
      this.selectShowing = true;
    },
    async setUserDetails() {
      const userDetails = await JSON.parse(localStorage.getItem('user'));
      this.userLoggedIn = userDetails;
      console.log('userDetails:', userDetails);
    },
    capitalized(text) {
      return text.toUpperCase();
    },

    requiredFieldRule(value) {
      if (!value) {
        return 'This field is required';
      }
      return true;
    },
    // send email
    async sendInvitation() {
      if (!this.invitation_type || !this.selected_sender || !this.selected_customers.length) {
        this.fillFormsNotif();
        return;
      }
      // if (this.selected_customers == 0) {
      //   return alert('Please fill in all fields');
      // }

      // try {
      //   this.selected_customers.forEach(async (e) => {
      //     console.log('item selexted customers', e);
      // const PIN = [
      //   {
      //     pin: 8,
      //   },
      //   {
      //     pin: 8,
      //   },
      //   {
      //     pin: 8,
      //   },
      //   {
      //     pin: 8,
      //   },
      // ];
      this.selected_customers.forEach(async (customer) => {
        const htmlCode = generateStaticTemplate(
          this.theVirtualSpace,
          this.invitation_type,
          customer,
          this.message_content,
          this.expirationTimeDate,
          this.userLoggedIn,
          this.selectedBackup
        );
        emailjs
          .send(
            '1clickdesign',
            'template_jux9ud4',
            {
              to_email: customer.email,
              toName: this.selected_customers.full_name,
              invitation_type: this.invitation_type,
              from_name: this.selected_sender,
              qrcode: this.qrcodeImage,
              html: htmlCode,
            },
            '1q6zo7e--Blhgg73J'
          )
          .then(
            (result) => {
              this.emailSentNotif();
              console.log('SUCCESS!', result.text);
            },
            (error) => {
              this.emailErrorNotif();
              console.log('FAILED...', error.text);
            }
          );
      });
    },
    onClickPreview() {
      this.$router.push('/home/virtual-spaces/preview-invitation');
      // if (this.selected_customers.length !== 0 && this.invitation_type !== null) {
      //   // this.previewDialog = true;
      //   // alert(`You've selected ${this.template_selected.text}`);

      // } else {
      //   alert('Please complete required fields!');
      // }
    },
    saveToClipboard() {},
    print() {},
    submit() {
      console.log('this.selected_customers:', this.selected_customers);
      let canSubmit = true;
      if (this.selected_customers.length == 0) {
        canSubmit = false;
      }
      if (!canSubmit) {
        alert('Please complete the required fields!');
        return;
      }
    },
    cancel() {
      this.$emit('closeDialog');
    },
    getCurrentDate() {
      const now = new Date();
      const currentDate = now.toISOString().split('T')[0];
      const hours = now.getHours();
      const minutes = now.getMinutes();

      const currentTime = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
      this.from_date = currentDate;
      this.from_time = currentTime;
    },
    updateMessageContent() {
      if (!!this.invitation_type && !!this.selected_sender && !!this.selected_customers.length) {
        this.message_content = `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum obcaecati at saepe neque atque pariatur mollitia, exercitationem iusto sunt fugiat aspernatur, cupiditate culpa, voluptatibus dignissimos nisi magnam nemo? Officia, aliquid?`;
      }
    },
    onCustomerChangeWrapper() {
      // this.onSelectedCustomers();
      this.updateMessageContent();
    },
    emailSentNotif() {
      this.$toast.open({
        message: 'Showroom invitation sent!',
        type: 'success',
        duration: 1000 * 3,
        dismissible: true,
        position: 'top-right',
      });
    },
    emailErrorNotif() {
      this.$toast.open({
        message: 'An error occured while sending invitation',
        type: 'error',
        duration: 1000 * 3,
        dismissible: true,
        position: 'top-right',
      });
    },
    fillFormsNotif() {
      this.$toast.open({
        message: 'Please fill in all fields.',
        type: 'error',
        duration: 1000 * 3,
        dismissible: true,
        position: 'top-right',
      });
    },
  },
};
</script>
