<template>
  <div>
    <div class="flex justify-end w-full items-center align-middle">
      <PlansToolbar @addPlans="addPlans" />
    </div>
    <div>
      <v-data-table :headers="headers" :items="displayItems" :loading="loading" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small text @click="edit(item)">
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn small text @click="del(item)">
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="openAddPlanDialog"
      persistent
      :overlay="false"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <AddPlans class="p-4" @cancel="cancelAddPlanDialog" />
      </div>
    </v-dialog>
    <!-- edit  -->
    <v-dialog
      v-model="editDialog"
      persistent
      :overlay="false"
      width="500px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar mainTitle="Edit Plan" @closeDialog="editDialog = false" />
        <EditPlan class="bg-white p-4" :id="selectedPlanId" @cancel="editDialog = false" />
      </div>
    </v-dialog>
    <!-- delete  -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      :overlay="false"
      width="250px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar mainTitle="Delete Plan" @closeDialog="deleteDialog = false" />
        <DeletePlans class="bg-white" mainTitle="Delete Plan" @closeDialog="deleteDialog = false" />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import DialogTopBar from '@/components/DialogTopBar.vue';
import moment from 'moment';
import { getPlans } from './apis/get-plans';
import AddPlans from './components/plans_components/AddPlan.vue';
import DeletePlans from './components/plans_components/DeletePlan.vue';
import EditPlan from './components/plans_components/EditPlan.vue';
import PlansToolbar from './components/plans_components/PlansToolbar.vue';
import { EventBus } from '@/EventBus';

export default {
  data() {
    return {
      // dialogs
      search: '',
      loading: false,
      editDialog: false,
      deleteDialog: false,
      openAddPlanDialog: false,
      selectedPlanId: null,
      items: [],
      displayItems: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Permissions',
          value: 'permissions',
        },
        {
          text: 'User Limit',
          value: 'user_limit',
        },

        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
    };
  },
  components: {
    PlansToolbar,
    AddPlans,
    DialogTopBar,
    EditPlan,
    DeletePlans,
  },
  mounted() {
    this.loadData();
    EventBus.$on('SearchInputPlan', this.handleSearchPlan);
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.items = await getPlans();
        this.displayItems = this.items;
      } catch (err) {
        console.error('Error loading users:', err);
        this.$toast.open({ type: 'error', message: err.message });
      } finally {
        this.loading = false;
      }
    },
    handleSearchPlan(search_value) {
      const data = [...this.items].filter((d) => {
        const jsonStr = JSON.stringify(d, (key, value) => {
          if (typeof value === 'string') {
            return value.toLowerCase();
          }
          return value;
        });
        return jsonStr.includes(search_value.toLowerCase());
      });

      this.displayItems = data;
    },
    edit(plan) {
      this.selectedPlanId = plan.id;
      this.editDialog = true;
    },
    del() {
      this.deleteDialog = true;
    },
    moment(d) {
      return moment(d).format('llll');
    },
    addPlans() {
      this.openAddPlanDialog = true;
    },
    cancelAddPlanDialog() {
      this.openAddPlanDialog = false;
    },
  },
};
</script>
