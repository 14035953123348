<template>
  <div>
    <div>Are you sure you want to delete this customer?</div>
    <div class="my-2 py-4 justify-center flex">
      <v-btn @click="$emit('closeDialog')" text class="w-1/2">Cancel</v-btn>
      <v-btn @click="confirm" class="w-1/2">Ok</v-btn>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { doc, setDoc } from 'firebase/firestore';

export default {
  props: ["id"],
  methods: {
    async confirm() {
      await setDoc(doc(db, 'customers', this.id), { is_deleted: true }, { merge: true });
      this.$emit("closeDialog");
    },
  },
};
</script>