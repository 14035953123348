<template>
  <div>
    <!-- {{ selectedOtherMenusInfo}} -->
    <div class="text-center text-lg font-bold">
      <!-- {{ selectedOtherMenusInfo.mode }} -->
      {{ selectedOtherMenusInfo.virtual_space.title }}
    </div>
    <div class="my-4">
      <router-view @closeDialog="$emit('closeDialog')" />
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    selectedOtherMenusInfo: {
      handler(e) {
        this.$router.push({
          name: e.mode,
          params: {
            data: e,
          },
        });
      },
      immediate: true,
    },
  },
  props: ["selectedOtherMenusInfo"],
};
</script>