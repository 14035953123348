import { db } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export async function getPlans(search) {
  const conditions = [where('is_deleted', '==', false)];
  // "Starts with" string check
  if (search) conditions.push(where('full_name', '>=', search));
  const q = await getDocs(query(collection(db, 'plans'), ...conditions));
  const data = [];
  q.forEach((x) => data.push({ id: x.id, ...x.data() }));
  return data;
}
