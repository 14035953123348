<template>
  <div>
    <UserForm :id="id" @submit="submit" @cancel="$emit('close')" />
  </div>
</template>

<script>
import { db, storage } from '@/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import UserForm from '../../components/users_components/UserForm.vue';

export default {
  props: ['id'],
  components: { UserForm },
  methods: {
    async submit(data) {
      try {
        let avatarUrl = data.oldAvatarUrl;

        if (data.avatarFile !== null) {
          const ext = data.avatarFile.name.split('.').pop();
          const fileName = `avatars/user-${data.full_name.replace(/ /g, '')}-${data.id}.${ext}`;
          const avatarRef = ref(storage, fileName);
          await uploadBytes(avatarRef, data.avatarFile);
          avatarUrl = await getDownloadURL(avatarRef);
        }

        await setDoc(
          doc(db, 'users', data.id),
          {
            avatar_url: avatarUrl || '',
            email: data.email || '',
            full_name: data.full_name || '',
            company: data.company || '',
            role: data.role || '',
            permissions: data.permissions || [],
            receive_email: data.receive_email || false,
            regions: data.regions || [],
            position: data.position || '',
          },
          { merge: true }
        );

        this.$toast.open('Updated user');
        this.$emit('close');
      } catch (err) {
        this.$toast.open({ type: 'error', message: `Error: ${err.message}` });
      }
    },
  },
};
</script>
