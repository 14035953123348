<template>
  <div>
    <v-data-table :headers="headers" :items="items" :loading="loading" :search="search">
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="open(item)"> mdi-eye </v-icon>
        <v-icon class="mx-3" @click="del(item)"> mdi-delete </v-icon>
        <v-icon @click="edit(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      search: '',
      headers: [
        {
          text: 'Backup for',
          value: 'backup_for',
        },
        {
          text: 'Full Name',
          value: 'full_name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: "center"
        },
      ],
      items: [
        {
          backup_for: 'John Doe',
          full_name: 'James Read',
          email: 'james@123.com',
        },
      ],
    };
  },
};
</script>
