<template>
  <div class="h-screen flex justify-center align-middle items-center">
    <div class="px-20 sm:w-[500px] w-full">
      <div class="px-10 w-full border-2 border-gray-200 rounded-md">
        <div class="flex justify-center my-4">
          <div class="text-center w-[150px]">
            <v-img src="../../../assets/icons/logo.png" class="w-full"></v-img>
          </div>
        </div>
        <div>
          <v-text-field class="w-full" dense type="text" error-count="100" placeholder="Name" label="Name" clearable
            append-icon="mdi-text" v-model="displayName" outlined></v-text-field>
        </div>
        <div>
          <v-text-field class="w-full" dense type="email" error-count="100" placeholder="Email" label="Email" clearable
            append-icon="mdi-email" v-model="email" outlined></v-text-field>
        </div>
        <div class="relative">
          <div>
            <v-text-field dense class="w-full" name="password" outlined label="Enter your password"
              hint="At least 8 characters" min="8" v-model="password" clearable @click:append="() => (hide = !hide)"
              :append-icon="hide ? 'mdi-eye' : 'mdi-eye-off'" :append-icon-cb="() => (value = !value)" error
              :type="hide ? 'password' : 'text'"></v-text-field>
          </div>
          <div>
            <v-text-field dense class="w-full" name="confirm_password" outlined label="Confirm your password"
              hint="At least 8 characters" min="8" v-model="confirm_password" clearable
              @click:append="() => (hide2 = !hide2)" :append-icon="hide2 ? 'mdi-eye' : 'mdi-eye-off'"
              :append-icon-cb="() => (value = !value)" error :type="hide2 ? 'password' : 'text'"></v-text-field>
          </div>
        </div>

        <div class="my-6">
          <div>
            <v-btn class="w-full" color="#F19D38" @click="register">Sign Up</v-btn>
          </div>
        </div>
      </div>
      <div>
        <div class="py-10 text-center">
          <v-btn small @click="login" text>
            <span class="font-bold">Already have an account?</span>
          </v-btn>

          <br />
          <div class="text-xs mt-2">
            Copyright © 2022 1 Click Design LLC reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ensureUserExists } from '../ensure-user-exists';

export default {
  data() {
    return {
      displayName: "",
      email: "",
      password: "",
      confirm_password: "",
      hide: true,
      hide2: true,
    };
  },
  mounted() { },
  methods: {
    login() {
      this.$router.push("/login");
    },
    async register() {
      if (this.password !== this.confirm_password) {
        return this.$toast({ type: 'error', message: 'Passwords do not match' });
      }
      try {
        const credential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        await ensureUserExists(credential.user.email, this.displayName);
        this.$router.replace({ name: 'Dashboard' });
      } catch (err) {
        this.$toast.open({ type: 'error', message: err.message });
      }
    },
  },
};
</script>
