<template>
  <div>
    <div class="text-center my-4">Are you sure to delete this plan?</div>
    <ActionButtons @cancel="$emit('closeDialog')" :title="'Delete'" />
  </div>
</template>
<script>
import ActionButtons from '@/components/ActionButtons.vue';
export default {
  components: { ActionButtons },
  props: ['mainTitle'],
};
</script>