<template>
  <div>
    <!-- header  -->
    <TitleHeader />
    <CompaniesToolbar @search="onSearch" @SearchInput="searchMethod($event)" />
    <div>
      <v-data-table
        :headers="headers"
        :items="displayItems"
        item-key="id"
        :loading="loading"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 50, 100],
        }"
      >
        <!-- user avatar  -->
        <template v-slot:[`item.avatar`]="{ item }">
          <div class="flex">
            <v-avatar size="50">
              <img v-if="item.avatar_url" :src="item.avatar_url" class="object-cover" />
            </v-avatar>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex">
            <button text small @click="onSelectRow('view', item)" class="mr-2">
              <v-icon small> mdi-eye </v-icon>
            </button>
            <button text small @click="onSelectRow('edit', item)" class="mr-2">
              <v-icon small> mdi-pencil </v-icon>
            </button>
            <button text small @click="onSelectRow('delete', item)">
              <v-icon small> mdi-delete </v-icon>
            </button>
          </div>
        </template>
      </v-data-table>
    </div>

    <!-- view  -->
    <!-- <v-dialog
        v-model="viewCustomerDialog"
        persistent
        :overlay="false"
        :width="mode == 'delete' ? '300px' : ''"
        transition="dialog-transition"
      >
        <div class="bg-white">
          <DialogTopBar @closeDialog="viewCustomerDialog = false" :mainTitle="mode" />
          <SelectedCustomer
            :selectedItem="selectedItem"
            :mode="mode"
            @closeDialog="onSelectedCustomerClose"
          />
        </div>
      </v-dialog> -->

    <!-- add -->
    <v-dialog v-model="addCompanyDialog" persistent :overlay="false" transition="dialog-transition">
      <div class="bg-white">
        <!-- <AddCustomer @closeDialog="onAddCustomerClose" class="px-10" /> -->
      </div>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import DialogTopBar from '../../components/DialogTopBar.vue';
import TitleHeader from '../../components/TitleHeader.vue';
import CompaniesToolbar from './components/CompaniesToolbar';

export default {
  data() {
    return {
      selectedItem: [],
      mode: '',
      viewCustomerDialog: false,
      addCompanyDialog: false,
      items: [],
      displayItems: [],
      search: '',
      loading: false,
      headers: [
        {
          text: 'Company Name',
          value: 'company_name',
        },
        {
          text: 'Company Address',
          value: 'company_address',
        },
        {
          text: 'Contact Person',
          value: 'contact_person',
        },
        {
          text: 'Contact Details',
          value: 'contact_details',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
    };
  },
  components: {
    DialogTopBar,
    TitleHeader,
    // SelectedCustomer,
    CompaniesToolbar,
    // AddCustomer,
  },
  mounted() {
    this.loadCustomers();
    // EventBus.$on('SearchInputCustomer', this.handleSearchCustomer);
  },
  methods: {
    async loadCustomers() {
      this.loading = true;
      try {
        // this.items = await getCustomers();
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const is1cdSuperAdmin = userDetails.role.toLowerCase() === '1cdsuperadmin';

        this.displayItems = is1cdSuperAdmin
          ? this.items
          : this.items.filter((user) => {
              return (
                user.company.toLowerCase() === userDetails.company.toLowerCase() ||
                user.company.toLowerCase() === 'test' ||
                user.company.toLowerCase() === '1clickdesign'
              );
            });
      } catch (err) {
        console.error('Error loading customers:', err);
      } finally {
        this.loading = false;
      }
    },
    handleSearchCustomer(search_value) {
      this.displayItems = [...this.items].filter((d) => {
        const jsonStr = JSON.stringify(d, (key, value) => {
          if (typeof value === 'string') {
            return value.toLowerCase();
          }
          return value;
        });
        return jsonStr.includes(search_value.toLowerCase());
      });
    },
    async onSearch(search) {
      try {
        // this.items = await this.getCustomers(search);
        this.displayItems = this.items;
      } catch (err) {
        this.displayItems = this.items.filter((i) =>
          i.full_name.toLowerCase().includes(search.toLowerCase())
        );
      }
    },
    // onSelectedCustomerClose() {
    //   if (this.mode === 'edit' || this.mode === 'delete') {
    //     this.loadCustomers();
    //   }
    //   this.viewCustomerDialog = false;
    // },
    onAddCustomerClose() {
      this.loadCustomers();
      this.addCompanyDialog = false;
    },
    moment(d) {
      return moment(d).format('llll');
    },
    addCustomer() {
      this.addCompanyDialog = true;
    },
    onSelectRow(mode, item) {
      this.viewCustomerDialog = true;
      this.selectedItem = item;
      this.mode = mode;
    },
    closeDialog() {
      this.viewCustomerDialog = false;
    },
    searchMethod(val) {
      // console.log(val);
      this.search = val
    }
  },
};
</script>
<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
