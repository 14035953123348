<template>
  <div>
    <div class="flex justify-end w-full items-center align-middle">
      <VSToolbar @addVt="addVt" />
    </div>
    <div>
      <v-data-table :headers="headers" :items="displayItems" :loading="loading" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small text @click="edit(item)">
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn small text @click="del(item)">
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="openAddVSDialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <AddVS class="p-4" @closeDialog="openAddVSDialog = false" />
      </div>
    </v-dialog>
    <!-- edit  -->
    <v-dialog
      v-model="editDialog"
      persistent
      :overlay="false"
      width="500px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar mainTitle="Edit Virtual Space Type" @closeDialog="editDialog = false" />
        <EditVS class="bg-white p-4" @cancel="editDialog = false" />
      </div>
    </v-dialog>
    <!-- delete -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      :overlay="false"
      width="300px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar mainTitle="Delete Virtual Space Type" @closeDialog="deleteDialog = false" />
        <DeleteVS
          class="bg-white"
          mainTitle="Delete Virtual Space Type"
          @closeDialog="deleteDialog = false"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import VSToolbar from './components/virtual_space_types_components/VSToolbar.vue';
import AddVS from './components/virtual_space_types_components/AddVS.vue';
import DialogTopBar from '../../components/DialogTopBar.vue';
import EditVS from './components/virtual_space_types_components/EditVS.vue';
import DeleteVS from './components/virtual_space_types_components/DeleteVS.vue';
import { EventBus } from '@/EventBus';

export default {
  data() {
    return {
      // dialogs
      loading: false,
      search: '',
      editDialog: false,
      deleteDialog: false,
      openAddVSDialog: false,
      displayItems: [],
      items: [
        {
          id: 1,
          name: 'Virtual Showroom',
          value: 'virtual_showroom',
        },
        { id: 2, name: 'Virtual Real Estate', value: 'virtual_real_estate' },
      ],
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Value',
          value: 'value',
        },
        {
          text: 'Action',
          value: 'actions',
        },
      ],
    };
  },
  components: {
    DialogTopBar,
    VSToolbar,
    AddVS,
    EditVS,
    DeleteVS,
  },
  mounted() {
    this.loadData();
    EventBus.$on('SearchInputVirtualSpaceType', this.handleSearchVirtualSpaceType);
  },
  methods: {
    async loadData() {
      //temporary since data is static
      this.displayItems = this.items;
    },
    handleSearchVirtualSpaceType(search_value) {
      console.log('%c  search_value:', 'color: #0e93e0;background: #aaefe5;', search_value);
      const data = [...this.items].filter((d) => {
        const jsonStr = JSON.stringify(d, (key, value) => {
          if (typeof value === 'string') {
            return value.toLowerCase();
          }
          return value;
        });
        return jsonStr.includes(search_value.toLowerCase());
      });

      this.displayItems = data;
    },
    edit() {
      this.editDialog = true;
    },
    del() {
      this.deleteDialog = true;
    },
    moment(d) {
      return moment(d).format('llll');
    },
    addVt() {
      this.openAddVSDialog = true;
    },
  },
};
</script>
