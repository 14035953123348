<template>
  <div class="bg-white sm:min-w-[230px] md:min-w-[240px]">
    <div
      v-for="(item, i) in allowedMenuItems"
      :key="i"
      class="pb-1 w-full font-bold text-[#7D898C]"
    >
      <button
        @click="routeTo(item)"
        :class="
          $route.path.includes(item.path)
            ? 'border-solid border-r-4 border-[#FF9800] text-[#FF9800]'
            : ''
        "
        class="bg-white p-3 w-full flex text-left items-center focus:border-solid focus:border-r-4 focus:border-[#FF9800] hover:border-solid hover:border-r-4 hover:border-[#FF9800] hover:text-[#FF9800]"
      >
        <img
          class="min-w-[24px]"
          :src="item.icon_selected"
          v-if="$route.path.includes(item.path)"
        />
        <img class="min-w-[24px]" v-else :src="item.icon_default" />
        <div class="pl-2 hidden d-sm-inline-block">{{ item.name }}</div>
      </button>
    </div>
  </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { doc, getDoc } from 'firebase/firestore';

export default {
  data() {
    return {
      is1cdSuperAdmin: false,
      permissions: [],
      LeftSideMenus: [
        {
          id: 1,
          name: 'Dashboard',
          path: 'dashboard',
          icon_default: require('../assets/icons/main/gray/dashboard_grey.png'),
          icon_selected: require('../assets/icons/main/orange/dashboard_orange.png'),
          permission: 'dashboard',
        },
        {
          id: 2,
          name: 'Virtual Spaces',
          path: 'virtual-spaces',
          icon_default: require('../assets/icons/main/gray/virtual_spaces_grey.png'),
          icon_selected: require('../assets/icons/main/orange/virtual_spaces_orange.png'),
        },
        {
          id: 3,
          name: 'Analytics',
          path: 'analytics',
          icon_default: require('../assets/icons/main/gray/analytics_grey.png'),
          icon_selected: require('../assets/icons/main/orange/analytics_orange.png'),
          permission: 'reports',
        },
        {
          id: 12,
          name: 'Companies',
          path: 'companies',
          icon_default: require('../assets/icons/main/gray/company_grey.png'),
          icon_selected: require('../assets/icons/main/orange/company_orange.png'),
          permission: 'reports',
        },

        {
          id: 4,
          name: 'Customers',
          path: 'customers',
          icon_default: require('../assets/icons/main/gray/customers_grey.png'),
          icon_selected: require('../assets/icons/main/orange/customers_orange.png'),
          permission: 'customer',
        },
        {
          id: 5,
          name: 'Customer Activity',
          path: 'customer-activity',
          icon_default: require('../assets/icons/main/gray/customer_activity_grey.png'),
          icon_selected: require('../assets/icons/main/orange/customer_activity_orange.png'),
          permission: 'customer',
        },
        {
          id: 6,
          name: 'Products',
          path: 'products',
          icon_default: require('../assets/icons/main/gray/products_grey.png'),
          icon_selected: require('../assets/icons/main/orange/products_orange.png'),
          permission: 'product',
        },
        {
          id: 7,
          name: 'Users',
          path: 'users-view',
          icon_default: require('../assets/icons/main/gray/users_grey.png'),
          icon_selected: require('../assets/icons/main/orange/users_orange.png'),
          permission: 'settings',
        },
        // {
        //   id: 8,
        //   name: 'Messages',
        //   path: 'messages',
        //   icon_default: require('../assets/icons/main/gray/dashboard_grey.png'),
        //   icon_selected: require('../assets/icons/main/orange/dashboard_orange.png'),
        //   permission: 'messages',
        // },
        // {
        //   id: 9,
        //   name: 'Notifications',
        //   path: 'notifications',
        //   icon_default: require('../assets/icons/main/gray/dashboard_grey.png'),
        //   icon_selected: require('../assets/icons/main/orange/dashboard_orange.png'),
        // },
        // {
        //   id: 12,
        //   name: 'TextToSpeech',
        //   path: '/home/text-speech',
        //   icon_default: require('../assets/icons/main/gray/dashboard_grey.png'),
        //   icon_selected: require('../assets/icons/main/orange/dashboard_orange.png'),
        // },
        {
          id: 13,
          name: 'Video Conference',
          path: '/home/video-conference',
          icon_default: require('../assets/icons/main/gray/dashboard_grey.png'),
          icon_selected: require('../assets/icons/main/orange/dashboard_orange.png'),
        },

        // {
        //   id: 10,
        //   name: 'Text-Speech',
        //   path: 'text-speech',
        //   icon_default: require('../assets/icons/main/gray/dashboard_grey.png'),
        //   icon_selected: require('../assets/icons/main/orange/dashboard_orange.png'),
        // },
        // {
        //   id: 11,
        //   name: 'Invite',
        //   path: 'invite',
        //   icon_default: require('../assets/icons/main/gray/dashboard_grey.png'),
        //   icon_selected: require('../assets/icons/main/orange/dashboard_orange.png'),
        // },
      ],
    };
  },
  computed: {
    allowedMenuItems() {
      if (this.is1cdSuperAdmin) return this.LeftSideMenus;

      if (this.isAdmin) return this.LeftSideMenus;

      return this.LeftSideMenus.filter((m) =>
        !m.permission ? true : this.permissions.includes(m.permission)
      );
    },
  },
  mounted() {
    this.loadPermissions();
  },
  methods: {
    async loadPermissions() {
      const user = await this.getUser();
      this.is1cdSuperAdmin = user.role === '1cdsuperadmin';
      this.isAdmin = user.role.includes('admin');
      this.permissions = user?.permissions ?? [];
      console.log(user?.permissions);
    },
    async getUser() {
      if (!auth.currentUser) return null;
      const docSnap = await getDoc(doc(db, 'users', auth.currentUser.email));
      if (!docSnap.exists()) return null;
      // console.log('docSnap.data()', docSnap.data());
      return docSnap.data();
    },
    routeTo(item) {
      if (!this.$route.path.includes(item.path)) {
        this.$router.push({ name: item.name });
      }
    },
  },
};
</script>
