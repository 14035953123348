<template>
  <div>
    <!-- header  -->
    <TitleHeader />
    <!-- 4 col grids  -->
    <!-- main content in 2 col grid  -->
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        @click:row="selectedRow"
      >
        <template v-slot:[`item.time`]="{ item }">
          {{ formatDate(item.time) }}
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialog" transition="dialog-transition">
      <SelectedView @closeDialog="dialog = false" :selectedInfo="selectedInfo" />
    </v-dialog>
  </div>
</template>

<script>
import { db } from '@/firebase';
import { collectionGroup, getDocs, query, where } from 'firebase/firestore';
import moment from 'moment-timezone';
import TitleHeader from '../../components/TitleHeader.vue';
import SelectedView from './views/SelectedView.vue';

export default {
  data() {
    return {
      search: null,
      selectedInfo: [],
      loading: false,
      dialog: false,
      items: [],
      headers: [
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Start Date',
          value: 'start_date',
        },
        {
          text: 'Due Date',
          value: 'due_date',
        },
        {
          text: 'Assignees',
          value: 'assignees',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Priority',
          value: 'priority',
        },

        {
          text: 'Who',
          value: 'name',
        },
        {
          text: 'Activity',
          value: 'activity',
        },
        {
          text: 'Detail',
          value: 'detail',
        },
        {
          text: 'Time',
          value: 'time',
        },
      ],
    };
  },
  components: { TitleHeader, SelectedView },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.items = await this.getCustomerActivities();
      } catch (err) {
        console.error('Error loading data:', err);
      } finally {
        this.loading = false;
      }
    },
    async getCustomerActivities() {
      const q = await getDocs(
        query(collectionGroup(db, 'activities'), where('type', '==', 'customer'))
      );
      const data = [];
      q.forEach((x) => data.push({ ...x.data() }));
      return data;
    },
    moment(d) {
      return moment(d).format('llll');
    },
    selectedRow(item) {
      this.dialog = true;
      this.selectedInfo = item;
      console.log(item);
    },
    formatDate(date) {
      return moment
        .utc(new Date(date.seconds * 1000))
        .tz(moment.tz.guess())
        .format('LLL');
    },
  },
};
</script>