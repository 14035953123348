import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './style.css';
Vue.config.productionTip = false;

import axios from 'axios';
import VueAxios from 'vue-axios';
import vuetify from './plugins/vuetify';
Vue.use(VueAxios, axios);

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

// qrcode

// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faHatWizard } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// library.add(library, faHatWizard, FontAwesomeIcon);

// vuefire (firebase)
import { firestorePlugin } from 'vuefire';
Vue.use(firestorePlugin);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
