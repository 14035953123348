<template>
  <div class="text-center">
    <div class="text-center">
      <v-menu top :offset-x="offset">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"> fs fa-ellipsis-v </v-icon>
        </template>

        <v-list>
          <v-list-item v-for="(menu, index) in menus" :key="index">
            <button
              @click="
                onClickMenu({
                  mode: menu.title,
                  virtual_space: virtualSpace,
                })
              "
              class="flex items-center justify-center"
            >
              <v-icon class="mr-2">
                {{ menu.icon }}
              </v-icon>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </button>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menus: [
        {
          title: "Embed",
          icon: "fs fa-file-code",
        },
        {
          title: "Delete",
          icon: "fs fa-trash",
        },
      ],
      offset: true,
    };
  },
  props: ["virtualSpace"],
  methods: {
    onClickMenu(object) {
      this.$emit("onClickDropDownMenus", object);
    },
  },
};
</script>
<style scoped>
.v-icon {
  font-size: 14px;
}
</style>