const generateStaticTemplate = (
  virtualSpace,
  invitationType,
  selectedCustomer,
  messageContent,
  expiration,
  user,
  backup
) => {
  const senderName = user.full_name.toUpperCase();
  const backupName = backup ? backup.toUpperCase() : '';

  return `
    <body
      style="
        padding: 0 !important;
        margin: 0 !important;
        display: block !important;
        min-width: 100% !important;
        width: 100% !important;
        background: #f4f4f4;
        -webkit-text-size-adjust: none;
      "
    >
      <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4">
        <tr>
          <td align="center" valign="top">
            <!-- Main -->
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td align="center" style="padding: 40px 0">
                  <!-- Shell -->
                  <table width="650" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td
                        style="
                          width: 650px;
                          min-width: 650px;
                          font-size: 0pt;
                          line-height: 0pt;
                          padding: 0;
                          margin: 0;
                          font-weight: normal;
                          border: 1px solid black;
                        "
                      >
                        <!-- Title + Logo + Description -->
                        <div>
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            bgcolor="#ffffff"
                          >
                            <tr>
                              <td style="padding: 40px">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td
                                      style="
                                        padding-bottom: 30px;
                                        color: #444444;
                                        font-family: 'Yantramanav', Arial, sans-serif;
                                        font-size: 28px;
                                        line-height: 37px;
                                        font-weight: 300;
                                        text-align: center;
                                      "
                                    >
                                      ${virtualSpace.name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style="
                                        padding-bottom: 20px;
                                        font-size: 0pt;
                                        line-height: 0pt;
                                        text-align: center;
                                        border-bottom: 1px solid #dddddd;
                                      "
                                    >
                                      <a href="#" target="_blank"
                                        ><img
                                          src="${virtualSpace.logo_url}"
                                          width="350"
                                          height="175"
                                          border="0"
                                          alt=""
                                      /></a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style="
                                        padding: 20px 0;
                                        color: #666666;
                                        font-family: Arial, sans-serif;
                                        font-size: 16px;
                                        line-height: 30px;
                                        min-width: auto !important;
                                        text-align: center;
                                      "
                                    >
                                      ${virtualSpace.description}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <!-- END Title + Logo + Description -->
  
                        <!-- Thumbnail + Senders -->
                        <div>
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            bgcolor="#ffffff"
                          >
                            <tr>
                              <td style="padding: 0">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td style="font-size: 0pt; line-height: 0pt; text-align: center">
                                      <a href="#" target="_blank"
                                        ><img
                                          src="${virtualSpace.thumbnail_url}"
                                          width="100%"
                                          height="280"
                                          border="0"
                                          alt=""
                                      /></a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 30px 40px; background-color: #13282d">
                                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                          <td>
                                            <table
                                              width="100%"
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="0"
                                            >
                                              <tr>
                                                <td style="text-align: right">
                                                  <img
                                                    src="cid:user.png"
                                                    width="70"
                                                    height="70"
                                                    border="0"
                                                    alt=""
                                                  />
                                                </td>
                                                <td>
                                                  <table
                                                    width="100%"
                                                    border="0"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                  >
                                                    <tr>
                                                      <td
                                                        style="
                                                          color: white;
                                                          font-family: Arial, sans-serif;
                                                          font-size: 16px;
                                                          line-height: 30px;
                                                          min-width: auto !important;
                                                          text-align: center;
                                                        "
                                                      >
                                                        ${senderName}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        style="
                                                          color: orange;
                                                          font-family: Arial, sans-serif;
                                                          font-size: 16px;
                                                          line-height: 30px;
                                                          min-width: auto !important;
                                                          text-align: center;
                                                        "
                                                      >
                                                        Showroom Manager
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                          <td style="${backupName ? '' : 'display: none'}">
                                            <table
                                              width="100%"
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="0"
                                            >
                                              <tr>
                                                <td style="text-align: right">
                                                  <img
                                                    src="cid:user.png"
                                                    width="70"
                                                    height="70"
                                                    border="0"
                                                    alt=""
                                                  />
                                                </td>
                                                <td>
                                                  <table
                                                    width="100%"
                                                    border="0"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                  >
                                                    <tr>
                                                      <td
                                                        style="
                                                          color: white;
                                                          font-family: Arial, sans-serif;
                                                          font-size: 16px;
                                                          line-height: 30px;
                                                          min-width: auto !important;
                                                          text-align: center;
                                                        "
                                                      >
                                                        ${backupName}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        style="
                                                          color: orange;
                                                          font-family: Arial, sans-serif;
                                                          font-size: 16px;
                                                          line-height: 30px;
                                                          min-width: auto !important;
                                                          text-align: center;
                                                        "
                                                      >
                                                        Sales Representative
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <!-- END Thumbnail + Senders -->
  
                        <!-- Content -->
                        <div>
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            bgcolor="#ffffff"
                          >
                            <tr>
                              <td style="padding: 40px; border-bottom: 1px solid #979797">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td
                                      style="
                                        padding-bottom: 10px;
                                        color: orange;
                                        font-family: 'Yantramanav', Arial, sans-serif;
                                        font-size: 28px;
                                        line-height: 37px;
                                        font-weight: 300;
                                        text-align: center;
                                      "
                                    >
                                      A ${invitationType} invitation
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style="
                                        padding-bottom: 5px;
                                        color: #666666;
                                        font-family: Arial, sans-serif;
                                        font-size: 22px;
                                        line-height: 30px;
                                        min-width: auto !important;
                                        font-weight: 300;
                                        text-align: center;
                                      "
                                    >
                                      Hello ${selectedCustomer.full_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style="
                                        padding-bottom: 26px;
                                        color: #666666;
                                        font-family: Arial, sans-serif;
                                        font-size: 16px;
                                        line-height: 30px;
                                        min-width: auto !important;
                                        text-align: center;
                                      "
                                    >
                                      ${messageContent}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <!-- END Content -->
  
                        <!-- QR -->
                        <div>
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            bgcolor="#ffffff"
                          >
                            <tr>
                              <td style="padding: 40px; padding-bottom: 10px">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td>
                                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                          <th
                                            style="
                                              padding-bottom: 20px !important;
                                              font-size: 0pt;
                                              line-height: 0pt;
                                              padding: 0;
                                              margin: 0;
                                              font-weight: normal;
                                            "
                                            width="80"
                                          ></th>
                                          <td
                                            style="
                                              padding-bottom: 25px;
                                              color: #666666;
                                              font-family: Arial, sans-serif;
                                              font-size: 14px;
                                              line-height: 30px;
                                              text-align: center;
                                              min-width: auto !important;
                                            "
                                          >
                                            To enter our showroom, you may scan the OR code or click
                                            the button below.
                                          </td>
                                          <th
                                            style="
                                              padding-bottom: 20px !important;
                                              font-size: 0pt;
                                              line-height: 0pt;
                                              padding: 0;
                                              margin: 0;
                                              font-weight: normal;
                                            "
                                            width="80"
                                          ></th>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <th
                                          style="
                                            padding-bottom: 20px !important;
                                            font-size: 0pt;
                                            line-height: 0pt;
                                            padding: 0;
                                            margin: 0;
                                            font-weight: normal;
                                          "
                                          width="150"
                                        ></th>
                                        <th
                                          style="
                                            padding-bottom: 10px;
                                            font-size: 0pt;
                                            line-height: 0pt;
                                            text-align: left;
                                            border-radius: 1.25rem;
                                            background-color: #ff9800;
                                            padding: 30px 0;
                                          "
                                        >
                                          <table
                                            width="100%"
                                            border="0"
                                            cellspacing="0"
                                            cellpadding="0"
                                          >
                                            <tr>
                                              <td
                                                style="
                                                  padding: 30px 0;
                                                  color: white;
                                                  font-family: Arial, sans-serif;
                                                  font-size: 30px;
                                                  text-align: center;
                                                  min-width: auto !important;
                                                "
                                              >
                                                SCAN ME
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: center; padding-bottom: 10px">
                                                <img src="cid:qrcode" width="180" height="180" />
                                              </td>
                                            </tr>
                                          </table>
                                        </th>
                                        <th
                                          style="
                                            padding-bottom: 20px !important;
                                            font-size: 0pt;
                                            line-height: 0pt;
                                            padding: 0;
                                            margin: 0;
                                            font-weight: normal;
                                          "
                                          width="150"
                                        ></th>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <th
                                          style="
                                            padding-bottom: 20px !important;
                                            font-size: 0pt;
                                            line-height: 0pt;
                                            padding: 0;
                                            margin: 0;
                                            font-weight: normal;
                                          "
                                          width="150"
                                        ></th>
                                        <td
                                          style="
                                            padding: 25px 0;
                                            font-family: Arial, sans-serif;
                                            font-size: 16px;
                                            line-height: 30px;
                                            text-align: center;
                                            min-width: auto !important;
                                          "
                                        >
                                          <a
                                            href="${virtualSpace.url}"
                                            target="_blank"
                                            style="
                                              background-color: black;
                                              color: white;
                                              padding: 10px 0px;
                                              text-decoration: none;
                                              display: inline-block;
                                              border-radius: 10px;
                                              border: none;
                                              text-align: center;
                                              width: 100%;
                                            "
                                          >
                                            Enter Showroom
                                          </a>
                                        </td>
                                        <th
                                          style="
                                            padding-bottom: 20px !important;
                                            font-size: 0pt;
                                            line-height: 0pt;
                                            padding: 0;
                                            margin: 0;
                                            font-weight: normal;
                                          "
                                          width="150"
                                        ></th>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style="
                                        color: #ff9800;
                                        font-family: Arial, sans-serif;
                                        font-size: 16px;
                                        line-height: 30px;
                                        text-align: center;
                                        min-width: auto !important;
                                      "
                                    >
                                      Expiration
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style="
                                        font-family: Arial, sans-serif;
                                        font-size: 14px;
                                        line-height: 30px;
                                        text-align: center;
                                        min-width: auto !important;
                                      "
                                    >
                                      ${expiration}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <!-- END QR -->
  
                        <!-- Footer -->
                        <table
                          width="100%"
                          border="0"
                          cellspacing="0"
                          cellpadding="0"
                          bgcolor="#ffffff"
                        >
                          <tr>
                            <td style="padding: 30px 40px; border-top: 3px solid #f4f4f4">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <th
                                    style="
                                      font-size: 0pt;
                                      line-height: 0pt;
                                      padding: 0;
                                      margin: 0;
                                      font-weight: normal;
                                    "
                                  >
                                    <tr>
                                      <td
                                        style="
                                          color: #999999;
                                          font-family: Arial, sans-serif;
                                          font-size: 12px;
                                          line-height: 16px;
                                          text-align: center;
                                          min-width: auto !important;
                                        "
                                      >
                                        Copyright 2023, 1 Click Design. All rights reserved. Not for
                                        distribution.
                                      </td>
                                    </tr>
                                  </th>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!-- END Footer -->
                      </td>
                    </tr>
                  </table>
                  <!-- END Shell -->
                </td>
              </tr>
            </table>
            <!-- END Main -->
          </td>
        </tr>
      </table>
    </body>
  `;
};

export default generateStaticTemplate;
