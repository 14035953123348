<template>
  <div>
    <div>
      <div class="text-center my-2">
        <v-icon v-if="!avatarPreviewUrl" large class="w-full"> mdi-account-circle </v-icon>
        <div v-else class="flex flex-row justify-center mb-4">
          <v-avatar size="50">
            <img :src="avatarPreviewUrl" class="object-cover" />
          </v-avatar>
        </div>
        <v-btn small elevation="" color="" @click="onUploadClick"> Click to upload image </v-btn>
        <input class="hidden" type="file" ref="avatarFileUpload" @change="onAvatarUpload" />
      </div>
    </div>
    <div class="sm:grid grid-cols-4 gap-10">
      <div>
        <div class="text-xl text-[#F19D38] pb-2">Personal Details</div>
        <div class="grid gap-2 grid-cols-2">
          <div v-for="(item, i) in personal_details" :key="i">
          <div>
            <v-text-field
              dense
              :type="item.type"
              :label="item.label"
              v-model="item.value"
            ></v-text-field>
            <span class="text-xs italic text-red-500">{{ item.err }}</span>
          </div>
        </div>
        </div>
      </div>  
      <div>
        <div class="pb-2 text-xl text-[#F19D38]">Address</div>
        <div v-for="(item, j) in address" :key="j">
          <v-text-field
            dense
            :type="item.type"
            :label="item.label"
            v-model="item.value"
          ></v-text-field>
          <span class="text-xs italic text-red-500">{{ item.err }}</span>
        </div>
      </div>
      <div>
        <div class="text-xl text-[#F19D38]">Settings</div>
        <div v-for="(item, i) in settings" :key="i">
          <v-checkbox
            dense
            :label="item.label"
            v-model="item.value"
            :value="item.value"
          ></v-checkbox>
        </div>
      </div>
      <div>
        <div class="text-xl text-[#F19D38]">Description</div>
        <v-textarea v-model="description" outlined></v-textarea>
      </div>
    </div>
    <div>
      <ActionButtons :title="'Submit'" @cancel="$emit('closeDialog')" @submit="submit" />
    </div>
  </div>
</template>
<script>
import ActionButtons from '@/components/ActionButtons.vue';
import { db, storage } from '@/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { ADDRESS, PERSONAL_DETAILS } from '../customer-fields';
import { getCustomer } from '../get-customer';

export default {
  props: ['id'],
  data() {
    return {
      description: '',
      avatarFile: null,
      oldAvatarUrl: '',
      personal_details: PERSONAL_DETAILS,
      address: ADDRESS,
      settings: [
        {
          label: 'Show Hotspot',
          value: 'show_hotspot',
          type: 'checkbox',
        },
        {
          label: 'Show Price',
          value: 'show_price',
          type: 'checkbox',
        },
      ],
    };
  },
  computed: {
    avatarPreviewUrl() {
      if (this.avatarFile !== null) return URL.createObjectURL(this.avatarFile);
      if (this.oldAvatarUrl) return this.oldAvatarUrl;
      return null;
    },
  },
  watch: {
    id: {
      async handler() {
        const customer = await getCustomer(this.id);
        // Populate personal details
        let find = (name) => this.personal_details.find((p) => p.name === name) ?? { value: '' };
        find('full_name').value = customer.full_name ?? '';
        find('email').value = customer.email ?? '';
        find('phone').value = customer.phone ?? '';
        find('office_phone').value = customer.office_phone ?? '';
        find('resale_number').value = customer.resale_number ?? '';
        find('position').value = customer.position ?? '';
        find('company').value = customer.company ?? '';
        find('address').value = customer.address ?? '';
        find('web_url').value = customer.web_url ?? '';
        this.description = customer.description ?? '';
        // Populate address
        find = (name) => this.address.find((p) => p.name === name) ?? { value: '' };
        find('street').value = customer.street ?? '';
        find('city').value = customer.city ?? '';
        find('state').value = customer.state ?? '';
        find('zip').value = customer.zip ?? '';
        this.oldAvatarUrl = customer.avatar_url ?? '';
      },
      immediate: true,
    },
  },
  methods: {
    onUploadClick() {
      this.$refs.avatarFileUpload.click();
    },
    onAvatarUpload(e) {
      this.avatarFile = e.target.files[0];
    },
    async submit() {
      try {
        let canSubmit = true;
        for (const item of this.personal_details) {
          item.err = '';
          if (!item.value) {
            item.err = `Please fill in the ${item.label}`;
            canSubmit = false;
          }
        }
        for (const item of this.address) {
          item.err = '';
          if (!item.value) {
            item.err = `Please fill in the ${item.label}`;
            canSubmit = false;
          }
        }
        if (canSubmit) {
          let findPersonalDetail = (name) =>
            this.personal_details.find((p) => p.name === name)?.value ?? '';
          let findAddress = (name) => this.address.find((p) => p.name === name)?.value ?? '';
          let avatarUrl = this.oldAvatarUrl;

          if (this.avatarFile !== null) {
            const ext = this.avatarFile.name.split('.').pop();
            const fileName = `avatars/customer-${findPersonalDetail('full_name').replace(
              / /g,
              ''
            )}-${this.id}.${ext}`;
            const avatarRef = ref(storage, fileName);
            await uploadBytes(avatarRef, this.avatarFile);
            avatarUrl = await getDownloadURL(avatarRef);
          }

          await setDoc(
            doc(db, 'customers', this.id),
            {
              full_name: findPersonalDetail('full_name'),
              email: findPersonalDetail('email'),
              phone: findPersonalDetail('phone'),
              office_phone: findPersonalDetail('office_phone'),
              resale_number: findPersonalDetail('resale_number'),
              position: findPersonalDetail('position'),
              company: findPersonalDetail('company'),
              address: findPersonalDetail('address'),
              web_url: findPersonalDetail('web_url'),
              description: this.description,
              street: findAddress('street'),
              city: findAddress('city'),
              state: findAddress('state'),
              zip: findAddress('zip'),
              avatar_url: avatarUrl,
            },
            { merge: true }
          );

          this.$toast.open('Updated customer');
          this.$emit('closeDialog');
        }
      } catch (err) {
        this.$toast.open({ type: 'error', message: `Error: ${err.message}` });
      }
    },
  },
  components: {
    ActionButtons,
  },
};
</script>