
<template>
  <div>
    <PlansForm :id="id" @submit="submit" />
  </div>
</template>
<script>
import { db } from '@/firebase';
import { doc, setDoc } from 'firebase/firestore';
import PlansForm from "./PlansForm.vue";

export default {
  props: ['id'],
  components: { PlansForm },
  methods: {
    async submit(plan) {
      await setDoc(doc(db, 'plans', plan.id), {
        name: plan.name,
        price: plan.price,
        user_limit: plan.user_limit,
        permissions: plan.permissions,
        status: plan.status,
        is_deleted: false,
      });
      this.$toast.open('Updated plan');
      this.$emit('cancel');
    },
  },
};
</script>