
<template>
  <div>
    <VSForm />
    <ActionButtons
      :title="'Enter'"
      @cancel="$emit('cancel')"
      @submit="submit"
    />
  </div>
</template>
<script>
import ActionButtons from "../../../../components/ActionButtons.vue";
import VSForm from "./VSForm.vue";
export default {
  data() {
    return {};
  },
  components: { ActionButtons, VSForm },
  methods: {
    submit() {
      console.log("");
    },
  },
};
</script>