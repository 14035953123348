<template>
  <div class="flex items-center">
    <template v-if="!loading">
      <img
        v-if="companyLogoUrl"
        :src="companyLogoUrl"
        :alt="companyTitle"
        class="w-[50px] h-full mr-4 object-contain"
      />
      <img
        v-else
        src="../assets/icons/logo.png"
        alt="1 Click Design"
        class="w-[50px] h-full mr-4 object-contain"
      />
      <div class="text-center" v-if="companyId == '1clickdesign'">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text v-bind="attrs" v-on="on">
              <span class="hidden md:flex">{{ companyTitle }}</span>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list v-if="otherCompanies.length">
            <v-list-item v-for="(c, index) in otherCompanies" :key="index">
              <v-list-item-title class="text-xl">
                <v-btn @click="switchCompany(c)" text class="w-full">
                  {{ c.company_name }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list v-else class="text-center"> None </v-list>
        </v-menu>
      </div>
    </template>
    <template v-else>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </template>
  </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';

export default {
  data: () => ({
    userId: null,
    is1cdSuperAdmin: false,
    companyId: '1clickdesign',
    companyTitle: '1 Click Design',
    companyLogoUrl: null,
    companyChoices: [],
    loading: false,
  }),
  computed: {
    otherCompanies() {
      const userDetails = JSON.parse(localStorage.getItem('user'));
      const isSuperAdmin = userDetails.role.toLowerCase() === '1cdsuperadmin';
      if (isSuperAdmin) return this.companyChoices.filter((i) => i.id !== this.companyId);
      return [];
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        const user = await this.getUser();
        this.userId = user.email;
        this.is1cdSuperAdmin = user.role === '1cdsuperadmin';
        const company = await this.getCompany(user.company);

        this.companyChoices = await this.getCompanies();
        if (this.is1cdSuperAdmin) {
          this.companyChoices.push({
            id: '1clickdesign',
            company_name: '1 Click Design',
          });
          if (user.temporary_company) {
            const company = this.companyChoices.find((c) => c.id === user.temporary_company);
            this.companyId = company.id;
            this.companyTitle = company.company_name;
            this.companyLogoUrl = company.logo_url;
          }
        } else {
          this.companyId = user.company.toLowerCase();
          this.companyTitle = company.company_name;
          this.companyTitle = company.company_name;
          this.companyLogoUrl = company.logo_url;
        }

        // if (user.temporary_company) {
        //   const company = this.companyChoices.find((c) => c.id === user.temporary_company);
        //   if (!company) return;
        //   this.companyId = company.id;
        //   this.companyTitle = company.company_name;
        //   this.companyLogoUrl = company.logo_url;
        // }

        console.log(
          '%c  this.companyId.toLowerCase():',
          'color: #0e93e0;background: #aaefe5;',
          this.companyId.toLowerCase()
        );
        localStorage.setItem('current_company', this.companyId.toLowerCase());
        if (!company || this.is1cdSuperAdmin) return;
        // this.companyId = company.id;
        // this.companyTitle = company.company_name;
        // this.companyLogoUrl = company.logo_url;
      } catch (err) {
        console.error('Error loading choices:', err);
      } finally {
        this.loading = false;
      }
    },
    async switchCompany(company) {
      console.log('%c  company:', 'color: #0e93e0;background: #aaefe5;', company);
      try {
        await setDoc(
          doc(db, 'users', this.userId),
          {
            temporary_company: company.id,
          },
          { merge: true }
        );
        localStorage.setItem('current_company', company.company_name);

        // window.location.reload('/home');
        window.location.href = '/home';
      } catch (err) {
        console.error(err);
        this.$toast.open({ type: 'error', message: `Error: ${err.message}` });
      }
    },
    async getUser() {
      if (!auth.currentUser) return null;
      const docSnap = await getDoc(doc(db, 'users', auth.currentUser.email));
      if (!docSnap.exists()) return null;
      return docSnap.data();
    },
    async getCompany(id) {
      try {
        if (!id) throw 'No Company ID Provided';
        const docSnap = await getDoc(doc(db, 'companies', id));
        if (!docSnap.exists()) return null;
        return docSnap.data();
      } catch (error) {
        console.log('Error at Getting Company:', error);
      }
    },
    async getCompanies() {
      try {
        const q = await getDocs(collection(db, 'companies'));
        const data = [];
        q.forEach((x) => data.push({ id: x.id, ...x.data() }));
        return data;
      } catch (error) {
        console.log('Error at Getting Companies:', error);
      }
    },
  },
};
</script>
