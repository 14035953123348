<template>
  <div class="sm:pr-5 py-5">
    <div class="flex items-center flex-col md:flex-row md:justify-between">
      <h1 class="text-[24px] font-bold text-[#091E42] md:text-[28px]">Virtual Experiences</h1>
      <div class="flex items-center md:justify-end">
        <div class="w-[200px] md:w-[230px] lg:w-[300px]">
          <v-text-field
            full-width
            placeholder="Search..."
            append-icon="mdi-card-search"
            class="mr-2"
            v-model="filterShowroomsString"
          ></v-text-field>
        </div>
        <v-btn small dark rounded elevation="" color="#FF9800" @click="add_dialog = true"
          >Create</v-btn
        >
      </div>
    </div>

    <div class="my-8 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 max-h-[600px]">
      <div v-for="(virtualSpace, i) in filteredShowrooms.filter((v) => !!v.thumbnail_url)" :key="i">
        <v-card class="mx-auto flex flex-col" max-width="650" height="550">
          <v-img
            @click="open_new_url(virtualSpace.url)"
            class="white--text align-end cursor-pointer"
            height="250px"
            :src="virtualSpace.thumbnail_url"
          >
          </v-img>
          <v-card-title class="text-sm truncate md:text-base lg:text-lg">
            {{ virtualSpace.name }}
          </v-card-title>
          <v-card-subtitle class="text-xs truncate md:text-sm">
            Owner:
            {{
              virtualSpace.authorized_owner
                ? virtualSpace.authorized_owner
                : 'Owner of the showroom'
            }}
          </v-card-subtitle>

          <v-card-text class="text-xs md:text-sm">
            <div class="h-[120px] overflow-y-auto scrollbar-hide text-ellipsis">
              {{
                virtualSpace.description
                  ? virtualSpace.description
                  : 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore architecto nemo provident ad unde officia accusantium aliquid necessitatibus corrupti, amet vel? Distinctio placeat neque eius incidunt aperiam sint nihil eum!'
              }}
            </div>
          </v-card-text>
          <v-card-actions class="sm:flex justify-around text-sm p-[12px]">
            <!-- <pre>  {{ CardMenus }}</pre> -->
            <div v-for="(cardMenu, i) in CardMenus" :key="i">
              <button
                class="flex items-center text-[#13282D] font-bold"
                @click="
                  onSelectedMenu({
                    virtualSpace: virtualSpace,
                    cardMenu: cardMenu,
                  })
                "
              >
                <v-icon class="mr-1">{{ cardMenu.icon }}</v-icon>
                <v-text class="hidden text-xs lg:flex">{{ cardMenu.mode }}</v-text>
              </button>
            </div>

            <!-- dropdown  menu -->
            <DropDownMenus
              @onClickDropDownMenus="onClickDropDownMenus"
              :virtualSpace="virtualSpace"
            />
          </v-card-actions>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="tours_dialog" persistent :overlay="false" transition="dialog-transition">
      <ToursDialog :selectedVirtualSpace="selectedVirtualSpace" class="bg-white" />
    </v-dialog>

    <v-dialog v-model="edit_dialog" persistent :overlay="false" transition="dialog-transition">
      <DialogTopBar @closeDialog="edit_dialog = false" mainTitle="Update Virtual Space" />
      <EditDialog
        :id="selectedVirtualSpace?.virtualSpace?.id"
        class="bg-white"
        @closeDialog="edit_dialog = false"
      />
    </v-dialog>

    <v-dialog v-model="preview_dialog" persistent :overlay="false" transition="dialog-transition">
      <PreviewDialog :selectedVirtualSpace="selectedVirtualSpace" class="bg-white" />
    </v-dialog>

    <v-dialog
      v-model="share_dialog"
      persistent
      :overlay="false"
      transition="dialog-transition"
      :width="$vuetify.breakpoint.xs ? '100%' : '65%'"
    >
      <ShareDialog
        :virtualSpaceId="selectedVirtualSpaceId"
        class="bg-white"
        @closeDialog="share_dialog = false"
      />
    </v-dialog>

    <v-dialog v-model="add_dialog" persistent :overlay="false" transition="dialog-transition">
      <DialogTopBar @closeDialog="closeAndRefreshDialogs" mainTitle="Create Virtual Space" />
      <AddDialog @closeDialog="closeAndRefreshDialogs" class="bg-white" />
    </v-dialog>

    <v-dialog v-model="preview_dialog" persistent :overlay="false" transition="dialog-transition">
      <PreviewDialog :selectedVirtualSpace="selectedVirtualSpace" class="bg-white" />
    </v-dialog>

    <v-dialog
      v-model="openOtherMenus"
      persistent
      :overlay="false"
      transition="dialog-transition"
      :width="selectedMode == 'Delete' ? '300px' : 'null'"
    >
      <div class="bg-white p-2">
        <DialogTopBar @closeDialog="closeOtherMenus" :mainTitle="selectedOtherMenusInfo.mode" />
        <OtherMenusDialog
          class="px-2"
          :selectedOtherMenusInfo="selectedOtherMenusInfo"
          @closeDialog="closeOtherMenus"
        />
      </div>
    </v-dialog>

    <!-- <v-dialog v-model="dialogDelete" persistent :overlay="false" transition="dialog-transition">
    </v-dialog> -->
  </div>
</template>
<script>
import { db } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import DialogTopBar from '../../components/DialogTopBar.vue';
import AddDialog from './components/dialogs/AddDialog.vue';
import EditDialog from './components/dialogs/EditDialog.vue';
import OtherMenusDialog from './components/dialogs/OtherMenusDialog.vue';
import PreviewDialog from './components/dialogs/PreviewDialog.vue';
import ShareDialog from './components/dialogs/ShareDialog.vue';
import ToursDialog from './components/dialogs/ToursDialog.vue';
import DropDownMenus from './components/DropDownMenus.vue';

export default {
  data() {
    return {
      disabled: false,
      add_dialog: false,
      tours_dialog: false,
      edit_dialog: false,
      preview_dialog: false,
      share_dialog: false,
      selectedMode: null,
      selectedOtherMenusInfo: [],
      openSelectedDialog: false,
      openOtherMenus: false,
      dialogDelete: false,
      selectedVirtualSpace: null,
      selectedVirtualSpaceId: null,
      virtualSpaces: [],
      CardMenus: [
        {
          id: 1,
          mode: 'Showings',
          icon: 'fas fa-video',
        },
        {
          id: 2,
          mode: 'Edit',
          icon: 'fas fa-pencil',
        },
        {
          id: 3,
          mode: 'Preview',
          icon: 'fas fa-eye',
        },
        {
          id: 4,
          mode: 'Share',
          icon: 'fas fa-share-alt',
        },
      ],
      current_company_tab: null,
      filterShowroomsString: '',
    };
  },
  components: {
    DropDownMenus,
    DialogTopBar,
    OtherMenusDialog,
    ToursDialog,
    EditDialog,
    AddDialog,
    PreviewDialog,
    ShareDialog,
  },
  mounted() {
    this.loadVirtualSpaces();
    this.current_company_tab = localStorage.getItem('current_company');
  },
  computed: {
    filteredShowrooms: function () {
      return this.virtualSpaces.filter((showroom) => {
        return showroom.name.toLowerCase().match(this.filterShowroomsString.toLowerCase());
      });
    },
  },
  watch: {
    filteredShowrooms: {
      handler() {
        console.log(this.filteredShowrooms);
      },
    },
  },
  methods: {
    capitalizeWords(str) {
      var words = str.split(' ');

      for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join(' ');
    },
    async loadVirtualSpaces() {
      this.loading = true;
      try {
        const vSpaces = await this.getVirtualSpaces();
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const currentCompanyInstance = this.current_company_tab;

        const is1cdSuperAdmin = userDetails.role.toLowerCase() === '1cdsuperadmin';

        this.virtualSpaces =
          currentCompanyInstance === '1clickdesign' && is1cdSuperAdmin
            ? vSpaces
            : vSpaces.filter((space) => {
                return space?.authorized_companies.includes(currentCompanyInstance);
              });

        this.virtualSpaces = this.virtualSpaces.map((vs) => {
          console.log('vs', vs);
          const authorized_owner =
            vs.authorized_companies.filter((company) => company !== '1clickdesign')[0] ?? '';

          return {
            ...vs,
            authorized_owner: this.capitalizeWords(authorized_owner),
          };
        });
        // this.filteredShowrooms = this.virtualSpaces;
      } catch (err) {
        console.error('Error loading virtual spaces:', err);
        this.$toast.open({ type: 'error', message: err.message });
      } finally {
        this.loading = false;
      }
    },
    async getVirtualSpaces() {
      const conditions = [where('is_deleted', '==', false)];
      const q = await getDocs(query(collection(db, 'virtual_spaces'), ...conditions));
      const data = [];
      q.forEach((x) => data.push({ id: x.id, ...x.data() }));
      return data;
    },
    open_new_url(link) {
      window.open(link, '_blank');
    },
    closeDialog() {
      this.openSelectedDialog = false;
    },
    closeAndRefreshDialogs() {
      this.add_dialog = false;
      this.loadVirtualSpaces();
    },
    closeOtherMenus() {
      this.openOtherMenus = false;
    },
    onClickDropDownMenus(obj) {
      this.openOtherMenus = true;
      console.log('the object:', obj);
      this.selectedMode = obj.mode;
      this.selectedOtherMenusInfo = obj;
    },
    onSelectedMenu(object) {
      this.selectedVirtualSpace = object;
      this.selectedVirtualSpaceId = object.virtualSpace.id;
      if (object.cardMenu.mode == 'Tours') {
        this.tours_dialog = true;
      } else if (object.cardMenu.mode == 'Edit') {
        this.edit_dialog = true;
      } else if (object.cardMenu.mode == 'Preview') {
        this.open_new_url(object.virtualSpace.url);
      } else if (object.cardMenu.mode == 'Share') {
        this.share_dialog = true;
      }
      console.log('selectedVirtualSpace', this.selectedVirtualSpace);
    },
  },
};
</script>

<style scoped>
.v-icon {
  font-size: 14px;
}
</style>
