<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <button v-bind="attrs" v-on="on" class="py-2">
          <Avatar
            :username="displayName"
            :src="avatarURL"
            :customStyle="{
              border: '1.5px solid #d88307',
              padding: 'auto',
              ...(getSeparatedDisplayNameLength() > 2 && { fontSize: '1rem' }),
            }"
          />
        </button>
      </template>

      <div>
        <v-sheet>
          <v-container>
            <div class="flex flex-row justify-center">
              <Avatar
                :username="displayName"
                :src="avatarURL"
                :customStyle="{
                  border: '1px solid #d88307',
                  padding: 'auto',

                  ...(getSeparatedDisplayNameLength() > 2 && { fontSize: '1rem' }),
                }"
              />
            </div>
            <h3 class="text-center">{{ displayName }}</h3>
            <h3 class="text-center">{{ email }}</h3>
            <div class="text-center">
              <v-btn text class="w-full bg-[#b1aeae] mt-5 mb-3" rounded medium elevation="0" @click="openMyProfileDialog">
                My Profile
              </v-btn>
              <v-btn text class="w-full bg-[#FF9800]" rounded medium elevation="0" @click="signOut">Sign Out</v-btn>
            </div>
            <v-list> </v-list>
          </v-container>
        </v-sheet>
      </div>
    </v-menu>
    <v-dialog
      v-model="myProfileDialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <div class="bg-white p-4">
        <div class="flex justify-end">
          <v-btn icon text small>
            <v-icon @click="myProfileDialog = false">mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <my-profile />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { doc, getDoc } from 'firebase/firestore';
import Avatar from 'vue-avatar';
import MyProfile from '../components/MyProfile';

export default {
  data: () => ({
    email: '',
    myProfileDialog: false,
    displayName: '',
    avatarURL: '',
  }),
  components: {
    Avatar,
    MyProfile,
  },
  async mounted() {
    auth.onAuthStateChanged(() => this.loadUser());
    this.avatarURL = this.getCurrentUserAvatarURL();
  },
  methods: {
    openMyProfileDialog() {
      this.myProfileDialog = true;
    },
    getCurrentUserAvatarURL() {
      const userDetails = JSON.parse(localStorage.getItem('user'));

      return userDetails?.avatar_url;
    },
    getSeparatedDisplayNameLength() {
      return this.displayName.split(' ').length;
    },
    async loadUser() {
      const user = await this.getUser();
      if (!user) return;
      this.email = user.email;
      this.displayName = user.full_name;
    },
    async getUser() {
      if (!auth.currentUser) return null;
      const docSnap = await getDoc(doc(db, 'users', auth.currentUser.email));
      if (!docSnap.exists()) return null;
      return docSnap.data();
    },
    async signOut() {
      await auth.signOut();
      localStorage.removeItem('user');
      this.$router.replace({ name: 'Log In' });
    },
  },
};
</script>
