<template>
  <div>
    <VSForm @closeDialog="$emit('closeDialog')" />
  </div>
</template>
<script>
import VSForm from './VSForm.vue';
export default {
  data() {
    return {
      name: '',
      value: '',
    };
  },
  components: { VSForm },
  methods: {
    submit() {
      console.log('');
    },
  },
};
</script>