<template>
  <div>
    <div class="w-full">
      <iframe
        class="w-full"
        :src="$route.params.data.virtual_space.url"
        frameborder="0"
      ></iframe>
    </div>
    <div class="py-4">
      <div>
        <v-text-field
          readonly
          type="text"
          placeholder=""
          label="Url"
          append-icon=""
          v-model="url"
          outlined
          color
        ></v-text-field>
      </div>
      <div>
        <v-btn class="w-full">Click to copy</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: this.$route.params.data.virtual_space.url,
    };
  },
};
</script>