<template>
  <div class="h-screen flex justify-center align-middle items-center">
    <div class="px-20 sm:w-[500px] w-full">
      <div class="px-10 w-full border-2 border-gray-200 rounded-md">
        <div class="flex justify-center my-4">
          <div class="text-center w-[150px]">
            <v-img src="../../../assets/icons/logo.png" class="w-full"></v-img>
          </div>
        </div>
        <div class="text-center my-6">
          <div class="text-xl font-bold">Create Account</div>
          <div class="font-light text-sm">
            Please enter your new password
          </div>
        </div>
        <div>
          <v-text-field class="w-full" dense type="password" error-count="100" label="Password" clearable
            v-model="password" outlined></v-text-field>
          <v-text-field class="w-full" dense type="password" error-count="100" label="Confirm Password" clearable
            v-model="confirm_password" outlined></v-text-field>
        </div>
        <div class="my-6">
          <div>
            <v-btn class="w-full" color="#F19D38" @click="submit">Submit</v-btn>
          </div>
        </div>
      </div>
      <div>
        <div class="py-10 text-center">
          <v-btn small @click="needToSignIn" text>
            <span class="font-bold">Need to Sign In?</span>
          </v-btn>

          <br />
          <div class="text-xs mt-2">
            Copyright © 2022 1 Click Design LLC reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ensureUserExists } from '../ensure-user-exists';

export default {
  data() {
    return {
      password: "",
      confirm_password: "",
    };
  },
  mounted() { },
  methods: {
    async submit() {
      if (this.password !== this.confirm_password) {
        this.$toast.open({ type: 'error', message: 'Passwords do not match' });
        return;
      }
      try {
        if (!this.$route.query.email) return;
        const res = await createUserWithEmailAndPassword(auth, this.$route.query.email, this.password);
        console.log("res", res);
        await ensureUserExists(res.user.email, res.user.displayName);
        this.$toast.open('Success');
        this.$router.replace({ name: 'Dashboard' });
      } catch (err) {
        console.error(err);
        this.$toast.open({ type: 'error', message: `Error: ${err.message}` });
      }
    },
    needToSignIn() {
      this.$router.push('/login');
    },
  },
};
</script>
