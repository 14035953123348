<template>
  <div>
    <div class="flex justify-end w-full items-center align-middle">
      <CompaniesToolbar @addCompany="addCompany" @search="onSearch" />
    </div>

    <div class="">
      <v-data-table :headers="headers" :items="displayItems" :loading="loading" :search="search">
        <template v-slot:[`item.logo_url`]="{ item }">
          <v-avatar size="30">
            <img :src="item.logo_url" class="object-cover" />
          </v-avatar>
        </template>
        <template v-slot:[`item.plan`]="{ item }">
          <span>{{ item.plan || '-' }}</span>
        </template>
        <template v-slot:[`item.virtual_space_type`]="{ item }">
          <span>{{ parseVirtualSpaceType(item.virtual_space_type) }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status.charAt(0).toUpperCase() + item.status.slice(1) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small text @click="edit(item)">
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn small text @click="del(item)">
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog
      v-model="openAddCompanyDialog"
      persistent
      :overlay="false"
      transition="dialog-transition"
      class="relative"
    >
      <div class="bg-white">
        <AddCompany class="px-10 py-4" @cancel="openAddCompanyDialog = false" />
      </div>
    </v-dialog>

    <!-- edit  -->
    <v-dialog v-model="editDialog" persistent :overlay="false" transition="dialog-transition">
      <div class="bg-white">
        <DialogTopBar mainTitle="Edit Company Information" @closeDialog="editDialog = false" />
        <EditCompany :id="selectedCompanyId" class="bg-white p-4" @cancel="editDialog = false" />
      </div>
    </v-dialog>

    <!-- delete  -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      :overlay="false"
      width="250px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar mainTitle="Delete Company" @closeDialog="deleteDialog = false" />
        <DeleteCompany
          :id="selectedCompanyId"
          class="bg-white"
          @closeDialog="deleteDialog = false"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { db } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import moment from 'moment';
import DialogTopBar from '../../components/DialogTopBar.vue';
import AddCompany from './components/companies_components/AddCompany.vue';
import CompaniesToolbar from './components/companies_components/CompaniesToolbar.vue';
import DeleteCompany from './components/companies_components/DeleteCompany.vue';
import EditCompany from './components/companies_components/EditCompany.vue';
import { EventBus } from '@/EventBus';

export default {
  data() {
    return {
      // dialogs
      loading: false,
      search: '',
      editDialog: false,
      deleteDialog: false,
      openAddCompanyDialog: false,
      selectedCompanyId: null,
      items: [],
      displayItems: [],
      headers: [
        {
          text: 'Logo',
          value: 'logo_url',
        },
        {
          text: 'Company Name',
          value: 'company_name',
        },
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Plan',
          value: 'plan',
        },
        {
          text: 'Virtual Space Type',
          value: 'virtual_space_type',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
    };
  },
  components: {
    CompaniesToolbar,
    AddCompany,
    DialogTopBar,
    EditCompany,
    DeleteCompany,
  },
  mounted() {
    this.loadData();
    EventBus.$on('SearchInputCompany', this.handleSearchCompany);
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.items = await this.getCompanies();
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const currentCompanyInstance = localStorage.getItem('current_company');

        const is1cdSuperAdmin =
          userDetails.role.toLowerCase() === '1cdsuperadmin' &&
          currentCompanyInstance === '1clickdesign';

        this.displayItems = is1cdSuperAdmin
          ? this.items
          : this.items.filter((company) => {
              return company?.company_name.toLowerCase() === currentCompanyInstance.toLowerCase();
            });
      } catch (err) {
        console.error('Error loading customers:', err);
      } finally {
        this.loading = false;
      }
    },
    handleSearchCompany(search_value) {
      this.displayItems = [...this.items].filter((d) => {
        const jsonStr = JSON.stringify(d, (key, value) => {
          if (typeof value === 'string') {
            return value.toLowerCase();
          }
          return value;
        });
        return jsonStr.includes(search_value.toLowerCase());
      });
    },
    async getCompanies(search) {
      const conditions = [where('is_deleted', '==', false)];
      // "Starts with" string check
      if (search) conditions.push(where('company_name', '>=', search));
      const q = await getDocs(query(collection(db, 'companies'), ...conditions));
      const data = [];
      q.forEach((x) => data.push({ id: x.id, ...x.data() }));
      return data;
    },
    async onSearch(search) {
      try {
        this.items = await this.getCompanies(search);
        this.displayItems = this.items;
      } catch (err) {
        this.displayItems = this.items.filter((i) =>
          i.company_name.toLowerCase().includes(search.toLowerCase())
        );
      }
    },
    edit(company) {
      this.selectedCompanyId = company.id;
      this.editDialog = true;
    },
    del(company) {
      this.selectedCompanyId = company.id;
      this.deleteDialog = true;
    },
    moment(d) {
      return moment(d).format('llll');
    },
    addCompany() {
      this.openAddCompanyDialog = true;
    },
    parseVirtualSpaceType(t) {
      if (t === 'showroom') return 'Virtual Showrom';
      else if (t === 'realestate') return 'Virtual Real Estate';
      return '';
    },
  },
};
</script>
