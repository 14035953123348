export const PERSONAL_DETAILS = [
  {
    name: 'full_name',
    label: 'Full Name',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'email',
    label: 'Email',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'phone',
    label: 'Phone',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'office_phone',
    label: 'Office Phone',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'resale_number',
    label: 'Resale Number / Business Licenses',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'position',
    label: 'Position',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'company',
    label: 'Company Name',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'web_url',
    label: 'Website Url',
    value: '',
    type: 'text',
    err: false
  },
];

export const ADDRESS = [
  {
    name: 'street',
    label: 'Street',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'city',
    label: 'City',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'state',
    label: 'State',
    value: '',
    type: 'text',
    err: false
  },
  {
    name: 'zip',
    label: 'Zip Code',
    value: '',
    type: 'text',
    err: false
  },
];
