import { db } from '@/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export async function ensureUserExists(email, fullName) {
  const docSnap = await getDoc(doc(db, 'users', email));

  if (!docSnap.exists()) {
    const default_params = {
      full_name: fullName,
      email: email,
      avatar_url: '',
      company: '',
      role: 'user',
      permissions: [],
      receive_email: false,
      regions: [],
      position: '',
      is_deleted: false,
    };
    await setDoc(doc(db, 'users', email), default_params);

    return default_params;
  }

  return docSnap.data();
}
