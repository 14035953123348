export const PERMISSIONS_CHOICES = [
  'dashboard',
  'product',
  'cia',
  'reports',
  'settings',
  'messages',
  'showroom',
  'customer',
  'management',
  'survey_management',
];
