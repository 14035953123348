<template>
  <div>
    <div class="text-center my-4">Are you sure to delete this user?</div>
    <div class="py-4">
      <ActionButtons @cancel="$emit('closeDialog')" :title="'Delete'" />
    </div>
  </div>
</template>
<script>
import ActionButtons from "../../../../components/ActionButtons.vue";
export default {
  components: { ActionButtons },
  props: ["mainTitle"],
};
</script>