<template>
  <div class="p-2">
    <EditForm :id="id" @closeDialog="$emit('closeDialog')" />
  </div>
</template>

<script>
import EditForm from "./EditForm.vue";

export default {
  props: ["id"],
  components: { EditForm },
};
</script>