<template>
  <div>
    <!-- <div class="my-2 flex sm:justify-end"> -->
    <!-- <v-icon> mdi-magnify </v-icon> -->
    <!-- <div class="border-2 border-gray-400 rounded-md max-w-[300px]"> -->
    <!-- <input
          type="text"
          :placeholder="placeholder"
          class="w-full px-2"
          :value="value"
          @input="handleChange"
        /> -->

    <v-text-field
      @input="handleChange"
      clearable
      :label="placeholder"
      placeholder="Placeholder"
      prepend-icon="mdi-magnify"
    ></v-text-field>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
// import { EventBus } from '@/EventBus';

export default {
  data() {
    return {
      val: null,
    };
  },
  props: ['value', 'placeholder', 'search_id'],
  methods: {
    handleChange(e) {
      // this.$emit('input', e.target.value);
      // console.log('route', this.$route.path, this.search_id, this.placeholder);
      // EventBus.$emit(`SearchInput${this.search_id}`, e.target.value);
      this.$emit(`SearchInput`, e);
      // console.log(e);
    },
  },
};
</script>
