<template>
  <div>
    <!-- header  -->
    <TitleHeader />
    <!-- {{  greetAITextToSpeech() }} -->
    <div class="sm:grid grid-cols-2 gap-2 mt-14">
      <div v-for="(item, i) in dashboard_grid" :key="i" class="mb-4 md:mb-0">
        <div
          class="rounded-[12px] p-[20px] flex align-center justify-between"
          :class="item.bgColor"
        >
          <div>
            <div class="text-[36px] text-white font-bold">0</div>
            <div class="text-white text-[16px] font-semibold">
              {{ item.text }}
            </div>
          </div>
          <div>
            <img :src="item.icon" width="45px" height="45px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase';
import { collectionGroup, getDocs } from 'firebase/firestore';
import TitleHeader from '../../components/TitleHeader.vue';
import moment from 'moment-timezone';

export default {
  data() {
    return {
      textToSpeak: null,
      myName: '',
      dashboard_grid: [
        {
          text: 'Customer Visits',
          bgColor: 'bg-[#8E5B9A]',
          icon: require('../../assets/icons/main/dashboard/tasks_assigned_to_me.png'),
          textSize: '16px',
        },
        {
          text: 'Products Viewed',
          bgColor: 'bg-[#FF9800]',
          icon: require('../../assets/icons/main/dashboard/virtual_spaces.png'),
          textSize: '16px',
        },
        {
          text: 'My Interactions',
          bgColor: 'bg-[#12A94F]',
          icon: require('../../assets/icons/main/dashboard/analytics_reports.png'),
          textSize: '16px',
        },
        {
          text: 'Buy Signals',
          bgColor: 'bg-[#1BBFD3]',
          icon: require('../../assets/icons/main/dashboard/notifications.png'),
          textSize: '16px',
        },
      ],

      summary_menu: [
        {
          title: 'Tasks Assigned to Me',
          icon: '',
          color: '',
          total: '123',
          link: 'Customer Activity',
        },
        {
          title: 'Virtual Spaces',
          icon: '',
          color: '',
          total: '123',
          link: 'Virtual Spaces',
        },
        {
          title: 'Analytics & Reports',
          icon: '',
          color: '',
          total: '123',
          link: 'Analytics',
        },
        {
          title: 'My Notifications',
          icon: '',
          color: '',
          total: '123',
          link: 'Notifications',
        },
      ],
      headers: [
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Who',
          value: 'name',
        },
        {
          text: 'Activity',
          value: 'activity',
        },
        {
          text: 'Detail',
          value: 'detail',
        },
        {
          text: 'Time',
          value: 'time',
        },
      ],
      allUserActivities: [],
      loading: false,
    };
  },
  components: { TitleHeader },
  mounted() {
    this.loadData();
  },
  watch: {
    '$route.path': {
      handler(e) {
        if (e) {
          // this.activateTextSpeech();
        }
      },
      immediate: true,
    },
  },
  methods: {
    activateTextSpeech() {
      if ('speechSynthesis' in window) {
        this.synthesizer = window.speechSynthesis;
        this.greetAITextToSpeech();
      } else {
        console.error('Speech synthesis not supported');
      }
    },
    greetAITextToSpeech() {
      console.log('HELLO TEXT-SPEECH');
      this.myName = JSON.parse(localStorage.getItem('user')).full_name;
      this.textToSpeak = 'Welcome ' + this.myName + 'to your new 1 Click Design platform';
      if (this.synthesizer && this.textToSpeak) {
        const utterance = new SpeechSynthesisUtterance(this.textToSpeak);
        utterance.lang = 'en-AU';
        this.synthesizer.speak(utterance);
      }
    },
    async loadData() {
      this.loading = true;
      try {
        this.allUserActivities = await this.getAllUserActivities();
        console.log('all user activities:', this.allUserActivities);
      } catch (err) {
        console.error('Error loading data:', err);
      } finally {
        this.loading = false;
      }
    },
    async getAllUserActivities() {
      const q = await getDocs(collectionGroup(db, 'activities'));
      const data = [];
      q.forEach((x) => data.push({ ...x.data() }));
      return data;
    },
    routeTo(link) {
      this.$router.push({ name: link });
    },
    formatDate(date) {
      return moment
        .utc(new Date(date.seconds * 1000))
        .tz(moment.tz.guess())
        .format('LLL');
    },
  },
};
</script>
