<template>
  <div class="w-full flex px-4">
    <TopNavigatorDropDownCo />
    <div class="items-center justify-center hidden text-sm md:flex">{{ c_time }}</div>
    <div class="text-right justify-end flex items-center">
      <div class="mr-4 flex items-center justify-end">
        <div class="hidden md:block">
          <div class="uppercase font-semibold">{{ full_name }}</div>
          <div class="uppercase text-xs">{{ company }}</div>
        </div>
      </div>
      <TopNavigatorDropDownUser />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import TopNavigatorDropDownCo from './TopNavigatorDropDownCo.vue';
import TopNavigatorDropDownUser from './TopNavigatorDropDownUser.vue';
import moment from 'moment';

export default {
  data() {
    return {
      full_name: '',
      company: '',
      c_time: '',
    };
  },
  created() {
    setInterval(() => {
      this.c_time = moment().format('llll');
    }, 1000);
  },
  mounted() {
    this.setUserDetails();
  },
  components: { TopNavigatorDropDownCo, TopNavigatorDropDownUser },
  methods: {
    setUserDetails() {
      const userDetails = JSON.parse(localStorage.getItem('user'));
      this.full_name = userDetails.full_name;
      this.company = userDetails.role === '1cdsuperadmin' ? '1 Click Design' : userDetails.company;
    },
  },
};
</script>
