<template>
  <div class="sm:grid grid-cols-2 gap-4 p-4 m-4 border-solid border-2 border-gray-600 bg-gray-100">
    <div class="h-[500px] overflow-scroll">
      <img src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" />
      <img src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" />
      <img src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" />
      <img src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" />
    </div>
    <div>
      <div>
        <social-buttons />
      </div>
      <div v-for="(item, i) in info" :key="i">
        <div class="font-bold text-xl">{{ item.text }} :</div>
        <div class="bg-white p-2">
          <v-card>
            <v-card-text>
              {{ item.value }}
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="playShowroom"> Click to view the showroom</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>

      <div>
        <v-btn
          class="w-full my-2"
          color="primary"
          @click="requestForQuote('Request for Quotation')"
        >
          REQUEST FOR QUOTE
        </v-btn>
        <v-btn class="w-full my-2" color="primary" @click="contactSales('Contact Sales')">
          CONTACT SALES
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import SocialButtons from './components/SocialButtons.vue';
export default {
  data() {
    return {
      images: [
        {
          url: '',
        },
      ],
      info: [
        {
          text: 'PRICE',
          value: '$ 10,000.00',
        },
        {
          text: 'DESCRIPTION',
          value:
            ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore architecto nemo provident ad unde officia accusantium aliquid necessitatibus corrupti, amet vel? Distinctio placeat neque eius incidunt aperiam sint nihil eum!',
        },
      ],
    };
  },

  components: { SocialButtons },
  methods: {
    playShowroom() {
      window.open(
        'https://s3.amazonaws.com/www.onlyshowroom.com/prod/ryansaghian_v3/index.html',
        '_blank'
      );
    },
    // playAudio() {
    //   const audio = new Audio('../../assets/audio/sample_audio.mp3');
    //   audio.play();
    // },
    requestForQuote(e) {
      alert(e);
    },
    contactSales(e) {
      alert(e);
    },
  },
};
</script>