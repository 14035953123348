<template>
  <div class="sm:flex w-full">
    <!-- <div class="sm:w-1/2 sm:flex items-center" v-if="$route.name == 'Users UI'">
      <v-btn small class="mr-4">Display All</v-btn>
      <div class="w-1/3">
        <v-select label="Select Company" :items="items" v-model="value" autocomplete></v-select>
      </div>
    </div> -->
    <div class="flex mr-2">
      <div class="flex items-center justify-end flex-col md:flex-row">
        <SimpleSearch @input="updateInputValue" class="mr-8" :placeholder="'Search User...'" @SearchInput="updateInputValue($event)" />
        <div>
          <v-btn dark medium rounded elevation="0" color="#F29E37" @click="addUser">Invite Users </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleSearch from '@/components/SimpleSearch.vue';

export default {
  components: { SimpleSearch },
  data() {
    return {
      items: [],
      value: null,
    };
  },
  methods: {
    addUser() {
      this.$emit('addUser');
    },
    addCompany() {
      this.$emit('addCompany');
    },

    updateInputValue(val) {
      this.value = val
      // console.log(this.value);
      this.$emit(`SearchInput`, val);
    }
  },
};
</script>
