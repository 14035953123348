var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({staticClass:"py-2"},'button',attrs,false),on),[_c('Avatar',{attrs:{"username":_vm.displayName,"src":_vm.avatarURL,"customStyle":{
            border: '1.5px solid #d88307',
            padding: 'auto',
            ...(_vm.getSeparatedDisplayNameLength() > 2 && { fontSize: '1rem' }),
          }}})],1)]}}])},[_c('div',[_c('v-sheet',[_c('v-container',[_c('div',{staticClass:"flex flex-row justify-center"},[_c('Avatar',{attrs:{"username":_vm.displayName,"src":_vm.avatarURL,"customStyle":{
                border: '1px solid #d88307',
                padding: 'auto',

                ...(_vm.getSeparatedDisplayNameLength() > 2 && { fontSize: '1rem' }),
              }}})],1),_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.displayName))]),_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.email))]),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"w-full bg-[#b1aeae] mt-5 mb-3",attrs:{"text":"","rounded":"","medium":"","elevation":"0"},on:{"click":_vm.openMyProfileDialog}},[_vm._v(" My Profile ")]),_c('v-btn',{staticClass:"w-full bg-[#FF9800]",attrs:{"text":"","rounded":"","medium":"","elevation":"0"},on:{"click":_vm.signOut}},[_vm._v("Sign Out")])],1),_c('v-list')],1)],1)],1)]),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.myProfileDialog),callback:function ($$v) {_vm.myProfileDialog=$$v},expression:"myProfileDialog"}},[_c('div',{staticClass:"bg-white p-4"},[_c('div',{staticClass:"flex justify-end"},[_c('v-btn',{attrs:{"icon":"","text":"","small":""}},[_c('v-icon',{on:{"click":function($event){_vm.myProfileDialog = false}}},[_vm._v("mdi-close")])],1)],1),_c('div',[_c('my-profile')],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }