<template>
  <div>
    <PermissionsForm @closeDialog="$emit('closeDialog')" />
  </div>
</template>
<script>
import PermissionsForm from './PermissionsForm.vue';
export default {
  data() {
    return {};
  },
  components: { PermissionsForm },
  methods: {
    submit() {
      console.log('');
    },
  },
};
</script>