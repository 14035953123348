<template>
  <div class="h-screen flex justify-center align-middle items-center">
    <div class="px-20 sm:w-[500px] w-full">
      <div class="px-10 w-full border-2 border-gray-200 rounded-md">
        <div class="flex justify-center my-4">
          <div class="text-center w-[150px]">
            <v-img src="../../../assets/icons/logo.png" class="w-full"></v-img>
          </div>
        </div>
        <div>
          <v-text-field
            class="w-full"
            dense
            type="email"
            error-count="100"
            placeholder="Email"
            label="Email"
            clearable
            append-icon="mdi-email"
            v-model="email"
            outlined
          ></v-text-field>
        </div>
        <div class="relative">
          <div>
            <v-text-field
              dense
              class="w-full"
              name="password"
              outlined
              label="Enter your password"
              hint="At least 8 characters"
              min="8"
              v-model="password"
              clearable
              @click:append="() => (hide = !hide)"
              :append-icon="hide ? 'mdi-eye' : 'mdi-eye-off'"
              :append-icon-cb="() => (value = !value)"
              error
              :type="hide ? 'password' : 'text'"
            ></v-text-field>
          </div>
          <div class="absolute top-[45px] w-full flex justify-end">
            <button class="text-xs" @click="passwordRecovery">Forgot password?</button>
          </div>
        </div>

        <div class="my-6">
          <div>
            <v-btn class="w-full" color="#F19D38" @click="login">Log In</v-btn>
          </div>
          <div class="my-4 text-center">OR</div>
          <div>
            <v-btn class="w-full" @click="loginWithGoogle">
              <img src="../../../assets/icons/google.png" class="w-[24px] mr-4" alt="google_logo" />

              Log in with Google</v-btn
            >
          </div>
        </div>
      </div>
      <div>
        <div class="py-10 text-center">
          <v-btn text @click="register" small>
            <span class="font-bold">Dont' have an account?</span>
          </v-btn>
          <br />
          <div class="text-xs mt-2">
            <Copyright />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Copyright from '@/components/footer/CopyrightText.vue';
import { auth, googleAuthProvider } from '@/firebase';
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { ensureUserExists } from '../ensure-user-exists';

export default {
  data() {
    return {
      email: '',
      password: '',
      hide: true,
    };
  },
  components: { Copyright },
  mounted() {
    // select * from levels
    // db.collection("levels")
    //   .get()
    //   .then((querySnapshot) => {
    //     // rows
    //     const rows = querySnapshot.docs.map((doc) => doc.data());
    //     // do something with documents
    //     console.log("results:", rows);
    //   });
  },
  methods: {
    passwordRecovery() {
      this.$router.push('password-recovery');
    },
    register() {
      this.$router.push('/register');
    },
    async login() {
      try {
        const res = await signInWithEmailAndPassword(auth, this.email, this.password);
        console.log('result login', res);
        const userDetails = await ensureUserExists(res.user.email, res.user.displayName);
        this.setLocalStorageItems(userDetails);
        // this.$router.replace({ name: 'Dashboard' });

        this.$router.replace({ name: this.getLandingRoute(userDetails) });
      } catch (err) {
        this.$toast.open({ type: 'error', message: `Error: ${err.message}` });
      }
    },
    async loginWithGoogle() {
      try {
        const result = await signInWithPopup(auth, googleAuthProvider);
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        const userDetails = await ensureUserExists(result.user.email, result.user.displayName);
        this.setLocalStorageItems(userDetails);
        // this.$router.replace({ name: 'Dashboard' });

        this.$router.replace({ name: this.getLandingRoute(userDetails) });
      } catch (err) {
        // Handle Errors here.
        const errorCode = err.code;
        const errorMessage = err.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        this.$toast.open({ type: 'error', message: `Error: ${errorMessage} (${errorCode})` });
      }
    },
    setLocalStorageItems(items) {
      localStorage.setItem('user', JSON.stringify(items));
    },
    getLandingRoute(userDetails) {
      if (userDetails.role.toLowerCase() === '1cdsuperadmin') return 'Dashboard';
      if (userDetails?.permissions.includes('dashboard')) return 'Dashboard';
      return 'Virtual Spaces';
    },
  },
};
</script>
