<template>
  <div>
    <TitleHeader />
    <!-- send email  -->
    <div class="sm:grid grid-cols-3 gap-2">
      <div class="w-full">
        <InputField v-model="name" label="Enter name" type="text" />
      </div>
      <div>
        <InputField v-model="email" label="Enter email" type="email" />
      </div>
      <div>
        <InputField v-model="showroom_url" label="Enter url" type="text" />
      </div>
    </div>
    <div class="my-4">
      <textarea placeholder="type description" class="border-solid border-gray-500 border-2 p-2 w-full" v-model="description" cols="20" rows="3"></textarea>
    </div>
    <div class="my-4">
      <v-btn class="w-full"> Attach images </v-btn>
    </div>
    <v-btn @click="sendEmail" class="w-full"> Send </v-btn>

    <!-- table of history email  -->

    <div>
      <div class="font-bold text-xl py-4">Invitations Table</div>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search">
        <template v-slot:[`item.time_stamp`]="{ item }">
          {{ moment(item.time_stamp) }}
        </template>
      </v-data-table>
    </div>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <v-btn text color="primary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import InputField from '../../components/form/InputField.vue';
import emailjs from '@emailjs/browser';
import supabase from '../../database/config/supabaseClient.js';
import { getCustomers } from '../customers/get-customers';
import TitleHeader from '../../components/TitleHeader.vue';
import { db } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
export default {
  data() {
    return {
      myUserId: '',
      snackbar: false,
      snackbarText: '',
      items: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Email', value: 'email' },
        { text: 'Showroom URL', value: 'showroom_url' },
        { text: 'Date sent', value: 'time_stamp' },
      ],
      loading: false,
      search: '',

      // email component
      name: '',
      email: '',
      message: '',
      showroom_url: '',

      customers: [],
      showrooms: [],
      customersSelected: [],
      showroomsSelected: [],
      text: '',
    };
  },

  mounted() {
    this.loadCustomers();
    emailjs.init('l206l0TzHdlv-UgTj');
    this.populateInvitationsTable(this.myUserId);
  },
  watch: {
    '$route.path': {
      handler(e) {
        if (e) {
          this.myUserId = localStorage.getItem('MyUserId');
          this.populateInvitationsTable(this.myUserId);
        }
      },
      immediate: true,
    },
  },
  methods: {
    moment(d) {
      return moment(d).format('LLL');
    },
    capitalized(text) {
      return text.toUpperCase();
    },
    sendEmail() {
      if (!this.email || !this.name || !this.showroom_url) {
        return alert('Please fill in all fields');
      }
      try {
        emailjs
          .send('service_8d9iaoh', 'template_u4a5j1b', {
            to_name: this.capitalized(this.name),
            to_email: this.email,
            message: this.showroom_url,
            reply_to: 'renato@1clickdesign.com',
          })
          .then(async (res) => {
            console.log('res123', res.status);
            if (res.status == 200) {
              this.snackbar = true;
              this.snackbarText = 'Successfully sent your invitation';
              await this.insertToDb(
                this.email,
                this.showroom_url,
                this.name,
                this.myUserId,
                this.description
              );
              await this.populateInvitationsTable(this.myUserId);
              this.clearInputs();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.log({ error });
      }
    },
    clearInputs() {
      this.name = '';
      this.email = '';
      this.message = '';
      this.showroom_url = '';
    },
    async insertToDb(email, showroom_url, name, sender_userid, description) {
      await supabase.from('invitations').insert([
        {
          email: email,
          customer_name: name,
          showroom_url: showroom_url,
          sender_userid: sender_userid,
          description: description,
        },
      ]);
    },
    async populateInvitationsTable(myUserId) {
      this.loading = true;
      try {
        const res = await supabase
          .from('invitations')
          .select('*')
          .filter('sender_userid', 'eq', myUserId)
          .order('id', {
            ascending: false,
          });
        console.log('res.data', res);
        this.loading = false;
        this.items = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async loadCustomers() {
      this.customers = await getCustomers();
    },
  },
  components: { TitleHeader, InputField },
};
</script>