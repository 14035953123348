<template>
  <div class="my-4">
    <v-card>
      <div class="font-thin text-4xl text-center mt-3">Profile Picture</div>
      <div class="flex justify-center py-4">
        <img :src="avatarUrl" alt="image" class="w-1/4 rounded-full" />
      </div>
      <v-card-text class="text-center">
        <v-btn rounded color="success" class="max-w-1/2" elevation="0"> Change my picture</v-btn>
      </v-card-text>
    </v-card>

    <div class="w-full my-4">
      Select Date/Time format
      <select name="Select Date/Time Format" id="" class="bg-gray-100 w-full py-4 px-2">
        <option value="">
          {{ format1(new Date()) }}
        </option>
        <option value="">{{ format2(new Date()) }}</option>
        <option value="">{{ format3(new Date()) }}</option>
      </select>
    </div>
    <div class="px-2">
      <v-text-field
        name="Date Account Established"
        label="Date Account Established"
        v-model="dateAccountCreated"
      ></v-text-field>
    </div>
    <v-btn rounded class="w-full" color="success" elevation="0">Enter</v-btn>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      dateAccountCreated:'3/23/2023',
      dateTimeFormats: [
        {
          text: 'MM/DD/YYYY',
          value: 'd1',
        },
      ],
      dateTime: '',
      avatarUrl: '',
    };
  },
  mounted() {
    this.avatarUrl = this.getAvatarUrl();
  },
  methods: {
    getAvatarUrl() {
      return JSON.parse(localStorage.getItem('user')).avatar_url;
    },
    format1(d) {
      return moment(d).format('l');
    },
    format2(d) {
      return moment(d).format('LLL');
    },
    format3(d) {
      return moment(d).format('LLLL');
    },
  },
};
</script>
