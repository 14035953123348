<template>
  <div>
    <div class="text-center my-4">Are you sure you want to delete this company?</div>
    <div class="py-4">
      <ActionButtons @cancel="$emit('closeDialog')" @submit="confirm" :title="'Delete'" />
    </div>
  </div>
</template>
<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { db } from '@/firebase';
import { doc, setDoc } from 'firebase/firestore';

export default {
  components: { ActionButtons },
  props: ['id', "mainTitle"],
  methods: {
    async confirm() {
      if (!this.id) return;
      try {
        await setDoc(doc(db, 'companies', this.id), { is_deleted: true }, { merge: true });
        this.$emit('closeDialog');
        this.$toast.open('Deleted company');
      } catch (err) {
        console.error(err);
        this.$toast.open({ type: 'error', message: `Error: ${err.message}` });
      }
    },
  },
};
</script>