<template>
  <div class="bg-white p-2">
    <DialogTopBar @closeDialog="close" />

    <div class="text-xl my-2 text-center font-bold">
      {{ title }}
    </div>
    <div class="grid-cols-3 gap-2 grid">
      <div>
        <div class="my-1 border-solid border-2 border-gray-300 rounded-md px-2 w-full">
          Type:<input type="text" :value="type" />
        </div>
        <div class="my-1 border-solid border-2 border-gray-300 rounded-md px-2 w-full">
          Title: <input type="text" :value="title" />
        </div>
        <div class="my-1 border-solid border-2 border-gray-300 rounded-md px-2 w-full">
          Start Date: <input type="text" :value="start_date" />
        </div>
        <div class="my-1 border-solid border-2 border-gray-300 rounded-md px-2 w-full">
          Due Date: <input type="text" :value="due_date" />
        </div>
        <div class="my-1 border-solid border-2 border-gray-300 rounded-md px-2 w-full">
          Assignees: <input type="text" :value="assignees" />
        </div>
        <div class="my-1 border-solid border-2 border-gray-300 rounded-md px-2 w-full">
          Status: <input type="text" :value="status" />
        </div>
        <div class="my-1 border-solid border-2 border-gray-300 rounded-md px-2 w-full">
          Priority: <input type="text" :value="priority" />
        </div>
      </div>
      <div>Lorem ooooo</div>
      <div>
        <v-btn class="w-full my-1" color="#F29D38">Save</v-btn>
        <v-btn class="w-full my-1" color="#F29D38">Send Virtual Space to Client</v-btn>
        <v-btn class="w-full my-1" color="#F29D38">Enter Virtual Space</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import DialogTopBar from '../../../components/DialogTopBar.vue';
export default {
  data() {
    return {
      type: '',
      title: '',
      start_date: '',
      due_date: '',
      assignees: '',
      status: '',
      priority: '',
    };
  },
  components: { DialogTopBar },
  methods: {
    close() {
      this.$emit('closeDialog');
    },
  },
  props: ['selectedInfo'],
  watch: {
    selectedInfo: {
      handler(e) {
        this.title = e.title;
        this.start_date = e.start_date;
        this.due_date = e.due_date;
        this.assignees = e.assignees;
        this.status = e.status;
        this.priority = e.priority;
      },
      immediate: true,
    },
  },
};
</script>
