<template>
  <div>
    <AddForm @closeDialog="$emit('closeDialog')" />
  </div>
</template>

<script>
import AddForm from "./AddForm.vue";
export default {
  components: { AddForm },
};
</script>