<template>
  <div class="p-4">
    <div class="sm:flex">
      <div class="p-10 sm:w-1/2">
        <InputField label="Name" type="text" v-model="name" />
        <InputField label="Url" type="text" v-model="url" />

        <!-- background music url  -->
        <span>Background Music</span>
        <div class="flex items-center">
          <v-select class="mr-2 w-3" label="Default" :items="default_background_music_choices"
            v-model="default_background_music" autocomplete></v-select>
          <div class="grow">
            <InputField type="text" v-model="background_music_url" />
          </div>
          <v-btn small elevation="" color="" @click="onPreviewBgMusicClick">
            <template v-if="!isPlayingBgMusic">
              <v-icon>mdi-play</v-icon>Preview
            </template>
            <template v-else>
              <v-icon>mdi-pause</v-icon>Stop
            </template>
          </v-btn>
          <v-btn small elevation="" color="" @click="onUploadBgMusicClick">
            <v-icon>mdi-upload</v-icon>{{ background_music_file !== null ? background_music_file.name : 'Upload'}}
          </v-btn>
          <input class="hidden" type="file" ref="bgMusicUpload" @change="onBgMusicUpload" />
          <audio class="hidden" ref="bgMusicPreview" />
        </div>

        <!-- description  -->
        <span>Description</span>
        <div class="border-solid border-2 border-gray-200">
          <textarea class="p-2 w-full h-full outline-none bg-white" name="Description" id="description" cols="2"
            rows="2" v-model="description"></textarea>
        </div>
        <!-- virtual space type  -->
        <div>
          <v-select label="Virtual Space Type" :items="virtual_space_type_choices" v-model="virtual_space_type"
            autocomplete></v-select>
        </div>

        <!-- checkboxes  -->
        <div class="flex items-center justify-between">
          <v-checkbox label="Show Product Hotspot" v-model="show_product_hotspot"
            value="show_product_hotspot"></v-checkbox>
          <v-checkbox label="Is Public " v-model="is_public" value="is_public"></v-checkbox>
          <v-checkbox label="Third Party Space" v-model="third_party_space" value="third_party_space"></v-checkbox>
        </div>

        <!-- options offered  -->
        <div>
          <v-select label="Options Offered" :items="custom_options" v-model="custom_options_choices"
            autocomplete></v-select>
        </div>

        <!-- authorized users  -->
        <div>
          <v-select label="Authorized Users" :items="authorized_users_choices" v-model="authorized_users"
            autocomplete></v-select>
        </div>
      </div>

      <div class="sm:w-1/2 flex flex-col">
        <div class="flex border-solid border-b-2 border-gray-300 justify-start grow-0">
          <button @click="onThumbnailUploadClick" class="
              hover:bg-[#e2ba86] hover:text-white
              border-solid border-r-2 border-l-2 border-t-2
              focus:bg-[#DDA55C] focus:text-gray-500
              active:bg-[#DDA55C]
              rounded-t-md
              px-2
              border-gray-300
            ">
            Thumbnail
          </button>
          <input class="hidden" type="file" ref="thumbnailUpload" @change="onThumbnailUpload" />
          <button @click="onPhotosUploadClick" class="
              hover:bg-[#e2ba86] hover:text-white
              border-solid border-r-2 border-l-2 border-t-2
              focus:bg-[#DDA55C] focus:text-gray-500
              active:bg-[#DDA55C]
              rounded-t-md
              px-2
              border-gray-300
            ">
            Photos
          </button>
          <input class="hidden" type="file" ref="photosUpload" @change="onPhotosUpload" multiple />
          <button @click="onLogoUploadClick" class="
              hover:bg-[#e2ba86] hover:text-white
              border-solid border-r-2 border-l-2 border-t-2
              focus:bg-[#DDA55C] focus:text-gray-500
              active:bg-[#DDA55C]
              rounded-t-md
              px-2
              border-gray-300
            ">
            Logo
          </button>
          <input class="hidden" type="file" ref="logoUpload" @change="onLogoUpload" />
        </div>

        <div class="flex flex-col overflow-y-auto grow">
          <img class="w-16 h-16 object-contain" :src="thumbnailFilePreview" />
          <img class="w-16 h-16 object-contain" v-for="(photoSrc, i) in photoFilePreviews" :key="i" :src="photoSrc" />
          <img class="w-16 h-16 object-contain" :src="logoFilePreview" />
        </div>
      </div>

    </div>
    <ActionButtons :title="'Submit'" @submit="submit" @cancel="$emit('closeDialog')" />
  </div>
</template>

<script>
import ActionButtons from '@/components/ActionButtons.vue';
import InputField from '@/components/form/InputField.vue';
import { db, storage } from "@/firebase";
import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { getVirtualSpace } from '../../apis/get-virtual-space';

export default {
  props: ['id'],
  components: { InputField, ActionButtons },
  data() {
    return {
      name: '',
      url: '',
      default_background_music: '',
      background_music_url: '',
      background_music_file: null,
      description: '',
      virtual_space_type: '',
      show_product_hotspot: false,
      is_public: false,
      third_party_space: false,
      custom_options: [],
      authorized_users: [],
      thumbnail_file: null,
      existing_thumbnail_url: '',
      photo_files: [],
      existing_photo_urls: '',
      logo_file: null,
      existing_logo_url: '',
      virtual_space_type_choices: [
        { text: 'Virtual Space Showroom', value: 'showroom' },
        { text: 'Virtual Space Real Estate', value: 'real_estate' },
      ],
      custom_options_choices: [],
      authorized_users_choices: [],
      default_background_music_choices: [],
      isPlayingBgMusic: false,
    };
  },
  computed: {
    thumbnailFilePreview() {
      if (this.thumbnail_file) return URL.createObjectURL(this.thumbnail_file);
      if (this.existing_thumbnail_url) return this.existing_thumbnail_url;
      return null;
    },
    photoFilePreviews() {
      if (this.photo_files.length > 0) return [...this.photo_files].map(p => URL.createObjectURL(p));
      if (this.existing_photo_urls) return this.existing_photo_urls;
      return [];
    },
    logoFilePreview() {
      if (this.logo_file) return URL.createObjectURL(this.logo_file);
      if (this.existing_logo_url) return this.existing_logo_url;
      return null;
    },
  },
  watch: {
    id: {
      handler() {
        this.loadData();
      },
      immediate: true,
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        const vs = await getVirtualSpace(this.id);
        this.name = vs.name;
        this.url = vs.url;
        this.background_music_url = vs.background_music_url;
        this.description = vs.description;
        this.virtual_space_type = vs.virtual_space_type;
        this.show_product_hotspot = vs.show_product_hotspot;
        this.is_public = vs.is_public;
        this.third_party_space = vs.third_party_space;
        this.custom_options = vs.custom_options;
        this.authorized_users = vs.authorized_users;
        this.existing_thumbnail_url = vs.thumbnail_url;
        this.existing_photo_urls = vs.photo_urls;
        this.existing_logo_url = vs.logo_url;
      } catch (err) {
        console.error('Error loading choices:', err);
      } finally {
        this.loading = false;
      }
    },
    onThumbnailUploadClick() {
      this.$refs.thumbnailUpload.click();
    },
    onThumbnailUpload(e) {
      this.thumbnail_file = e.target.files[0];
    },
    onPhotosUploadClick() {
      this.$refs.photosUpload.click();
    },
    onPhotosUpload(e) {
      this.photo_files = e.target.files;
    },
    onLogoUploadClick() {
      this.$refs.logoUpload.click();
    },
    onLogoUpload(e) {
      this.logo_file = e.target.files[0];
    },
    onPreviewBgMusicClick() {
      if (this.isPlayingBgMusic) {
        this.$refs.bgMusicPreview.pause();
        this.$refs.bgMusicPreview.currentTime = 0;
        this.isPlayingBgMusic = false;
        return;
      }
      if (!this.background_music_file) return;
      // Source: https://stackoverflow.com/a/28619927
      this.$refs.bgMusicPreview.src = URL.createObjectURL(this.background_music_file);
      this.$refs.bgMusicPreview.onend = function () {
        URL.revokeObjectURL(this.src);
      };
      this.$refs.bgMusicPreview.play();
      this.isPlayingBgMusic = true;
    },
    onUploadBgMusicClick() {
      this.$refs.bgMusicUpload.click();
    },
    onBgMusicUpload(e) {
      this.background_music_file = e.target.files[0];
    },
    async submit() {
      try {
        let bgMusicUrl = this.background_music_url;
        let thumbFileUrl = this.existing_thumbnail_url;
        let photoUrls = this.existing_photo_urls;
        let logoFileUrl = this.existing_logo_url;

        await setDoc(doc(db, 'virtual_spaces', this.id), {
          name: this.name,
          url: this.url,
          background_music_url: this.background_music_url,
          description: this.description,
          virtual_space_type: this.virtual_space_type,
          show_product_hotspot: this.show_product_hotspot,
          is_public: this.is_public,
          third_party_space: this.third_party_space,
          custom_options: [],
          authorized_users: [],
          is_deleted: false,
        }, { merge: true });

        const virtualSpaceFolderPath = `virtual_spaces/${this.name.replace(/ /g, '')}-${this.id}`;

        if (this.background_music_file !== null) {
          const ext = this.background_music_file.name.split('.').pop();
          const bgMusicFileRef = ref(storage, `${virtualSpaceFolderPath}/bgmusic.${ext}`);
          await uploadBytes(bgMusicFileRef, this.background_music_file);
          bgMusicUrl = await getDownloadURL(bgMusicFileRef);
        }

        if (this.thumbnail_file !== null) {
          const ext = this.thumbnail_file.name.split('.').pop();
          const thumbFileRef = ref(storage, `${virtualSpaceFolderPath}/thumbnail.${ext}`);
          await uploadBytes(thumbFileRef, this.thumbnail_file);
          thumbFileUrl = await getDownloadURL(thumbFileRef);
        }

        let i = 0;
        for (const photo_file of this.photo_files) {
          i++;
          const ext = photo_file.name.split('.').pop();
          const photoFileRef = ref(storage, `${virtualSpaceFolderPath}/photo-${i}.${ext}`);
          await uploadBytes(photoFileRef, photo_file);
          photoUrls.push(await getDownloadURL(photoFileRef));
        }

        if (this.logo_file !== null) {
          const ext = this.logo_file.name.split('.').pop();
          const logoFileRef = ref(storage, `${virtualSpaceFolderPath}/logo.${ext}`);
          await uploadBytes(logoFileRef, this.logo_file);
          logoFileUrl = await getDownloadURL(logoFileRef);
        }

        await setDoc(doc(db, 'virtual_spaces', this.id), {
          background_music_url: bgMusicUrl,
          thumbnail_url: thumbFileUrl,
          photo_urls: photoUrls,
          logo_url: logoFileUrl,
        }, { merge: true });

        this.$toast.open('Updated Virtual Space');
        this.$emit('closeDialog');
      } catch (err) {
        console.error(err);
        this.$toast.open({ type: 'error', message: err.message });
      }
    },
  },
};
</script>