<template>
  <v-container fluid>
    <p>{{ company.company_name }}</p>
    <v-divider></v-divider>
    <v-checkbox
      v-for="(item, i) in items"
      :key="i"
      v-model="selected"
      :label="item.name"
      :value="item.value"
    ></v-checkbox>
    <div class="d-flex justify-end">
      <v-btn @click="save" rounded color="#F29E37" dark elevation="0">Submit</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selected: [],
      items: [
        { name: 'Virual Spaces' , value: 'virtual_spaces' },
        { name: 'Analytics', value: 'analytics' },
        { name: 'Companies', value: 'companies' },
        { name: 'Customers', value: 'customers' },
        { name: 'Customer Activity', value: 'customer_activity' },
        { name: 'Products', value: 'products' },
        { name: 'Users', value: 'users' },
        { name: 'Video Conference', value: 'video_conference' },
      ],
    };
  },
  props: ["company"],
  methods: {
    save() {
      const item = {
        company: this.company,
        selected: this.selected
      };

      console.log(item);

      this.$emit('closePermissionDialog', false)
    }
  }
};
</script>