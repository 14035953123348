<template>
  <div>
    <DialogTopBar @closeDialog="close" mainTitle="Add Customer" />
    <div>
      <div class="text-center my-2">
        <v-icon v-if="!avatarPreviewUrl" large class="w-full"> mdi-account-circle </v-icon>
        <div v-else class="flex flex-row justify-center mb-4">
          <v-avatar size="50">
            <img :src="avatarPreviewUrl" class="object-cover" />
          </v-avatar>
        </div>
        <v-btn small elevation="" color="" @click="onUploadClick"> Click to upload image </v-btn>
        <input class="hidden" type="file" ref="avatarFileUpload" @change="onAvatarUpload" />
      </div>
    </div>
    <div class="sm:grid grid-cols-3 gap-10">
      <div>
        <div class="text-xl text-[#F19D38] pb-2">Personal Details</div>
        <div v-for="(item, i) in personal_details" :key="i" class="pb-1">
          <InputField :label="item.label" :type="item.type" v-model="item.value" />
          <span v-if="item.err" class="text-xs italic text-red-500">
            {{ item.label }} is required
          </span>
        </div>
      </div>
      <div>
        <div class="pb-2 text-xl text-[#F19D38]">Address</div>
        <div v-for="(item, j) in address" :key="j">
          <InputField :label="item.label" :type="item.type" v-model="item.value" />
          <span v-if="item.err" class="text-xs italic text-red-500"
            >{{ item.label }} is required</span
          >
        </div>
      </div>
      <div>
        <div class="text-xl text-[#F19D38]">Settings</div>
        <div v-for="(item, i) in settings" :key="i">
          <v-checkbox
            dense
            :label="item.label"
            v-model="item.value"
            :value="item.value"
          ></v-checkbox>
        </div>
        <div>
          <TextArea />
          <!-- <span class="text-xs italic text-red-500">{{ item.err }}</span> -->
        </div>
      </div>
    </div>
    <div class="my-2 border-t-2 border-gray-300 flex justify-center py-2">
      <ActionButtons title="Submit" @cancel="close" @submit="submit" />
    </div>
  </div>
</template>
<script>
import DialogTopBar from '@/components/DialogTopBar.vue';
import ActionButtons from '@/components/ActionButtons.vue';
import InputField from '@/components/form/InputField';
import TextArea from '@/components/form/TextArea';
import { db, storage } from '@/firebase';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { ADDRESS, PERSONAL_DETAILS } from '../customer-fields';

export default {
  components: { DialogTopBar, InputField, TextArea, ActionButtons },
  data() {
    return {
      description: '',
      avatarFile: null,
      personal_details: PERSONAL_DETAILS,
      address: ADDRESS,
      settings: [
        {
          label: 'Show Hotspot',
          value: 'show_hotspot',
          type: 'checkbox',
          // err: "",
        },
        {
          label: 'Show Price',
          value: 'show_price',
          type: 'checkbox',
          // err: "",
        },
      ],
    };
  },
  computed: {
    avatarPreviewUrl() {
      if (this.avatarFile !== null) return URL.createObjectURL(this.avatarFile);
      return null;
    },
  },
  methods: {
    close() {
      this.$emit('closeDialog');
      // clear all data in personal_details form field
      for (const key in this.personal_details) {
        this.personal_details[key].err = false;
      }
      // clear all data in personal_details address
      for (const key in this.address) {
        this.address[key].err = false;
      }
    },
    onUploadClick() {
      this.$refs.avatarFileUpload.click();
    },
    onAvatarUpload(e) {
      this.avatarFile = e.target.files[0];
    },
    async submit() {
      try {
        let canSubmit = true;
        for (const key in this.personal_details) {
          this.personal_details[key].err = false;
          if (!this.personal_details[key].value) {
            this.personal_details[key].err = true;
            canSubmit = false;
          }
        }
        for (const key in this.address) {
          this.address[key].err = false;
          if (!this.address[key].value) {
            this.address[key].err = true;
            canSubmit = false;
          }
        }
        if (canSubmit) {
          let findPersonalDetail = (name) =>
            this.personal_details.find((p) => p.name === name)?.value ?? '';
          let findAddress = (name) => this.address.find((p) => p.name === name)?.value ?? '';
          let avatarUrl = '';

          const docRef = await addDoc(collection(db, 'customers'), {
            full_name: findPersonalDetail('full_name'),
            email: findPersonalDetail('email'),
            phone: findPersonalDetail('phone'),
            office_phone: findPersonalDetail('office_phone'),
            resale_number: findPersonalDetail('resale_number'),
            position: findPersonalDetail('position'),
            company: findPersonalDetail('company'),
            address: findPersonalDetail('address'),
            web_url: findPersonalDetail('web_url'),
            description: this.description,
            street: findAddress('street'),
            city: findAddress('city'),
            state: findAddress('state'),
            zip: findAddress('zip'),
            avatar_url: avatarUrl,
            is_deleted: false,
          });

          if (this.avatarFile !== null) {
            const ext = this.avatarFile.name.split('.').pop();
            const fileName = `avatars/customer-${findPersonalDetail('full_name').replace(
              / /g,
              ''
            )}-${docRef.id}.${ext}`;
            const avatarRef = ref(storage, fileName);
            await uploadBytes(avatarRef, this.avatarFile);
            avatarUrl = await getDownloadURL(avatarRef);
          }

          await setDoc(doc(db, 'customers', docRef.id), { avatar_url: avatarUrl }, { merge: true });

          this.$toast.open('Created customer');
          this.$emit('closeDialog');
        }
      } catch (err) {
        console.error(err);
        this.$toast.open({ type: 'error', message: `Error: ${err.message}` });
      }
    },
  },
};
</script>

<style scoped>
.v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 36px;
}
</style>