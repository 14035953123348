<template>
  <div>
    <div class="text-center my-2">
      <v-icon v-if="!avatarPreviewUrl" large class="w-full"> mdi-account-circle </v-icon>
      <div v-else class="flex flex-row justify-center mb-4">
        <v-avatar size="50">
          <img :src="avatarPreviewUrl" class="object-cover" />
        </v-avatar>
      </div>
      <v-btn small elevation="" color="" @click="onUploadClick"> Click to upload image </v-btn>
      <input class="hidden" type="file" ref="avatarFileUpload" @change="onAvatarUpload" />
    </div>
    <v-text-field dense name="email" label="Email" type="email" v-model="email"></v-text-field>
    <v-text-field
      dense
      name="full_name"
      label="Display Name"
      type="text"
      v-model="full_name"
    ></v-text-field>
    <v-select
      label="Company"
      v-model="company"
      :items="companyChoices"
      item-text="company_name"
      item-value="id"
      autocomplete
      dense
    ></v-select>
    <v-radio-group v-model="role_selected" row>
      <div v-for="(role, j) in roles" :key="j" class="flex">
        <v-radio :label="role.name" :value="role.id"></v-radio>
      </div>
    </v-radio-group>
    <v-select
      label="Permissions"
      v-model="permissions"
      :items="permissionsChoices"
      autocomplete
      multiple
      dense
    ></v-select>
    <v-autocomplete
      label="Regions"
      v-model="regions"
      :items="regionsAutocomplete"
      multiple
      dense
    ></v-autocomplete>
    <v-text-field
      dense
      name="position"
      label="Position"
      type="text"
      v-model="position"
    ></v-text-field>
    <v-switch dense label="Receive email" v-model="receive_email"></v-switch>
    <ActionButtons @submit="onSubmit" @cancel="$emit('cancel')" :title="'Submit'" />
  </div>
</template>

<script>
import ActionButtons from '@/components/ActionButtons.vue';
import { db } from '@/firebase';
import { getRegionsData } from '@/views/users/get-regions-data';
import { getRoles } from '@/views/users/get-roles';
import { getUser } from '@/views/users/get-user';
import { PERMISSIONS_CHOICES } from '@/views/users/permissions';
import { collection, getDocs } from 'firebase/firestore';

export default {
  components: { ActionButtons },
  props: ['id'],
  data() {
    return {
      avatarFile: null,
      oldAvatarUrl: '',
      email: '',
      full_name: '',
      company: '',
      role_selected: '',
      permissions: null,
      receive_email: false,
      regions: [],
      position: '',
      roles: [],
      permissionsChoices: PERMISSIONS_CHOICES,
      regionsData: {},
      regionSearch: '',
      regionsAutocomplete: [],
      companyChoices: [],
    };
  },
  computed: {
    avatarPreviewUrl() {
      if (this.avatarFile !== null) return URL.createObjectURL(this.avatarFile);
      if (this.oldAvatarUrl) return this.oldAvatarUrl;
      return null;
    },
  },
  watch: {
    regionSearch() {
      this.regionsAutocomplete = [];
      if (this.regionSearch === '') return;
      this.regionsAutocomplete = Object.values(this.regionsData).reduce(
        (acc, val) => acc.concat(val),
        []
      );
    },
    id: {
      handler() {
        this.loadData();
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async onSubmit() {
      this.$emit('submit', {
        id: this.id,
        oldAvatarUrl: this.avatar_url,
        avatarFile: this.avatarFile,
        email: this.email,
        full_name: this.full_name,
        company: this.company,
        role: this.role_selected,
        permissions: this.permissions,
        receive_email: this.receive_email,
        regions: this.regions,
        position: this.position,
      });
    },
    onUploadClick() {
      this.$refs.avatarFileUpload.click();
    },
    onAvatarUpload(e) {
      this.avatarFile = e.target.files[0];
    },
    async loadData() {
      this.loading = true;
      try {
        const availableRoles = await getRoles();
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const is1cdSuperAdmin = userDetails.role.toLowerCase() === '1cdsuperadmin';
        const isUserAdmin = userDetails.role.toLowerCase() === 'admin';
        const isClient = userDetails.role.toLowerCase() === 'client';

        this.roles = is1cdSuperAdmin
          ? availableRoles
          : availableRoles.filter((roles) => {
              if (isUserAdmin) {
                return !['1cdsuperadmin', 'admin'].includes(roles.id);
              }
              if (isClient) {
                return !['1cdsuperadmin', 'admin', 'client'].includes(roles.id);
              }
              return roles.id;
            });
        this.regionsData = await getRegionsData();
        this.companyChoices = await this.getCompanies();
        this.regionsAutocomplete = Object.values(this.regionsData).reduce(
          (acc, val) => acc.concat(val),
          []
        );
        if (!this.id) return;
        const user = await getUser(this.id);
        this.full_name = user.full_name;
        this.email = user.email;
        this.company = user.company ?? '';
        this.role_selected = user.role ?? '';
        this.permission = user.permission ?? '';
        this.receive_email = user.receive_email ?? false;
        this.region = user.region ?? null;
        this.position = user.position ?? '';
        this.permissions = user.permissions;
        this.regionsAutocomplete = user.regions;
        this.regions = user.regions;
        this.avatar_url = user.avatar_url;
      } catch (err) {
        console.error('Error loading choices:', err);
      } finally {
        this.loading = false;
      }
    },
    async getCompanies() {
      const q = await getDocs(collection(db, 'companies'));
      const data = [];
      q.forEach((x) => data.push({ id: x.id, ...x.data() }));
      return data;
    },
  },
};
</script>
