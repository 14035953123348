<template>
  <div class="bg-gray-50 flex flex-col h-screen">
    <!-- <div class="sm:flex">
      <div class="fixed left-0 h-full w-[248px]">
        <TopNavigationBar class="bg-white sm:h-[60px] sm:flex sm:fixed hidden w-full" />
        <LeftSideBar class="bg-white w-[248px] h-full mt-[60px] hidden sm:block drop-shadow-2xl" />
      </div>
      <div class="mr-[32px] sm:ml-[280px] mt-[50px] w-full">
        <router-view />
      </div>
    </div> -->
    <TopNavigationBar class="bg-white w-full h-[60px]" />
    <div class="flex justify-between" style="height: calc(100vh - 60px)">
      <LeftSideBar />
      <router-view class="h-full flex-grow overflow-y-auto p-4 md:p-10" />
    </div>
  </div>
</template>

<script>
import TopNavigationBar from '../../components/TopNavigationBar.vue';
import LeftSideBar from '../../components/LeftSideBar.vue';

export default {
  components: {
    TopNavigationBar,
    LeftSideBar,
  },
};
</script>
