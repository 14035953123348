import { db } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export async function getShowroomManager(search) {
  const conditions = [where('is_deleted', '==', false)];
  // "Starts with" string check
  if (search) conditions.push(where('is_showroom_manager','==','true'));
  const q = await getDocs(query(collection(db, 'customers'), ...conditions));
  const showroomManagers = [];
  q.forEach((customer) =>
    showroomManagers.push({
      id: customer.id,
      ...customer.data(),
    })
  );
  return showroomManagers;
}
