<template>
  <div class="sm:flex justify-between w-full">
    <div class="flex justify-end" :class="$route.name == 'Users' ? 'w-1/2' : 'w-full'">
      <div class="flex items-center">
        <SimpleSearch class="mr-2" :placeholder="'Search Plans...'" :search_id="'Plan'" />
        <div>
          <v-btn small elevation="" color="#F29E37" @click="addPlans">Add Plan </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleSearch from '../../../../components/SimpleSearch.vue';
export default {
  components: { SimpleSearch },
  methods: {
    addPlans() {
      this.$emit('addPlans');
    },
  },
};
</script>
