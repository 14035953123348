<template>
  <div>
    <!-- header  -->
    <TitleHeader />
    <!-- 4 col grids  -->
    <!-- main content in 2 col grid  -->
    <div>
      <SimpleSearch
        :kSearch="kSearch"
        :placeholder="'Search Notifications...'"
        :search_id="'Notification'"
      />
    </div>
    <div>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex">
            <button
              text
              small
              @click="
                onSelectRow({
                  mode: 'edit',
                  item: item,
                })
              "
              class="mr-2"
            >
              <v-icon small> mdi-pencil </v-icon>
            </button>
            <button text small @click="onSelectRow({ mode: 'delete', item: item })">
              <v-icon small> mdi-delete </v-icon>
            </button>
          </div>
        </template>
      </v-data-table>
    </div>
    <!-- edit  -->
    <v-dialog v-model="editDialog" persistent :overlay="false" transition="dialog-transition">
      <div class="bg-white">
        <DialogTopBar :mainTitle="selectedInfo.mode" @closeDialog="editDialog = false" />
        <EditView class="bg-white" :selectedInfo="selectedInfo" @closeDialog="editDialog = false" />
      </div>
    </v-dialog>
    <!-- delete  -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      :overlay="false"
      width="300px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar :mainTitle="selectedInfo.mode" @closeDialog="deleteDialog = false" />
        <DeleteView class="bg-white" :selectedInfo="selectedInfo" />
      </div>
    </v-dialog>
    <!-- Add  -->
    <v-dialog v-model="addDialog" persistent :overlay="false" transition="dialog-transition">
      <AddView class="bg-white" :selectedInfo="selectedInfo" />
    </v-dialog>
  </div>
</template>

<script>
import SimpleSearch from '@/components/SimpleSearch.vue';
import moment from 'moment';
import TitleHeader from '../../components/TitleHeader.vue';
import DialogTopBar from '../../components/DialogTopBar.vue';

export default {
  data() {
    return {
      kSearch: '',
      loading: false,
      search: '',
      selectedInfo: [],
      editDialog: false,
      deleteDialog: false,
      addDialog: false,
      items: [
        {
          id: 1,
          date: '',
          notification_detail: '',
        },
        {
          id: 2,
          date: '',
          notification_detail: '',
        },
        {
          id: 3,
          date: '',
          notification_detail: '',
        },
        {
          id: 4,
          date: '',
          notification_detail: '',
        },
      ],
      headers: [
        {
          text: 'Date',
          value: 'date',
        },
        {
          text: 'Notification Detail',
          value: 'notification_detail',
        },
      ],
    };
  },
  components: { TitleHeader, DialogTopBar, SimpleSearch },
  methods: {
    onSelectRow(e) {
      console.log('item', e);
      if (e.mode == 'edit') {
        this.editDialog = true;
        this.selectedInfo = e;
      } else if (e.mode == 'delete') {
        this.deleteDialog = true;
        this.selectedInfo = e;
      }
    },
    moment(d) {
      return moment(d).format('llll');
    },
  },
};
</script>
