import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
// import 'firebase/compat/auth';

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
// const { Timestamp, GeoPoint } = firebase.firestore;
// export { Timestamp, GeoPoint };

const config = {
  apiKey: 'AIzaSyDnXEDK-e6YhN5HoZJSe7xW0QsUBnuDku8',
  authDomain: 'clickdesign-53172.firebaseapp.com',
  projectId: 'clickdesign-53172',
  storageBucket: 'clickdesign-53172.appspot.com',
  messagingSenderId: '848933842885',
  appId: '1:848933842885:web:0fbdeb5e7cd7b0dc26a5ac',
  measurementId: 'G-R9QZZ8ZVY3',
};

const app = initializeApp(config);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({
  // Force user to select account every time
  prompt: 'select_account',
});
googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
