<template>
  <div>
    <div class="py-4 text-center">
      Do you want to delete this showroom? When clicked the OK button, this showroom will be
      deleted.
    </div>
    <div class="py-4 flex">
      <ActionButtons title="Enter" @cancel="$emit('closeDialog')" />
    </div>
  </div>
</template>

<script>
import ActionButtons from '@/components/ActionButtons.vue';
export default {
  props: ['selectedOtherMenusInfo'],
  components: { ActionButtons },
};
</script>