<template>
  <div>
    <div>
      <DialogTopBar @closeDialog="close" mainTitle="Create Virtual Space Type" />
    </div>

    <div v-for="(item, i) in vs" :key="i">
      <InputField v-model="item.value" :label="item.label" :type="item.type" />
      <div v-if="item.err" class="font-bold text-xs text-red-900">{{ item.label }} is required</div>
    </div>
    <ActionButtons :title="'Create Virtual Space Type'" @cancel="close" @submit="submit" />
  </div>
</template>
<script>
import ActionButtons from '@/components/ActionButtons.vue';
import DialogTopBar from '@/components/DialogTopBar.vue';
import InputField from '@/components/form/InputField.vue';
export default {
  data() {
    return {
      vs: [
        {
          label: 'Name',
          value: '',
          type: 'text',
          err: false,
        },
        {
          label: 'Value',
          value: '',
          type: 'text',
          err: false,
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit('closeDialog');
      this.clearData();
      this.clearErr();
    },
    clearData() {
      for (const key in this.vs) {
        this.vs[key].value = '';
      }
    },
    clearErr() {
      for (const key in this.vs) {
        this.vs[key].err = '';
      }
    },
    submit() {
      for (const key in this.vs) {
        if (this.vs[key].value == '') {
          this.vs[key].err = true;
        }
      }
    },
  },
  components: {
    InputField,
    ActionButtons,
    DialogTopBar,
  },
};
</script>