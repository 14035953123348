<template>
  <div>
    <div class="my-2">
      <div>
        <AddCustomerForm @closeDialog="$emit('closeDialog')" @submit="submit" />
      </div>
    </div>
  </div>
</template>

<script>
import AddCustomerForm from './AddCustomerForm.vue';
export default {
  components: { AddCustomerForm },
  methods: {
    submit(info) {
      console.log('submitted info', info);
    },
  },
};
</script>
