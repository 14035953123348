<template>
  <div>
    <div class="sm:flex">
      <div class="w-full">
        <InputField label="Company Name" type="text" v-model="company_name" readOnly />
        <div class="grid grid-cols-2 gap-2">
          <InputField label="First Name" type="text" v-model="first_name" readOnly />
          <InputField label="Last Name" type="text" v-model="last_name" readOnly />
        </div>
        <div class="grid grid-cols-2 gap-2">
          <InputField label="Email" type="text" v-model="email" readOnly />
          <InputField label="Phone Number" type="text" v-model="phone_number" readOnly />
        </div>
        <InputField label="Address" type="text" v-model="address" readOnly />
        <InputField label="Website URL" type="text" v-model="web_url" readOnly />
        <v-textarea label="Description" outlined v-model="description" class="mt-4" readOnly />
      </div>
      <div class="w-full">
        <div class="sm:flex justify-center">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab v-for="item in tabs" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>
        <v-tabs-items v-model="tab">
          <v-tab-item key="Activity"> </v-tab-item>
          <v-tab-item key="History"> </v-tab-item>
          <v-tab-item key="Virtual Spaces"> </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/form/InputField';
import { getCustomer } from '../get-customer';

export default {
  props: ['id'],
  components: { InputField },
  data() {
    return {
      tabs: ['Activity', 'History', 'Virtual Spaces'],
      tab: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      company_name: '',
      address: '',
      web_url: '',
      description: '',
    };
  },
  watch: {
    id: {
      async handler() {
        const customer = await getCustomer(this.id);
        this.first_name = customer.full_name.split(' ').slice(0, -1).join(' ');
        this.last_name = customer.full_name.split(' ').pop();
        this.email = customer.email;
        this.phone_number = customer.phone;
        this.company_name = customer.company;
        this.address = customer.address;
        this.web_url = customer.web_url;
        this.description = customer.description;
      },
      immediate: true,
    },
  },
};
</script>