<template>
  <div>
    <div>
      <!-- header  -->
      <TitleHeader />
      <!-- OVERVIEW -->
      <div class="flex flex-col gap-3 align-top h-[369px]">
        <div
          class="text-xl h-[56px] text-[#fff] bg-[#FF9800] rounded-xl py-4 px-5 justify-center items-center"
        >
          Overview
        </div>
        <!-- OVERVIEW DETAILS -->
        <div class="h-[301px] border border-[#D2D6D7] rounded-xl p-3">
          <div class="gap-[25px] flex justify-between">
            <div
              class="flex flex-col overflow-hidden basis-1/4 max-h-[85px] rounded-xl align-top justify-center text-left px-5 py-4 text-[14px] bg-[#BFD4F6]"
            >
              Total Invited Showroom Visitors
              <h6 class="text-2xl font-bold">No Data</h6>
            </div>
            <div
              class="flex flex-col overflow-hidden basis-1/4 h-[85px] rounded-xl align-top justify-center text-left px-5 py-4 text-[14px] bg-[#FFC6C2]"
            >
              Total Product Purchased
              <h6 class="text-2xl font-bold">No Data</h6>
            </div>
            <div
              class="flex flex-col overflow-hidden basis-1/4 h-[85px] rounded-xl align-top justify-center text-left px-5 py-4 text-[14px] bg-[#E3D6E6]"
            >
              Total Revenue
              <h6 class="text-2xl font-bold">No Data</h6>
            </div>
            <div
              class="flex flex-col overflow-hidden basis-1/4 h-[85px] rounded-xl align-top justify-center text-left px-5 py-4 text-[14px] bg-[#FFE5BF]"
            >
              Total Live Tour
              <h6 class="text-2xl font-bold">No Data</h6>

              <!-- GRAPH -->
              <!-- <div class="h-[176px]">
              <LineGraph />
            </div> -->
            </div>
          </div>

          <!-- CHART -->
          <div class="h-[176px] w-full">
            <LineGraph name="overview"></LineGraph>
          </div>
        </div>
      </div>

      <!-- SHOWROOM ACTIVITIES -->
      <div class="flex flex-col gap-3 align-top h-[528px]">
        <div
          class="text-xl h-[56px] text-[#fff] bg-[#FF9800] rounded-xl py-4 px-5 mt-[28px] justify-center items-center"
        >
          Showroom Activities
        </div>
        <!-- SHOWROOM VISITORS -->
        <div
          class="h-[256px] w-full flex flex-col justify-center items-start border border-[#D2D6D7] rounded-xl p-5"
        >
          <h7 class="text-xl font-bold w-full text-center"> Showroom Visitors</h7>
          <!-- GRAPH -->
          <div class="h-[176px] w-full">
            <LineGraph name="showroom"></LineGraph>
          </div>
        </div>

        <!-- VIDEO CONFERENCING ENGAGEMENT -->
        <div
          class="h-[256px] w-full flex flex-col justify-center items-start border border-[#D2D6D7] rounded-xl p-5 mb-5"
        >
          <h7 class="text-xl font-bold w-full text-center"> Video Conferencing Engagement</h7>
          <!-- GRAPH -->
          <div class="h-[176px] w-full">
            <LineGraph name="video-conf"></LineGraph>
          </div>
        </div>
      </div>
    </div>

    <!-- super admin jack - end  -->
  </div>
</template>

<script>
import { db } from '@/firebase';
import { collection, getDocs } from 'firebase/firestore';
import TitleHeader from '../../components/TitleHeader.vue';
import LineGraph from '../../components/Graph/LineGraph.vue';
export default {
  data() {
    return {
      loading: false,
      toolbar: [
        { text: 'Showrooms:', value: '5' },
        { text: 'Products:', value: '5' },
        { text: 'Customers:', value: '5' },
        { text: 'New Alerts:', value: '5' },
        { text: 'New Messages:', value: '5' },
      ],
      sales_analytics: [
        { text: 'Customer Visits:', value: '100' },
        { text: 'Products Viewed:', value: '200' },
        { text: 'My Interactions:', value: '300' },
        { text: 'Buy Signals:', value: '4022' },
        { text: 'Sales:', value: '3' },
      ],
      super_admin_basics: [
        { text: 'No. of clients', value: '123' },
        { text: 'No. of showrooms', value: '123' },
        { text: 'No. of salespersons', value: '123' },
        { text: 'No. of products', value: '123' },
        { text: 'No. of registered customers', value: '123' },
      ],
      super_admin_key_analytics: [
        { text: 'No. of salespersons logins', value: '123' },
        { text: 'No. of customer visits', value: '123' },
        { text: 'No. of product interactions', value: '123' },
        { text: 'No. of visitor/sales interactions', value: '123' },
        { text: 'No. of sales/buy signals', value: '123' },
      ],
      super_admin_sales_production: [
        { text: 'Active Prospects', value: '123' },
        { text: 'Signed/Space in Production', value: '123' },
        { text: '3D Models Required', value: '123' },
        { text: '3D Models Produced', value: '123' },
        { text: 'Finalized and Gone Live', value: '123' },
      ],
      totals_super_admin_headers: [
        { text: 'Clients', value: 'clients' },
        { text: 'Showrooms', value: 'showrooms' },
        { text: 'Sales Staff', value: 'sales_staff' },
        { text: 'Products', value: 'products' },
        { text: 'Customers', value: 'customers' },
        { text: 'Sales Logins', value: 'sales_logins' },
        { text: 'Customer Visits', value: 'customer_visits' },
        { text: 'Product Interactions', value: 'product_interactions' },
        { text: 'Sales/Visitor Interactions', value: 'sales_visitors_interactions' },
        { text: 'Buy Signals', value: 'buying_signals' },
        { text: 'Active Prospects', value: 'active_prospects' },
        { text: 'Signed/Space in Production', value: 'signed_space' },
        { text: '3D Models Required', value: '3d_models_required' },
        { text: '3D Models Produced', value: '3d_models_produced' },
        { text: 'Finalized and Live', value: 'finalized_live' },
      ],
      totals_super_admin_items: [
        {
          clients: '123',
          showrooms: '123',
          sales_staff: '123',
          products: '123',
          customers: '123',
          sales_logins: '123',
          customer_visits: '123',
          product_interactions: '123',
          sales_visitors_interactions: '123',
          buying_signals: '123',
          active_prospects: '123',
          signed_space: '123',
          three_d_models_required: '123',
          three_d_models_produced: '123',
          finalized_live: '123',
        },
      ],
      summary_menu: [
        {
          title: 'Tasks Assigned to Me',
          icon: '',
          color: '',
          total: '123',
        },
        {
          title: 'Virtual Spaces',
          icon: '',
          color: '',
          total: '123',
        },
        {
          title: 'Virtual Spaces',
          icon: '',
          color: '',
          total: '123',
        },
        {
          title: 'My Notifications',
          icon: '',
          color: '',
          total: '123',
        },
      ],
    };
  },
  mounted() {
    this.getAnalytics().then((d) => {
      console.log('data from getAnalytics', d);
    });
  },
  components: {
    TitleHeader,
    LineGraph,
  },
  methods: {
    async getAnalytics() {
      const q = await getDocs(collection(db, 'customers'));
      const customers = [];
      q.forEach((customer) =>
        customers.push({
          id: customer.id,
          ...customer.data(),
        })
      );
      return customers;
    },
  },
};
</script>
