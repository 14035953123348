import { getCurrentUser } from '@/views/auth/get-current-user';
import Vue from 'vue';
import VueRouter from 'vue-router';
import AnalyticsView from '../views/analytics/AnalyticsViewV2.vue';
import InvitationView from '../views/auth/invitation/InvitationView';
import LoginView from '../views/auth/login/LoginView';
import PasswordRecoveryView from '../views/auth/password-recovery/PasswordRecoveryView';
import RegisterView from '../views/auth/register/RegisterView';
import CustomerActivityView from '../views/customer-activity/CustomerActivityView';
import CustomersView from '../views/customers/CustomersView';
import Companies from '../views/companies/CompaniesPage';
import DashboardView from '../views/dashboard/DashboardView.vue';
import HomeView from '../views/home/HomeView.vue';
import MessagesView from '../views/messages/MessagesView';
import NotificationsView from '../views/notifications/NotificationsView';
import ProductsView from '../views/products/ProductsView';
import CompaniesView from '../views/users/CompaniesView';
import IndexUsers from '../views/users/IndexUsers';
import PermissionsView from '../views/users/PermissionsView';
import PlansView from '../views/users/PlansView';
import UsersView from '../views/users/UsersView';
// import EditUserView from '../views/users/EditUserView';
import UsersBackupView from '../views/users/UsersBackupView';
import VirtualSpaceTypes from '../views/users/VirtualSpaceTypesView';
import DeleteVirtualSpaceView from '../views/virtual-spaces/screens/DeleteVirtualSpaceView';
import EditVirtualSpaceView from '../views/virtual-spaces/screens/EditVirtualSpaceView';
import EmbedVirtualSpaceView from '../views/virtual-spaces/screens/EmbedVirtualSpaceView';
import VirtualSpacesView from '../views/virtual-spaces/VirtualSpacesView';
import PreviewInvitation from '../views/virtual-spaces/components/dialogs/PreviewDialog';
// import TextSpeech from '../views/text-speech/MainTextSpeech';
import Invite from '../views/invite/InviteClients';
import InvitationContent from '../views/invite/InvitationContent';
import TextToSpeech from '../views/text-speech/TextSpeechIndex';
import VideoConference from '../views/video_conference/VideoConferenceIndex';


Vue.use(VueRouter);

const routes = [
  {
    path: '/invitation-content',
    name: 'Invitation Content',
    component: InvitationContent,
  },

  {
    path: '*',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Log In',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/password-recovery',
    name: 'Password Recovery',
    component: PasswordRecoveryView,
  },
  {
    path: '/invitation',
    name: 'Invitation',
    component: InvitationView,
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    children: [
      {
        path: 'video-conference',
        name: 'Video Conference',
        meta: { menu_title: 'Video Conference' },
        component: VideoConference,
      },
      {
        path: 'companies',
        name: 'Companies',
        meta: { menu_title: 'Companies' },
        component: Companies,
      },
      {
        path: 'invite',
        name: 'Invite',
        meta: { menu_title: 'Invite-Clients' },
        component: Invite,
      },

      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: { menu_title: 'Dashboard' },
        component: DashboardView,
      },
      {
        path: 'virtual-spaces',
        name: 'Virtual Spaces',
        meta: { menu_title: 'Virtual Spaces' },
        component: VirtualSpacesView,
        children: [
          {
            path: 'preview-invitation',
            name: 'Preview Invitation',
            meta: { menu_title: 'Invitation' },
            component: PreviewInvitation,
          },
          {
            path: 'edit',
            name: 'Edit',
            component: EditVirtualSpaceView,
          },

          {
            path: 'embed',
            name: 'Embed',
            component: EmbedVirtualSpaceView,
          },
          {
            path: 'delete',
            name: 'Delete',
            component: DeleteVirtualSpaceView,
          },
        ],
      },
      {
        path: 'analytics',
        name: 'Analytics',
        meta: { menu_title: 'Analytics' },
        component: AnalyticsView,
      },
      {
        path: 'customers',
        name: 'Customers',
        meta: { menu_title: 'Customers' },
        component: CustomersView,
      },
      {
        path: 'customer-activity',
        name: 'Customer Activity',
        meta: { menu_title: 'Customer Activity' },
        component: CustomerActivityView,
      },
      {
        path: 'products',
        name: 'Products',
        meta: { menu_title: 'Products' },
        component: ProductsView,
      },
      {
        path: 'text-speech',
        name: 'TextToSpeech',
        component: TextToSpeech,
      },
      {
        path: 'users',
        name: 'Users',
        meta: { menu_title: 'Users' },
        component: IndexUsers,
        children: [
          {
            path: 'users-view',
            name: 'Users',
            component: UsersView,
          },
          // {
          //   path: 'users-view/edit/:id',
          //   name: 'Users.Edit',
          //   component: EditUserView,
          // },
          {
            path: 'users-backup',
            name: 'Backups',
            component: UsersBackupView,
          },
          {
            path: 'companies-view',
            name: 'Companies UI',
            component: CompaniesView,
          },
          {
            path: 'plans-view',
            name: 'Plans UI',
            component: PlansView,
          },
          {
            path: 'permission-view',
            name: 'Permission UI',
            component: PermissionsView,
          },
          {
            path: 'virtual-space-types-view',
            name: 'Virtual Space Types UI',
            component: VirtualSpaceTypes,
          },
        ],
      },
      {
        path: 'messages',
        name: 'Messages',
        meta: { menu_title: 'Messages' },
        component: MessagesView,
      },
      {
        path: 'notifications',
        name: 'Notifications',
        meta: { menu_title: 'Notifications' },
        component: NotificationsView,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/* eslint-disable-next-line */
router.beforeEach(async (to, from, next) => {
  if (to.name === 'Invitation') return next();
  if (to.name !== 'Log In') {
    const currentUser = await getCurrentUser();
    if (!currentUser) return next({ name: 'Log In' });
  }
  next();
});

export default router;
