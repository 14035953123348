import axios from 'axios';

export async function getRegionsData() {
  const res = await axios
    .create({})
    .get(
      'https://raw.githubusercontent.com/David-Haim/CountriesToCitiesJSON/master/countriesToCities.json'
    );
  return res.data;
}
