<template>
  <div class="sm:pr-5 py-5">
    <!-- header  -->
    <div class="flex items-center flex-col md:flex-row md:justify-between">
      <h1 class="pb-2 text-[24px] font-bold text-[#091E42] md:text-[28px]">Products</h1>
      <v-btn rounded dark color="#F29E37" @click="addProducts">Add Product</v-btn>
    </div>
    <div class="flex justify-between items-center">
      <div class="w-full md:w-[230px] lg:w-[300px] items-end">
        <v-text-field
          full-width
          :placeholder="filterPlaceholder"
          prepend-inner-icon="mdi-card-search"
          v-model="filterProductsString"
          class="m-0 p-0"
        ></v-text-field>
      </div>
      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn slot="activator" tonal v-bind="attrs" v-on="on">
            <span>Filter by</span>
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list class="m-0 p-0">
          <v-list-item full-width v-for="(filter, i) in filters" :key="i" class="m-0 p-0 min-h-0">
            <v-list-item-title>
              <v-btn text class="w-full m-0 p-0" @click="changeFilterBy(filter)">
                {{ filter.name }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- 4 col grids  -->
    <!-- main content in 2 col grid  -->
    <!-- <ProductsToolbar @addProducts="addProducts" /> -->
    <div>
      <v-data-table
        :items-per-page="10"
        :headers="headers"
        :items="filteredProducts"
        :loading="loading"
        :search="search"
        :header-props="test"
      >
        <!-- <template v-slot:header="{ columns, isSorted, getSortIcon, toggleSort }">
          <tr>
            <template v-for="column in columns">
              <td :key="column.key">
                <span class="mr-2 cursor-pointer" @click="() => toggleSort(column)">hey</span>
                <template v-if="isSorted(column)">
                  <v-icon :icon="getSortIcon(column)"></v-icon>
                </template>
                <v-icon
                  v-if="column.removable"
                  icon="$close"
                  @click="() => remove(column.key)"
                ></v-icon>
              </td>
            </template>
          </tr>
        </template> -->
        <!-- <template v-slot:header> TESTS </template> -->
        <template v-slot:[`item.product_image_urls`]="{ item }">
          <v-img
            class="object-contain m-2"
            width="50px"
            height="50px"
            :src="getProductImageUrl(item)"
          ></v-img>
        </template>
        <!-- <template v-slot:[`item.product_detail`]="{ item }">
          <p class="truncate w-[100px]">{{ item.product_detail }}</p>
        </template> -->
        <template v-slot:[`item.in_stock`]="{ item }">
          <v-chip
            small
            v-if="item.in_stock"
            class="m-0 p-1"
            style="border: 2px solid green; color: green; font-weight: bold; background: unset"
          >
            <v-icon small color="green">mdi-check</v-icon>
          </v-chip>
          <v-chip
            small
            v-else
            class="m-0 p-1"
            style="border: 2px solid red; color: red; font-weight: bold; background: unset"
          >
            <v-icon small color="red">mdi-close</v-icon>
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex">
            <button
              text
              small
              @click="
                onSelectRow({
                  mode: 'edit',
                  item: item,
                })
              "
              class="mr-2"
            >
              <v-icon small> mdi-pencil </v-icon>
            </button>
            <button text small @click="onSelectRow({ mode: 'delete', item: item })">
              <v-icon small> mdi-delete </v-icon>
            </button>
          </div>
        </template>
      </v-data-table>
    </div>
    <!-- edit  -->
    <v-dialog v-model="editDialog" persistent :overlay="false" transition="dialog-transition">
      <div class="bg-white">
        <DialogTopBar :mainTitle="selectedInfo.mode" @closeDialog="editDialog = false" />
        <EditView class="bg-white" :id="selectedInfo?.item?.id" @closeDialog="editDialog = false" />
      </div>
    </v-dialog>
    <!-- delete  -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      :overlay="false"
      width="300px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar :mainTitle="selectedInfo.mode" @closeDialog="deleteDialog = false" />
        <DeleteView
          class="bg-white"
          :selectedInfo="selectedInfo"
          @closeDialog="deleteDialog = false"
        />
      </div>
    </v-dialog>
    <!-- Add  -->
    <v-dialog
      v-model="addProductsDialog"
      persistent
      :overlay="false"
      transition="dialog-transition"
    >
      <AddView
        class="bg-white px-4"
        :selectedInfo="selectedInfo"
        @closeDialog="addProductsDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import moment from 'moment';
import DialogTopBar from '../../components/DialogTopBar.vue';
import EditView from './components/editFolder/EditView';
import DeleteView from './components/deleteFolder/DeleteView';
import AddView from './components/addFolder/AddView';

export default {
  data() {
    return {
      addProductsDialog: false,
      loading: false,
      search: '',
      selectedInfo: [],
      editDialog: false,
      deleteDialog: false,
      items: [],
      headers: [
        {
          text: 'Image',
          value: 'product_image_urls',
          sortable: false,
        },
        {
          text: 'Product Name',
          value: 'product_name',
        },
        {
          text: 'Product Brand',
          value: 'brand_name',
        },
        {
          text: 'Product Collection',
          value: 'product_collection',
        },
        // {
        //   text: 'Description',
        //   value: 'product_detail',
        // },
        {
          text: 'SKU',
          value: 'product_sku',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'In Stock',
          value: 'in_stock',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      filterProductsString: '',
      filterBy: { name: 'Name', value: 'product_name' },
      filters: [
        { name: 'Name', value: 'product_name' },
        { name: 'Description', value: 'product_detail' },
        { name: 'Brand', value: 'brand_name' },
        { name: 'Collection', value: 'product_collection' },
        { name: 'SKU', value: 'product_sku' },
      ],
      test: { class: 'green' },
    };
  },
  components: {
    EditView,
    DeleteView,
    AddView,
    DialogTopBar,
  },
  mounted() {
    this.loadData();
  },
  computed: {
    filteredProducts: function () {
      console.log('yo', this.items);
      return this.items.filter((product) => {
        return product[this.filterBy.value]
          .toLowerCase()
          .match(this.filterProductsString.toLowerCase());
      });
    },
    filterPlaceholder: function () {
      return `Search by ${this.filterBy.name.toLowerCase()}...`;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.items = await this.getProducts();
      } catch (err) {
        console.error('Error loading products:', err);
        this.$toast.open({ type: 'error', message: err.message });
      } finally {
        this.loading = false;
      }
    },
    async getProducts() {
      const conditions = [where('is_deleted', '==', false)];
      const q = await getDocs(query(collection(db, 'products'), ...conditions));
      const data = [];
      q.forEach((x) => data.push({ id: x.id, ...x.data() }));
      return data;
    },
    onSelectRow(e) {
      console.log('item', e);
      if (e.mode == 'edit') {
        this.editDialog = true;
        this.selectedInfo = e;
      } else if (e.mode == 'delete') {
        this.deleteDialog = true;
        this.selectedInfo = e;
      }
    },
    moment(d) {
      return moment(d).format('llll');
    },
    addProducts() {
      this.addProductsDialog = true;
    },
    getProductImageUrl(product) {
      return product.product_image_urls.slice().shift();
    },
    changeFilterBy(newFilterBy) {
      this.filterBy = newFilterBy;
    },
  },
};
</script>
<style>
.green {
  color: green;
}
</style>
