<template>
  <div class="h-screen flex justify-center align-middle items-center">
    <div class="px-20 sm:w-[500px] w-full">
      <div class="px-10 w-full border-2 border-gray-200 rounded-md">
        <div class="flex justify-center my-4">
          <div class="text-center w-[150px]">
            <v-img src="../../../assets/icons/logo.png" class="w-full"></v-img>
          </div>
        </div>
        <div class="text-center my-6">
          <div class="text-xl font-bold">Password Recovery</div>
          <div class="font-light text-sm">
            Please provide us your Email address and we will send you a link
            where you can choose a new password.
          </div>
        </div>
        <div>
          <v-text-field class="w-full" dense type="email" error-count="100" placeholder="Please type your email"
            label="Please type your email address" clearable append-icon="mdi-email" v-model="email"
            outlined></v-text-field>
        </div>
        <div class="my-6">
          <div>
            <v-btn class="w-full" color="#F19D38" @click="submit">Submit</v-btn>
          </div>
        </div>
      </div>
      <div>
        <div class="py-10 text-center">
          <v-btn small @click="login" text>
            <span class="font-bold">Need to Sign In?</span>
          </v-btn>

          <br />
          <div class="text-xs mt-2">
            Copyright © 2022 1 Click Design LLC reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      confirm_password: "",
      hide: true,
      hide2: true,
    };
  },
  mounted() { },
  methods: {
    submit() {
      console.log("Register");
      this.$router.push('/password-recovery')
      // firebase
      //   .auth()
      //   .signInWithEmailAndPassword(this.email, this.password)
      //   .then((res) => {
      //     console.log("res", res);
      //   });
    },
    login() {
      this.$router.push('/login')
    }
  },
};
</script>
