export const PRODUCT_FORM_FIELDS = [
  {
    label: 'Product Name',
    name: 'product_name',
    value: '',
    type: 'text',
    err: false,
    required: true,
  },
  {
    label: '1CD Product Number',
    name: 'product_number',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'Product SKU',
    name: 'product_sku',
    value: '',
    type: 'text',
    err: false,
    required: true,
  },
  {
    label: 'Brand name',
    name: 'brand_name',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'Product available sizes',
    name: 'product_available_sizes',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'Price',
    name: 'price',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'Discount',
    name: 'discount',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'UPC',
    name: 'upc',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'Other product code',
    name: 'other_product_code',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'MSRP',
    name: 'msrp',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'In stock',
    name: 'in_stock',
    value: true,
    type: 'checkbox',
    err: false,
    required: false,
  },
  {
    label: 'Product number',
    name: 'product_number',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'Product collection',
    name: 'product_collection',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'E-commerce link',
    name: 'ecommerce_link',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'Product material',
    name: 'product_material',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'NFT link',
    name: 'nft_link',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'Product category',
    name: 'product_category',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
  {
    label: 'Product style',
    name: 'product_style',
    value: '',
    type: 'text',
    err: false,
    required: false,
  },
];
