<template>
  <div>
    <DialogTopBar class="bg-white" @closeDialog="close" mainTitle="Add Product" />
    <div class="my-4">
      <div class="sm:grid gap-4 grid-cols-5">
        <div v-for="(item, i) in product_fields" :key="i" class="pb-1">
          <InputField :label="item.label" :type="item.type" v-model="item.value" />
          <span v-if="item.err" class="text-xs italic text-red-600">
            {{ item.label }} required
          </span>
        </div>
        <v-select label="Showrooms" v-model="showrooms" :items="showrooms_choices" item-text="name" item-value="id"
          multiple dense></v-select>
        <v-select label="Tags" v-model="tags" :items="tags_choices" dense></v-select>
        <div class="flex flex-col items-start flex-wrap">
          <label>Product Images</label>
          <div class="flex flex-row">
            <img v-for="(url, i) in productImageUrls" :key="i" :src="url" class="object-contain" />
            <v-btn @click="onAddImageClick">Add image</v-btn>
            <input class="hidden" type="file" ref="imageFileUpload" @change="onImageFileUpload" accept="image/*"
              multiple />
          </div>
        </div>
        <div>
          <label>Product detail</label>
          <textarea v-model="product_detail" class="w-full h-20 outline outline-1 outline-slate-500 p-2" />
        </div>
      </div>
      <div class="my-2 border-t-2 border-gray-300 flex justify-center py-2">
        <ActionButtons title="Submit" @cancel="close" @submit="submit" />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButtons from '@/components/ActionButtons.vue';
import DialogTopBar from '@/components/DialogTopBar.vue';
import InputField from '@/components/form/InputField';
import { db, storage } from '@/firebase';
import { addDoc, collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { PRODUCT_FORM_FIELDS } from '../../product-form-fields';

export default {
  components: { DialogTopBar, InputField, ActionButtons },
  data() {
    return {
      loading: false,
      showrooms: [],
      tags: [],
      product_images: [],
      existing_product_image_urls: [],
      product_fields: PRODUCT_FORM_FIELDS.filter(f => f.name !== 'product_number'),
      product_detail: '',
      // Choices
      showrooms_choices: [],
      tags_choices: ['classically', 'modernly', 'jack like'],
    };
  },
  computed: {
    productImageUrls() {
      return [
        ...this.existing_product_image_urls,
        ...this.product_images.map(i => URL.createObjectURL(i)),
      ];
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.showrooms_choices = await this.getVirtualSpaces();
      } catch (err) {
        this.$toast.open({ type: 'error', message: err.message });
      } finally {
        this.loading = false;
      }
    },
    async getVirtualSpaces() {
      const conditions = [where('is_deleted', '==', false)];
      const q = await getDocs(query(collection(db, 'virtual_spaces'), ...conditions));
      const data = [];
      q.forEach((x) => data.push({ id: x.id, ...x.data() }));
      return data;
    },
    onAddImageClick() {
      this.$refs.imageFileUpload.click();
    },
    onImageFileUpload(e) {
      for (const file of e.target.files) {
        this.product_images.push(file);
      }
    },
    close() {
      this.$emit('closeDialog');
      this.clearErr();
      this.clearData();
    },
    clearErr() {
      for (const key in this.product_fields) {
        this.product_fields[key].err = false;
      }
    },
    clearData() {
      for (const key in this.product_fields) {
        this.product_fields[key].value = '';
      }
    },
    async submit() {
      let canSubmit = true;
      for (const key in this.product_fields) {
        if (!this.product_fields[key].required) continue;
        this.product_fields[key].err = '';
        if (this.product_fields[key].value == '') {
          this.product_fields[key].err = true;
          canSubmit = false;
        }
      }
      if (!canSubmit) return;
      let findField = (name) => this.product_fields.find((p) => p.name === name)?.value ?? '';
      let productImageUrls = [];

      const docRef = await addDoc(collection(db, 'products'), {
        product_name: findField('product_name'),
        product_sku: findField('product_sku'),
        brand_name: findField('brand_name'),
        product_available_sizes: findField('product_available_sizes'),
        price: findField('price'),
        discount: findField('discount'),
        upc: findField('upc'),
        other_product_code: findField('other_product_code'),
        msrp: findField('msrp'),
        in_stock: findField('in_stock'),
        product_collection: findField('product_collection'),
        ecommerce_link: findField('city'),
        product_material: findField('product_material'),
        nft_link: findField('nft_link'),
        product_category: findField('product_category'),
        product_style: findField('product_style'),
        showrooms: this.showrooms,
        tags: this.tags,
        product_image_urls: [],
        product_detail: this.product_detail,
        is_deleted: false,
      });

      for (const imageFile of this.product_images) {
        const ext = imageFile.name.split('.').pop();
        const fileName = `products/product-${findField('name').replace(
          / /g,
          ''
        )}-${docRef.id}.${ext}`;
        const productImageRef = ref(storage, fileName);
        await uploadBytes(productImageRef, imageFile);
        productImageUrls.push(await getDownloadURL(productImageRef));
      }

      await setDoc(doc(db, 'products', docRef.id), { 
        product_image_urls: productImageUrls,
      }, { merge: true });

      this.$toast.open('Created product');
      this.$emit('closeDialog');
    },
  },
};
</script>

<style scoped>
.v-text-field--filled.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot {
  min-height: 36px;
}
</style>