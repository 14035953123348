<template>
  <div>
    <div class="flex justify-end w-full items-center align-middle">
      <PermissionsToolbar @addPermission="addPermission" @SearchInput="searchMethod($event)" />
    </div>
    <div>
      <v-data-table :headers="headers" :items="displayItems" :loading="loading" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon text @click="viewDialogPermissionBtn(item)" v-bind="attrs" v-on="on">
                <v-icon> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>View permissions</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="edit(item)" v-bind="attrs" v-on="on">
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="del(item)" v-bind="attrs" v-on="on">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <!-- ADD Permission  -->
    <v-dialog
      v-model="openAddPermissionDialog"
      persistent
      width="350px"
      :overlay="false"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <AddPermission class="p-4" @closeDialog="openAddPermissionDialog = false" />
      </div>
    </v-dialog>
    <!-- ViewPermissions  -->
    <v-dialog
      v-model="viewDialogPermission"
      :overlay="false"
      width="400px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <PermissionsDialog
          :company="selectedCompany"
          @closePermissionDialog="closePermissionDialogParent($event)"
        ></PermissionsDialog>
      </div>
    </v-dialog>
    <!-- edit  -->
    <v-dialog
      v-model="editDialog"
      persistent
      :overlay="false"
      width="500px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar mainTitle="Edit Permission" @closeDialog="editDialog = false" />
        <EditPermission class="bg-white p-4" @cancel="editDialog = false" />
      </div>
    </v-dialog>
    <!-- delete  -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      :overlay="false"
      width="250px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar mainTitle="Delete Permission" @closeDialog="deleteDialog = false" />
        <DeletePermission
          class="bg-white"
          mainTitle="Delete Permission"
          @closeDialog="deleteDialog = false"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import PermissionsToolbar from './components/permissions_components/PermissionsToolbar.vue';
import AddPermission from './components/permissions_components/AddPermission.vue';
import DialogTopBar from '../../components/DialogTopBar.vue';
import EditPermission from './components/permissions_components/EditPermission.vue';
import DeletePermission from './components/permissions_components/DeletePermission.vue';
import { EventBus } from '@/EventBus';
import PermissionsDialog from '@/components/permissions/PermissionsDialog.vue';

export default {
  data() {
    return {
      selectedCompany: null,
      loading: false,
      search: null,
      viewDialogPermission: false,
      editDialog: false,
      deleteDialog: false,
      openAddPermissionDialog: false,
      displayItems: [],
      items: [
        {
          company_name: 'ABC',
          value: 'ABC',
          plan: 'admin',
          virtual_space_type: 'Virtual Showroom',
          status: 'Activated',
          reg_date: 'July 02, 2022',
        },
        {
          company_name: 'ACME',
          value: 'ACME',
          plan: 'basic',
          virtual_space_type: 'Virtual Showroom',
          status: 'Activated',
          reg_date: 'July 03, 2022',
        },
      ],
      headers: [
        {
          text: 'Company Name',
          value: 'company_name',
        },
        {
          text: 'Value',
          value: 'value',
        },
        {
          text: 'Plan',
          value: 'plan',
        },
        {
          text: 'Virtual Space Type',
          value: 'virtual_space_type',
        },
        {
          text: 'Status',
          value: 'status',
        },

        {
          text: 'Creation Date',
          value: 'reg_date',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
        },
      ],
    };
  },
  components: {
    PermissionsToolbar,
    AddPermission,
    DialogTopBar,
    EditPermission,
    DeletePermission,
    PermissionsDialog,
  },
  mounted() {
    this.loadData();
    EventBus.$on('SearchInputPermission', this.handleSearchPlan);
  },
  methods: {
    async loadData() {
      //temporary since data is static
      this.displayItems = this.items;
    },
    handleSearchPlan(search_value) {
      const data = [...this.items].filter((d) => {
        const jsonStr = JSON.stringify(d, (key, value) => {
          if (typeof value === 'string') {
            return value.toLowerCase();
          }
          return value;
        });
        return jsonStr.includes(search_value.toLowerCase());
      });

      this.displayItems = data;
    },
    viewDialogPermissionBtn(e) {
      this.viewDialogPermission = true;
      this.selectedCompany = e;
    },
    closePermissionDialogParent(val) {
      this.viewDialogPermission = val;
    },
    edit() {
      this.editDialog = true;
    },
    del() {
      this.deleteDialog = true;
    },
    moment(d) {
      return moment(d).format('llll');
    },
    addPermission() {
      this.openAddPermissionDialog = true;
    },
    searchMethod(val) {
      this.search = val;
    },
  },
};
</script>
