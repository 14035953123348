<template>
  <div class="text-center py-4">
    <v-btn text @click="$emit('cancel')" elevation="0"> Cancel</v-btn>
    <v-btn color="#E7A24D" @click="$emit('submit')" class="ml-2" elevation="0">
      {{ title }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ['title'],
};
</script>