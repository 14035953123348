<template>
  <div class="sm:flex justify-between w-full">
    <div class="sm:w-1/2 sm:flex items-center" v-if="$route.name == 'Users'">
      <v-btn small class="mr-4">Display All</v-btn>
      <div class="w-1/3">
        <v-select label="Select Company" :items="items" v-model="value" autocomplete></v-select>
      </div>
    </div>
    <div class="flex justify-end" :class="$route.name == 'Users' ? 'w-1/2' : 'w-full'">
      <div class="flex items-center">
        <SimpleSearch class="mr-2" :placeholder="'Search Company...'" :search_id="'Company'" />
        <div>
          <v-btn small elevation="" color="#F29E37" @click="addCompany">Add Company </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleSearch from '@/components/SimpleSearch.vue';

export default {
  components: { SimpleSearch },
  methods: {
    addCompany() {
      this.$emit('addCompany');
    },
  },
};
</script>
