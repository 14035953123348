<template>
    <div class="bg-gray-200 p-4">
      <div class="w-full px-10 bg-white">
        <input class="w-full" v-model="textToSpeak" type="text" />
      </div>
      <button @click="speak">Speak</button>
    </div>
  </template>
  <script>
  // English: en-US, en-GB, en-AU, en-IN, en-CA
  // Spanish: es-ES, es-US, es-MX, es-AR, es-CL
  // French: fr-FR, fr-CA
  // German: de-DE
  // Italian: it-IT
  // Japanese: ja-JP
  // Korean: ko-KR
  // Chinese (Mandarin): zh-CN, zh-HK, zh-TW
  // In your Vue.js component
  export default {
    data() {
      return {
        textToSpeak: "How are you? You are now in our new platform",
      };
    },
    mounted() {
      if ('speechSynthesis' in window) {
        this.synthesizer = window.speechSynthesis;
        this.speak();
      } else {
        console.error('Speech synthesis not supported');
      }
    },
  
    methods: {
      speak() {
        if (this.synthesizer && this.textToSpeak) {
          const utterance = new SpeechSynthesisUtterance(this.textToSpeak);
          utterance.lang = 'en-AU';
          this.synthesizer.speak(utterance);
        }
      },
    },
  };
  </script>
  
  