<template>
  <div class="bg-white p-2">
    <div class="px-4">
      <ViewComponent v-if="mode == 'view'" @closeDialog="closeDialog" />
      <EditComponent v-if="mode == 'edit'" :id="selectedItem.id" @closeDialog="closeDialog" />
      <DeleteComponent v-if="mode == 'delete'" :id="selectedItem.id" @closeDialog="closeDialog" />
    </div>
  </div>
</template>

<script>
import ViewComponent from "./ViewCustomer.vue";
import EditComponent from "./EditCustomer.vue";
import DeleteComponent from "./DeleteCustomer.vue";

export default {
  data() {
    return {};
  },
  props: ["selectedItem", "mode"],
  components: { ViewComponent, EditComponent, DeleteComponent },
  watch: {
    openDialog: {
      handler(e) {
        this.dialog = e;
      },
      immediate: true,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>