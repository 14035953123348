<template>
  <div>
    <div>
      <UsersToolbar @addUser="addUser" @SearchInput="searchMethod($event)" />
    </div>
    <div>
      <!-- <pre>
        {{ displayItems }}
      </pre> -->
      <v-data-table :search="search" :headers="headers" :items="displayItems" item-key="id" :loading="loading">
        <!-- user avatar  -->
        <!-- <template v-slot:[`item.avatar_url`]> -->
        <template v-slot:[`item.avatar_url`]="{ item }">
          <div class="flex w-[150px] text-xs items-center py-2">
            <v-avatar size="45">
              <img v-if="item.avatar_url" :src="item.avatar_url" class="object-cover" />
              <v-avatar v-else color="primary" size="45" class="text-white">{{
                item?.full_name ? item.full_name.match(/\b\w/g).join('') : ''
              }}</v-avatar>
            </v-avatar>
            <div class="ml-2 w-full">
              {{ item.full_name }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex w-full justify-start">
            <div class="mr-4">
              <v-icon medium @click="edit(item)"> mdi-pencil </v-icon>
              <!-- <button
                @click="selectMenu(item)"
                class="px-[8px] font-bold text-[#13282D] border-solid hover:text-[#FF9800] active:text-[#FF9800] focus:border-solid focus:border-b-4 focus:border-[#FF9800] focus:text-[#FF9800] mx-2"
              >
                Edit
              </button> -->
            </div>
            <div>
              <v-icon medium @click="del(item)">mdi-delete </v-icon>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="openAddUsersDialog"
      persistent
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
    >
      <div class="bg-white overflow-hidden">
        <AddUsers
          class="p-4"
          @cancel="openAddUsersDialog = false"
          @close="openAddUsersDialog = false"
        />
      </div>
    </v-dialog>
    <!-- edit  -->
    <v-dialog
      v-model="editDialog"
      persistent
      :overlay="false"
      width="700px"
      transition="dialog-transition"
    >
      <div class="bg-white overflow-hidden">
        <DialogTopBar mainTitle="Update User Information" @closeDialog="editDialog = false" />
        <EditView class="bg-white p-4" :id="selectedUserId" @close="editDialog = false" />
      </div>
    </v-dialog>
    <!-- delete  -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      :overlay="false"
      width="250px"
      transition="dialog-transition"
    >
      <div class="bg-white">
        <DialogTopBar mainTitle="Delete User" @closeDialog="deleteDialog = false" />
        <DeleteView class="bg-white" mainTitle="Delete User" @closeDialog="deleteDialog = false" />
      </div>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { db } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import moment from 'moment';
import DialogTopBar from '../../components/DialogTopBar.vue';
import AddUsers from './components/users_components/AddUsers.vue';
import DeleteView from './components/users_components/DeleteUsers.vue';
import EditView from './components/users_components/EditUsers.vue';
import UsersToolbar from './components/users_components/UsersToolbar.vue';
import { EventBus } from '@/EventBus';

export default {
  data() {
    return {
      // dialogs
      search: null,
      editDialog: false,
      deleteDialog: false,
      openAddUsersDialog: false,
      items: [
        {
          image: '',
          email: 'john@email.com',
          full_name: 'John Doe',
          organization: 'Acme',
          role: 'admin',
          status: 'Active',
          last_signin: '20 April 2020',
          reg_date: '',
        },
      ],
      selectedUserId: null,
      displayItems: [],
      loading: false,
      headers: [
        {
          text: 'User',
          value: 'avatar_url',
        },
        {
          text: 'Email Address',
          value: 'email',
        },

        {
          text: 'Organization',
          // value: 'organization',
          value: 'company',
        },
        {
          text: 'Role',
          value: 'role',
        },
        // {
        //   text: 'Status',
        //   value: 'status',
        // },
        // {
        //   text: 'Last Sign-in Time',
        //   value: 'last_signin',
        // },
        // {
        //   text: 'Creation Time',
        //   value: 'reg_date',
        // },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
    };
  },
  components: {
    UsersToolbar,
    AddUsers,
    DialogTopBar,
    EditView,
    DeleteView,
  },
  mounted() {
    this.loadUsers();
    EventBus.$on('SearchInputUser', this.handleSearchUser);
  },
  methods: {
    // selectMenu(e) {
    //   console.log(e);
    //   if (!this.$route.path.includes(e.path)) {
    //     this.$router.push({ path: `users-view/edit/${e.id}`,  });
    //   }
    // },
    async loadUsers() {
      this.loading = true;
      try {
        this.items = await this.getUsers();
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const currentCompanyInstance = localStorage.getItem('current_company');
        const is1cdSuperAdmin =
          userDetails.role.toLowerCase() === '1cdsuperadmin' &&
          currentCompanyInstance === '1clickdesign';

        this.displayItems = is1cdSuperAdmin
          ? this.items
          : this.items.filter((user) => {
              return (
                user?.company?.toLowerCase() === currentCompanyInstance.toLowerCase() &&
                user?.email !== userDetails.email
              );
            });
      } catch (err) {
        console.error('Error loading users:', err);
        this.$toast.open({ type: 'error', message: err.message });
      } finally {
        this.loading = false;
      }
    },
    async getUsers(search) {
      const conditions = [where('is_deleted', '==', false)];
      // "Starts with" string check
      if (search) conditions.push(where('full_name', '>=', search));
      const q = await getDocs(query(collection(db, 'users'), ...conditions));
      const data = [];
      q.forEach((x) => data.push({ id: x.id, ...x.data() }));
      return data;
    },
    handleSearchUser(search_value) {
      this.displayItems = [...this.items].filter((d) => {
        const jsonStr = JSON.stringify(d, (key, value) => {
          if (typeof value === 'string') {
            return value.toLowerCase();
          }
          return value;
        });
        return jsonStr.includes(search_value.toLowerCase());
      });
    },
    edit(user) {
      this.selectedUserId = user.id;
      this.editDialog = true;
    },
    del() {
      this.deleteDialog = true;
    },
    moment(d) {
      return moment(d).format('llll');
    },
    addUser() {
      this.openAddUsersDialog = true;
    },
    searchMethod(val) {
      // console.log(val);
      this.search = val
    }
  },
};
</script>
